<template lang="html">
  <div class="wrap">
    <div class="center title">
      Accessories
    </div>
    <div class="sectionMaps">
      <button
        id="scrollMap"
        v-for="scrollLink in links"
        type="button"
        name="button"
        @click="scrollToProduct(scrollLink.name)"
      >
        {{ scrollLink.name }}
      </button>
    </div>

    <div class="product-section" id="HeadstageTester">
      <div class="product-title center">
        Headstage Tester
      </div>

      <div class="tester">
        <div class="tester__img">
          <img
            src="../images/HS Tester/HS-tester-top.png"
            width="400px"
            alt=""
          />
        </div>
        <div class="tester__table">
          <table id="headstageTester">
            <tr v-for="spec in headStageSpecs">
              <td>
                {{ spec.spec }}
              </td>
              <td>
                {{ spec.description }}
              </td>
            </tr>
            <tr>
              <td>
                Software
              </td>
              <td>
                <a href="https://storage.googleapis.com/white-matter/hs-tester/hs-tester.zip" download>hs-tester config</a> (win32/64)
              </td>
            </tr>
          </table>
        </div>
      </div>
      <br />
      <p>
        Fits in the palm of your hand! Our low cost hs-tester is a precision
        arbitrary waveform generator that generates a variety of useful test
        signals for troubleshooting experiments. It enables rapid and automated
        testing of headstage functionality, channel gain and linearity, and
        input-referred noise measurement.
      </p>
    </div>

    <hr />

    <div class="product-section" id="Tethers">
      <div class="product-title center">
        Tethers
      </div>

      <div class="center">
        <img src="../images/accessories/tether1.jpeg" height="250px" alt="" />
        <video height="350px" muted loop playsinline autoplay>
          <source src="../videos/target.mp4" type="video/mp4" />
        </video>
        <img src="../images/accessories/tether2.jpeg" height="250px" alt="" />
      </div>
      <br />
      <p>
        Ultra flexible, 1.1 ~ 1.6 mm O.D. headstage tether cables are available
        as regular cables or with our unique lightweight inline slip-ring
        commutator. Cable length made to order, up to 10 meters long, with or
        without magnets for easy plugging.
      </p>
    </div>

    <hr />
    <section id="ElectrodeInterfaceBoards">
      <div class="product-section">
        <div class="product-title center">
          Electrode Interface Boards
        </div>

        <div id="adaptors">
          <h2>Adaptors</h2>
          <!-- <AccordionAdaptors /> -->
          <!-- <div id="adaptorDiv"> -->
          <table class="fixedHeader" id="adaptorTable">
            <tr>
              <template v-for="key in adaptorKeys">
                <th v-if="key == 'photo'" style="width: 120px"></th>
                <th v-else style="border: none;">
                  {{ key }}
                </th>
              </template>
            </tr>

            <tr v-for="(adaptor, index) in adaptors">
              <template v-for="key in adaptorKeys">
                <td v-if="key == 'photo'">
                  <img :src="adaptor[key]" width="120px" />
                </td>
                <td v-else>
                  {{ adaptor[key] }}
                </td>
              </template>
            </tr>
          </table>

          <!-- </div> -->
        </div>

        <div class="product-section" id="eibs">
          <h2>EIBs</h2>
          <!-- <AccordionEIBs /> -->

          <table class="fixedHeader" id="eibTable">
            <tr>
              <template v-for="key in eibKeys">
                <th v-if="key == 'photo'" style="width: 120px;"></th>
                <th v-else style="border: none;">
                  {{ key }}
                </th>
              </template>
            </tr>

            <tr v-for="(eib, index) in eibs">
              <template v-for="key in eibKeys">
                <td v-if="key == 'photo'">
                  <img :src="eib[key]" width="120px" />
                </td>
                <td v-else>
                  {{ eib[key] }}
                </td>
              </template>
            </tr>
          </table>

          <br />
          <p>
            We have a range of adaptors suitable for all common probe
            interconnects. Custom probe adaptors upon request.
          </p>
        </div>
      </div>
    </section>

    <hr />

    <div class="product-section" id="BreakoutBoxes">
      <div class="product-title center section">
        Breakout Boxes
      </div>

      <div class="center">
        <div class="center__image">
          <img src="../images/accessories/BNC0.png" height="250px" alt="" />
        </div>
        <div class="center__image">
          <img src="../images/accessories/BNC32.jpeg" height="300px" alt="" />
        </div>
        <div class="center__image">
          <img src="../images/accessories/BNC64.jpeg" height="300px" alt="" />
        </div>
      </div>
      <br />
      <p>
        32 and 64 channel breakout boxes for easy interfacing with existing lab
        equipment.
      </p>
    </div>
  </div>
</template>

<script>
import AccordionAdaptors from "../components/AccordionAdaptors.vue";
import AccordionEIBs from "../components/AccordionEIBs.vue";

let links = [
  { name: "Headstage Tester" },
  { name: "Tethers" },
  { name: "Electrode Interface Boards" },
  { name: "Breakout Boxes" }
];

let headStageSpecs = [
  { spec: "Channels", description: "64 (common mode)" },
  {
    spec: "Signals",
    description: "Sinusoid, white noise, neural data, user defined"
  },
  { spec: "Modes", description: "Signal generation, short-to-ground" },
  { spec: "Amplitude", description: "10mV, 20mV, 40mV pk-pk (user defined)" },
  { spec: "Bandwidth", description: "0.01 Hz ~ 12kHz" },
  { spec: "Resolution", description: "16 bit DAC @ 25kSps" },
  { spec: "Noise", description: "1.5uV RMS, THD 0.3% (0.01Hz ~ 25kHz)" },
  {
    spec: "Outputs",
    description: "2 x 34 pin Molex SSB6, 2 x Omnetics NSD-36, uFl"
  },
  { spec: "Power", description: "LiPo battery, 15 hr runtime between charges" },
  { spec: "Dimensions", description: "USB thumb-drive, 82 x 25 x 13 mm" }
];

let adaptorKeys = [
  "photo",
  "product ID",
  "connector1",
  "connector2",
  "channels",
  "length",
  "width (mm)"
];

let adaptors = [
  {
    photo: require("../images/accessories/adaptors/ADPT-NZA-DIP16.png"),
    "product ID": "ADPT-NZA-DIP16",
    connector1: "DIP16 IC socket",
    connector2: "Samtec MOLC",
    channels: "16",
    length: "27.9",
    "width (mm)": "15.9"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-NZA-SSB6.png"),
    "product ID": "ADPT-NZA-SSB6",
    connector1: "2 x Molex Slimstack SSB6",
    connector2: "Samtec MOLC",
    channels: "64",
    length: "52.7",
    "width (mm)": "13.4"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-OMN-SSB6.png"),
    "product ID": "ADPT-OMN-SSB6",
    connector1: "2 x Omnetics NSD36",
    connector2: "2 x Molex Slimstack SSB6",
    channels: "64",
    length: "14.7",
    "width (mm)": "13.0"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-ZIF33-SSB6.png"),
    "product ID": "ADPT-ZIF33-SSB6",
    connector1: "Hirose FH-43B ZIF",
    connector2: "2 x Molex Slimstack SSB6",
    channels: "32",
    length: "10.0",
    "width (mm)": "4.0"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-ZIF71-SSB6.png"),
    "product ID": "ADPT-ZIF71-SSB6",
    connector1: "Hirose FH-43B ZIF",
    connector2: "2 x Molex Slimstack SSB6",
    channels: "64",
    length: "15.9",
    "width (mm)": "11.0"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-NZA-OMN32.png"),
    "product ID": "ADPT-NZA-OMN32",
    connector1: "2 x Omnetics NSD36",
    connector2: "Samtec MOLC",
    channels: "32",
    length: "33.0",
    "width (mm)": "13.0"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-NZA-OMN64.png"),
    "product ID": "ADPT-NZA-OMN64",
    connector1: "2 x Omnetics NSD36",
    connector2: "2 x Samtec MOLC",
    channels: "64",
    length: "51.5",
    "width (mm)": "13.3"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-SAM-OMN64.png"),
    "product ID": "ADPT-SAM-OMN64",
    connector1: "2 x Samtec FOLC",
    connector2: "2 x Omnetics NPD36",
    channels: "64",
    length: "43.3",
    "width (mm)": "21.0"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-TET128.png"),
    "product ID": "ADPT-TET128",
    connector1: "2 x Molex Slimstack SSB6",
    connector2: "4 x Molex Slimstack SSB6",
    channels: "128",
    length: "30.0",
    "width (mm)": "25.6"
  }
];

let eibKeys = [
  "photo",
  "product ID",
  "probe interface",
  "connector",
  "channels",
  "length",
  "width (mm)"
];
let eibs = [
  {
    photo: require("../images/accessories/eibs/EIB-TET32.png"),
    "product ID": "EIB-TET32",
    "probe interface": "via array",
    connector: "Molex Slimstack SSB6",
    channels: "32",
    length: "10.0",
    "width (mm)": "9.2"
  },
  {
    photo: require("../images/accessories/eibs/EIB-TET64.png"),
    "product ID": "EIB-TET64",
    "probe interface": "via array",
    connector: "2 x Omnetics NPD ",
    channels: "64",
    length: "21.3",
    "width (mm)": "21.3"
  },
  {
    photo: require("../images/accessories/eibs/EIB-WIRE64.png"),
    "product ID": "EIB-WIRE64",
    "probe interface": "via array",
    connector: "2 x Molex Slimstack SSB6",
    channels: "64",
    length: "10.0",
    "width (mm)": "10.3"
  },
  {
    photo: require("../images/accessories/eibs/EIB-XBASE.png"),
    "product ID": "EIB-XBASE",
    "probe interface": "single via",
    connector: "Panasonic P4S",
    channels: "1",
    length: "10.0",
    "width (mm)": "6.5"
  },
  {
    photo: require("../images/accessories/eibs/EIB-LGA64.png"),
    "product ID": "EIB-LGA64",
    "probe interface": "flip chip array",
    connector: "2 x Molex Slimstack SSB6",
    channels: "64",
    length: "10.5",
    "width (mm)": "6.3"
  }
];

export default {
  name: `Accessories`,
  data() {
    return {
      links: links,
      headStageSpecs: headStageSpecs,
      adaptorKeys: adaptorKeys,
      adaptors: adaptors,
      eibKeys: eibKeys,
      eibs: eibs
    };
  },
  components: {
    AccordionAdaptors,
    AccordionEIBs
  },
  mounted() {
    console.log(this.$router.currentRoute.hash);
    if (this.$router.currentRoute.hash) {
      let id = this.$router.currentRoute.hash.split("#")[1];
      document
        .getElementById(id)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    }
  },
  methods: {
    scrollToProduct(productName) {
      console.log(productName.split(" ").join(""));
      var name = productName.split(" ").join("");

      document
        .getElementById(name)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
};
</script>

<style lang="scss" scoped>
$brand-yellow: #f4ab01;

.wrap {
  padding-top: 50px;
  margin: 0 150px;
  overflow: hidden;

  @media (max-width: 1100px) {
    margin: 0 100px;
  }
  @media (max-width: 800px) {
    margin: 0 80px;
  }

  @media (max-width: 600px) {
    margin: 0 50px;
  }
  @media (max-width: 500px) {
    margin: 0 20px;
  }
}

.title {
  font-size: 50px;
  font-weight: 600;
  color: $brand-yellow;

  @media (max-width: 800px) {
    font-size: 40px;
  }
  @media (max-width: 600px) {
    font-size: 30px;
  }
}

.product-title {
  font-size: 30px;
  font-weight: 600;
  // color: $brand-yellow;
}

.text {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 1px;
  color: hsl(0, 0%, 40%);
}

.center {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 800px) {
    display: block;
  }

  &__image img {
    height: auto;
    width: 100%;

    @media (max-width: 800px) {
      width: 80%;
    }
  }
}

.center img {
}

.tester {
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    display: block;
  }

  &__img {
    flex: 1;
    width: 100%;
  }
  &__img img {
    height: auto;
    width: 100%;
  }
  &__table {
    flex: 1;
    width: 100%;
  }
}

// .bbPhotos img {
//   height: auto;
//   width: 30%;
// }

.sectionMaps {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.product-section {
  padding: 70px 0;
}
#headstageTester {
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 1px;
  color: hsl(0, 0%, 40%);
  border-collapse: collapse;

  @media (max-width: 600px) {
    font-size: 10px;
  }
}

#headstageTester td,
#headstageTester th {
  border: 1px solid #ddd;
  padding: 0 10px;
}

#headstageTester tr:nth-child(even) {
  background-color: #f2f2f2;
}

#headstageTester tr:hover {
  background-color: #ddd;
}

// #adaptors {
//   padding-left: 100px;
// }

.fixedHeader tr:first-child > th {
  position: sticky;
  top: 0;
  background-color: white;
}

.fixedHeader {
  height: 400px;
  overflow: auto;
  display: block;
  width: 100%;
}

.fixedHeader {
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 1px;
  color: hsl(0, 0%, 40%);
  border-collapse: collapse;

  @media (max-width: 600px) {
    font-size: 10px;
  }
}

.fixedHeader td,
.fixedHeader th {
  // border: 1px solid #ddd;
  padding: 0 20px;
  text-align: left;
}

.fixedHeader tr:nth-child(even) {
  background-color: #f2f2f2;
}

// #eibTable {
//   width: 100%;
//   font-size: 14px;
//   font-weight: 300;
//   line-height: 25px;
//   letter-spacing: 1px;
//   color: hsl(0, 0%, 40%);
//   border-collapse: collapse;
//
//   @media (max-width: 600px) {
//     font-size: 10px;
//   }
// }

#eibTable td,
#eibTable th {
  // border: 1px solid #ddd;
  padding: 0 28px;
  text-align: left;
}

#eibTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

button#scrollMap {
  width: 150px;
  height: 50px;
  background-color: hsl(0, 0%, 95%);
  color: hsl(0, 0%, 20%);
  display: flex;
  justify-content: center;
}
</style>
