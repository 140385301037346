var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar",class:{
    'hidden-navbar': !_vm.showNavbar,
    'base-navbar': _vm.isTopOfPage,
    'no-bg': _vm.darkPage,
    'white-bg': (_vm.darkPage && !_vm.isTopOfPage) || !_vm.darkPage
  }},[_c('div',{staticClass:"header-logo",attrs:{"id":"logo"}},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../../images/Logos/logo no text.svg"),"height":"130%"}}),_c('span',{staticClass:"textgo"},[_vm._v(" White Matter")])])],1),_c('ul',{staticClass:"nav-links"},[_c('li',{staticClass:"nav-item"},[_c('div',{staticClass:"dropdown"},[_c('div',{staticClass:"dropbtn",on:{"mouseover":function($event){_vm.isOpen = true},"mouseleave":function($event){_vm.isOpen = false}}},[_c('router-link',{class:{},attrs:{"to":"/products"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.isSearch)?_c('span',{staticStyle:{"margin-right":"5px"}},[_c('span',{staticClass:"underlined",class:{
                    underline: this.$route.path.includes('products')
                  }},[_vm._v(" Products ")]),_c('div',{staticClass:"down-arrow",class:{}})]):_vm._e()])],1)],1),_c('div',{ref:"dropdownMenu",staticClass:"dropdown-content",class:{ show: _vm.isOpen },on:{"mouseover":function($event){_vm.isOpen = true},"mouseleave":function($event){_vm.isOpen = false}}},[_c('router-link',{attrs:{"to":"/products/eCubeServer"}},[_c('span',{class:{
                dropdownContentActive: this.$route.path.includes(
                  'eCubeServer'
                )
              }},[_vm._v(" eCube Server ")])]),_c('router-link',{attrs:{"to":"/products/eCubeHeadstages"}},[_c('span',{class:{
                dropdownContentActive: this.$route.path.includes(
                  'eCubeHeadstages'
                )
              }},[_vm._v(" eCube Headstages ")])]),_c('router-link',{attrs:{"to":"/products/nanoZ"}},[_c('span',{class:{
                dropdownContentActive: this.$route.path.includes('nanoZ')
              }},[_vm._v(" nanoZ ")])]),_c('router-link',{attrs:{"to":"/products/e3Vision"}},[_c('span',{class:{
                dropdownContentActive: this.$route.path.includes('e3Vision')
              }},[_vm._v(" e3Vision ")])]),_c('router-link',{attrs:{"to":"/products/accessories"}},[_c('span',{class:{
                dropdownContentActive: this.$route.path.includes(
                  'accessories'
                )
              }},[_vm._v(" Accessories ")])])],1)])]),_c('li',{staticClass:"nav-item"},[_c('router-link',{class:{},attrs:{"to":"/about"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.isSearch)?_c('span',{staticClass:"underlined",class:{ underline: this.$route.name == 'about' }},[_vm._v(" About ")]):_vm._e()])],1)],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{class:{},attrs:{"to":"/support"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.isSearch)?_c('span',{staticClass:"underlined",class:{ underline: this.$route.name == 'support' }},[_vm._v(" Support ")]):_vm._e()])],1)],1),_c('li',{staticClass:"nav-item"},[(!_vm.isSearch)?_c('div',{attrs:{"id":"search"},on:{"click":function($event){_vm.isSearch = !_vm.isSearch;
          _vm.setCursor;}}},[_c('img',{attrs:{"src":require("../../icons/search.svg"),"width":"15px","alt":""}})]):_vm._e(),(_vm.isSearch)?_c('div',{attrs:{"id":"search"},on:{"click":function($event){_vm.isSearch = !_vm.isSearch}}},[_c('img',{attrs:{"src":require("../../icons/close.svg"),"width":"15px","alt":""}})]):_vm._e()])]),_c('transition',{attrs:{"name":"reveal","mode":"out-in"}},[(_vm.isSearch)?_c('div',{staticClass:"search-bar",class:{ 'base-search-bar': _vm.isTopOfPage }},[_c('form',{attrs:{"autocomplete":"off","id":"searchy"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],attrs:{"id":"foo","type":"text","name":"","placeholder":"Search white-matter.com","autofocus":""},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing){ return; }_vm.query=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.query),expression:"query"}],staticClass:"search-results"},[_c('ul',{attrs:{"id":"search-results-list"}},[_vm._l((_vm.queryResults),function(result){return _c('li',[_c('router-link',{attrs:{"to":result.url},nativeOn:{"click":function($event){_vm.query = '';
                  _vm.isSearch = !_vm.isSearch;}}},[_c('div',[_vm._v(" "+_vm._s(result.title)+" "),_c('p',[_vm._v(_vm._s(_vm.queryStringMatch(result)))])])])],1)}),(_vm.clickedSearchLink)?_c('li',[_vm._v(" foo ")]):_vm._e()],2)])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }