<template lang="html">
  <div class="quoteContainer">
    <h2>Congrats on designing your own eCube Headstage!</h2>
    <p>Complete and submit the form below to request a quote.</p>

    <div class="wraps">
      <div class="image">
        <div class="hs32Image" v-if="this.productName == 'HS-32'">
          <img :src="imgString" width="320px" alt="" />
        </div>
        <div class="hs64Image" v-if="this.productName == 'HS-64'">
          <img :src="imgString" width="320px" alt="" />
        </div>
        <div class="hs640Image" v-if="this.productName == 'HS-640'">
          <img :src="imgString" width="200px" alt="" />
        </div>
        <div class="hsWImage" v-if="this.productName == 'HS-W'">
          <img :src="imgString" width="170px" alt="" />
        </div>
      </div>

      <div class="text">
        <div class="hsText" v-if="this.productName == 'HS-32'">
          <div style="padding-left: 140px">
            <h2>{{ productName }}</h2>
          </div>
          <ul style="padding-top: 10px">
            <li class=""><b>Channels</b>: {{ getChannelCount }}</li>
            <li><b>Weight</b>: {{ weight }}g</li>
            <li><b>Footprint</b>: 8 x 5 mm</li>
            <li v-if="modObjectToMMXList.length !== 0">
              <span> <b>Factory Modules</b>: </span>
              <template
                v-for="(mod, index) in modObjectToMMXList.concat(
                  modObjectToFactoryList
                )"
              >
                <span
                  v-if="
                    index !==
                      modObjectToMMXList.concat(modObjectToFactoryList).length -
                        1
                  "
                >
                  {{ mod }},</span
                >
                <span v-else> {{ mod }} </span>
              </template>
            </li>
            <li><b>Bandwidth</b>: 0.1Hz ~10kHz</li>
            <li>
              <b>Noise Floor</b>: 2.0 uV RMS (grounded inputs, full bandwidth)
            </li>
            <li><b>Sampling</b>: 14 bit ADC, 25 kSps</li>
            <li>
              <b>Data Cable</b>: 6 wire ultra-fine gigabit cable (1.0 ~ 1.5mm,
              O.D, optional Kevlar sheath)
            </li>
          </ul>
        </div>

        <div class="hsText" v-if="this.productName == 'HS-64'">
          <div style="padding-left: 140px">
            <h2>{{ productName }}</h2>
          </div>
          <ul style="padding-top: 10px">
            <li class=""><b>Channels</b>: {{ getChannelCount }}</li>
            <li><b>Weight</b>: {{ weight }}g</li>
            <li><b>Footprint</b>: 7.5 x 8.0 mm</li>
            <li v-if="modObjectToFactoryList.length !== 0">
              <span> <b>Factory Modules</b>: </span>
              <template v-for="(mod, index) in modObjectToFactoryList">
                <span v-if="index !== modObjectToFactoryList.length - 1">
                  {{ mod }},</span
                >
                <span v-else> {{ mod }} </span>
              </template>
            </li>
            <li>
              <span v-if="modObjectToMMXList.length !== 0">
                <b>MMX Modules</b>:
              </span>
              <template v-for="(mod, index) in modObjectToMMXList">
                <span v-if="index !== modObjectToMMXList.length - 1">
                  {{ mod }},</span
                >
                <span v-else> {{ mod }} </span>
              </template>
            </li>
            <li><b>Bandwidth</b>: 0.1Hz ~10kHz</li>
            <li>
              <b>Noise Floor</b>: 2.0 uV RMS (grounded inputs, full bandwidth)
            </li>
            <li><b>Sampling</b>: 14 bit ADC, 25 kSps</li>
            <li>
              <b>Data Cable</b>: 6 wire ultra-fine gigabit cable (1.0 ~ 1.5mm,
              O.D, optional Kevlar sheath)
            </li>
          </ul>
        </div>

        <div class="hsText" v-if="this.productName == 'HS-640'">
          <div style="padding-left: 140px">
            <h2>{{ productName }}</h2>
          </div>
          <ul style="padding-top: 10px">
            <li class=""><b>Channels</b>: {{ getChannelCount }}</li>
            <li><b>Weight</b>: {{ weight }}g</li>
            <li><b>Footprint</b>: 10.4 x 10.9 mm</li>
            <li v-if="modObjectToFactoryList.length !== 0">
              <span> <b>Factory Modules</b>: </span>
              <template v-for="(mod, index) in modObjectToFactoryList">
                <span v-if="index !== modObjectToFactoryList.length - 1">
                  {{ mod }},</span
                >
                <span v-else> {{ mod }} </span>
              </template>
            </li>
            <li v-if="modObjectToMMXList.length !== 0">
              <span> <b>MMX Modules</b>: </span>
              <template v-for="(mod, index) in modObjectToMMXList">
                <span v-if="index !== modObjectToMMXList.length - 1">
                  {{ mod }},</span
                >
                <span v-else> {{ mod }} </span>
              </template>
            </li>
            <li><b>Bandwidth</b>: 0.1Hz ~10kHz</li>
            <li>
              <b>Noise Floor</b>: 2.0 uV RMS (grounded inputs, full bandwidth)
            </li>
            <li><b>Sampling</b>: 14 bit ADC, 25 kSps</li>
            <li>
              <b>Data Cable</b>: 6 wire ultra-fine gigabit cable (1.0 ~ 1.5mm,
              O.D, optional Kevlar sheath)
            </li>
          </ul>
        </div>

        <div class="hsText" v-if="this.productName == 'HS-W'">
          <div style="padding-left: 140px">
            <h2>{{ productName }}</h2>
          </div>
          <ul style="padding-top: 10px">
            <li class=""><b>Channels</b>: {{ getChannelCount }}</li>
            <li>
              <b>Weight</b>: {{ weight }}g (with battery
              {{ Math.round(((weight + batteryWeight) * 100) / 100) }}g)
            </li>
            <li><b>Footprint</b>: 12.0 x 10.4 mm</li>
            <li v-if="modObjectToFactoryList.length !== 0">
              <span> <b>Factory Modules</b>: </span>
              <template v-for="(mod, index) in modObjectToFactoryList">
                <span v-if="index !== modObjectToFactoryList.length - 1">
                  {{ mod }},</span
                >
                <span v-else> {{ mod }} </span>
              </template>
            </li>
            <li v-if="modObjectToMMXList.length !== 0">
              <span> <b>MMX Modules</b>: </span>
              <template v-for="(mod, index) in modObjectToMMXList">
                <span v-if="index !== modObjectToMMXList.length - 1">
                  {{ mod }},</span
                >
                <span v-else> {{ mod }} </span>
              </template>
            </li>
            <li>
              <b>Recording Duration</b>: Up to {{ recordingDuration }} hrs
            </li>
            <li><b>Bandwidth</b>: 0.1Hz ~10kHz</li>
            <li>
              <b>Noise Floor</b>: 2.0 uV RMS (grounded inputs, full bandwidth)
            </li>
            <li><b>Sampling</b>: 14 bit ADC, 25 kSps</li>
            <li>
              <b>Data Link</b>: 10m radio link for remote control, online
              monitoring
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="inputForm">
      <form action="index.html" method="post" @submit.prevent="submitForm">
        <label><p>Name*</p></label>
        <input
          placeholder="First"
          type="text"
          v-model="quoteData.name.first"
          required
        />
        <input
          placeholder="Last"
          type="text"
          v-model="quoteData.name.last"
          required
        />

        <label><p>Email Address*</p></label>
        <input type="email" v-model="quoteData.email" required />
        <br />
        <label><p>Institution*</p></label>
        <input type="text" v-model="quoteData.institution" required />
        <br />
        <label><p>Message</p></label>
        <textarea type="text" v-model="quoteData.message" />
        <br />
        <button id="submit" type="submit" name="button">SUBMIT</button>

        <label v-if="loading">Loading...</label>
        <label v-if="success"></label>
        <label v-if="failure" style="color: red"
          >Oops something went wrong. Please use your email client.</label
        >
      </form>
    </div>
    <br /><br />
  </div>
</template>

<script>
import { store, mutations } from "../store";
import axios from "axios";

export default {
  name: `ConfiguratorQuoteForm`,
  data() {
    return {
      productName: "",
      weight: "",
      footPrint: "",
      recordingDuration: "",
      sampleRate: "",
      imgString: "",
      selectedModules: {},
      quoteData: {
        name: {
          first: "",
          last: ""
        },
        email: "",
        institution: "",
        message: "",
        sendTo: "sales"
      },
      loading: false,
      success: false,
      failure: false
    };
  },
  mounted() {
    this.productName = store.productName;
    this.weight = store.weight;
    this.batteryWeight = store.batteryWeight;
    this.recordingDuration = store.recordingDuration;
    this.sampleRate = store.sampleRate;
    this.imgString = store.imgString;
    this.selectedModules = store.selectedModules;
  },
  computed: {
    modObjectToMMXList() {
      let modList = [];
      let entries = Object.entries(this.selectedModules);

      for (let [key, value] of entries) {
        if (!["tracking LEDs", "mag-tether", "hardwired"].includes(key)) {
          if (value) {
            modList.push(key);
          }
        }
      }
      return modList;
    },
    modObjectToFactoryList() {
      let modList = [];
      let entries = Object.entries(this.selectedModules);

      for (let [key, value] of entries) {
        if (["tracking LEDs", "mag-tether", "hardwired"].includes(key)) {
          if (value) {
            modList.push(key);
          }
        }
      }
      return modList;
    },
    getChannelCount() {
      if (this.productName == "HS-32") {
        return "32";
      } else if (this.productName == "HS-64") {
        return "64";
      } else if (this.productName == "HS-640") {
        return (
          parseInt(
            this.imgString
              .split("/")[2]
              .split("-")[0]
              .slice(4, 6)
          ) * 64
        );
      } else if (this.productName == "HS-W") {
        return parseInt(this.imgString.split("/")[2].split("-")[0][4]) * 64;
      }
    },
    headstageDetails() {
      return {
        productName: this.productName,
        channelCount: this.getChannelCount,
        mmxModules: this.modObjectToMMXList,
        factoryModules: this.modObjectToFactoryList,
        recordingDuration: this.recordingDuration,
        sampleRate: this.sampleRate
      };
    }
  },
  methods: {
    setData(payload) {
      console.log(payLoad);
    },
    close() {
      this.$emit("close");
    },
    async submitForm() {
      let eventData = Object.assign(this.quoteData, this.headstageDetails);

      console.log(JSON.stringify(eventData));
      console.log(
        `has property channel count? : ${eventData.hasOwnProperty(
          "channelCount"
        )}`
      );
      this.loading = true;
      let url =
        "https://pxt3drtt8b.execute-api.us-west-2.amazonaws.com/testing/get-quote";

      try {
        let response = await axios.post(url, JSON.stringify(eventData));
        console.log(response);
        if (
          response.status == 200 &&
          response.data == null
          // response.data.hasOwnProperty("errorMessage") ||
          // response.status !== 200
        ) {
          console.log("function succeeded");
          console.log(response.data);
          setTimeout(() => {
            this.loading = false;
            this.$parent.$emit("close"); //apparently bad practice since can break if not grandchild
            this.$router.push({ path: "/quote/confirmation" });
            this.quoteData.name.first = "";
            this.quoteData.name.last = "";
            this.quoteData.email = "";
            this.quoteData.institution = "";
            this.quoteData.message = "";
          }, 1000);
        } else {
          console.log("function failed! please try again later");
          console.log(response.data.errorMessage);
          setTimeout(() => {
            this.loading = false;
            this.failure = true;
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.quoteContainer {
  margin: 0 150px;
}
.wraps {
  display: flex;
  flex-direction: row;
  background: white;
}

.text {
  max-width: 400px;
  margin-left: 50px;
}

.blah {
  opacity: 0;
}
ul {
  list-style-image: none;
  line-height: 16px;
}

li {
  font-size: 16px;
  font-weight: 500;
  color: hsl(0, 0, 10);
}

input {
  box-shadow: inset 0 2px 4px 0 hsla(0, 0%, 0%, 0.06);
  width: 100%;
  max-width: 600px;
  height: 25px;
  padding: 5px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  height: 45px;
  width: 100%;
  max-width: 600px;
  box-shadow: inset 0 2px 4px 0 hsla(0, 0%, 0%, 0.06);
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.foo {
  // max-height: 100vh;
  // max-width: 100vw;
  // position: relative;
  // z-index: 10000000;
}
</style>
