import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";

import VueScrollmagic from "vue-scrollmagic";
Vue.use(VueScrollmagic);

import VueMq from "vue-mq";

Vue.use(VueMq, {
  breakpoints: {
    xs: 450,
    sm: 700,
    md: 900,
    lg: 1250
    // xl: Infinity
  }
});

Vue.prototype.$http = axios;

Vue.prototype.$eventHub = new Vue();

//
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount(`#app`);
