<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header" id="modalTitle">
          <div class="">
            <slot name="header"></slot>
          </div>

          <a
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
            x
          </a>
        </header>
        <section class="modal-body" id="modalDescription">
          <QuoteForm
            :productName="this.productName"
            :selectedModules="this.selectedModules"
            :channelCount="this.channelCount"
            :contactEmail="contactEmail"
            :isConfiguratorQuote="true"
          />
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import QuoteForm from "./QuoteForm.vue";

export default {
  name: "QuoteRequest",
  components: {
    QuoteForm
  },
  data() {
    return {};
  },
  props: {
    productName: String,
    channelCount: Number,
    selectedModules: Array,
    contactEmail: String,
    isConfiguratorQuote: Boolean
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
$brand-yellow: #f4ab01;

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  max-height: 800px;
  overflow: hidden;

  @media (max-width: 450px) {
    max-height: 500px;
  }
  @media (max-width: 400px) {
    max-height: 400px;
  }
}

.modal-header {
  padding: 20px 100px;
  display: flex;
  position: relative;
  right: 0;
  top: 0;
  @media (max-width: 450px) {
    padding: 5px 50px;
  }
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  background-color: $brand-yellow;
  opacity: 0.9;
  color: white;
  justify-content: space-between;
}

.modal-body {
  position: relative;
  margin: 20px 100px;
  max-width: 60vw;
  @media (max-width: 650px) {
    margin: 10px 40px;
  }
}

#quoteList {
  padding: 20px 0 20px 60px;
}

.btn-close {
  border: none;
  text-align: right;
  font-size: 20px;
  // padding: 20px;
  cursor: pointer;
  font-weight: bold;
  color: white;
  background: transparent;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s ease;
}
</style>
