<template lang="html">
  <div class="table">
    <div class="adaptorTitles">
      <div v-for="key in adaptorKeys" class="">
        <div v-if="key == 'photo'">
          <span style="color: white"> </span>
        </div>
        <div class="" v-else>
          {{ key }}
        </div>
      </div>
    </div>
    <div class="bar">
      <template v-for="(adaptor, index) in adaptors">
        <div class="adaptorTable">
          <div
            :id="`accordion-${index}`"
            class="accordion"
            :class="{ active: accordionState[index].active }"
            @click="toggleActive(index)"
          >
            <div v-for="key in adaptorKeys">
              <div v-if="key == 'photo'" class="accordion__item">
                <img :src="adaptor[key]" width="120px" alt="" />
              </div>
              <div v-else class="accordion__item">
                <p>{{ adaptor[key] }}</p>
              </div>
            </div>
          </div>
          <div :ref="`panel-${index}`" class="panel">
            <p style="padding: 0 50px">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
let adaptorKeys = [
  "photo",
  "product ID",
  "connector1",
  "connector2",
  "channels",
  "length",
  "width (mm)"
];
let adaptors = [
  {
    photo: require("../images/accessories/adaptors/ADPT-NZA-DIP16.png"),
    "product ID": "ADPT-NZA-DIP16",
    connector1: "DIP16 IC socket",
    connector2: "Samtec MOLC",
    channels: "16",
    length: "27.9",
    "width (mm)": "15.9"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-NZA-SSB6.png"),
    "product ID": "ADPT-NZA-SSB6",
    connector1: "2 x Molex Slimstack SSB6",
    connector2: "Samtec MOLC",
    channels: "64",
    length: "52.7",
    "width (mm)": "13.4"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-OMN-SSB6.png"),
    "product ID": "ADPT-OMN-SSB6",
    connector1: "2 x Omnetics NSD36",
    connector2: "2 x Molex Slimstack SSB6",
    channels: "64",
    length: "14.7",
    "width (mm)": "13.0"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-ZIF33-SSB6.png"),
    "product ID": "ADPT-ZIF33-SSB6",
    connector1: "Hirose FH-43B ZIF",
    connector2: "2 x Molex Slimstack SSB6",
    channels: "32",
    length: "10.0",
    "width (mm)": "4.0"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-ZIF71-SSB6.png"),
    "product ID": "ADPT-ZIF71-SSB6",
    connector1: "Hirose FH-43B ZIF",
    connector2: "2 x Molex Slimstack SSB6",
    channels: "64",
    length: "15.9",
    "width (mm)": "11.0"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-NZA-OMN32.png"),
    "product ID": "ADPT-NZA-OMN32",
    connector1: "2 x Omnetics NSD36",
    connector2: "Samtec MOLC",
    channels: "32",
    length: "33.0",
    "width (mm)": "13.0"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-NZA-OMN64.png"),
    "product ID": "ADPT-NZA-OMN64",
    connector1: "2 x Omnetics NSD36",
    connector2: "2 x Samtec MOLC",
    channels: "64",
    length: "51.5",
    "width (mm)": "13.3"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-SAM-OMN64.png"),
    "product ID": "ADPT-SAM-OMN64",
    connector1: "2 x Samtec FOLC",
    connector2: "2 x Omnetics NPD36",
    channels: "64",
    length: "43.3",
    "width (mm)": "21.0"
  },
  {
    photo: require("../images/accessories/adaptors/ADPT-TET128.png"),
    "product ID": "ADPT-TET128",
    connector1: "2 x Molex Slimstack SSB6",
    connector2: "4 x Molex Slimstack SSB6",
    channels: "128",
    length: "30.0",
    "width (mm)": "25.6"
  }
];

let accordionState = [
  { index: 0, active: false },
  { index: 1, active: false },
  { index: 2, active: false },
  { index: 3, active: false },
  { index: 4, active: false },
  { index: 5, active: false },
  { index: 6, active: false },
  { index: 7, active: false },
  { index: 8, active: false }
];
export default {
  name: `accordion`,
  data() {
    return {
      adaptorKeys: adaptorKeys,
      adaptors: adaptors,
      accordionState: accordionState
    };
  },
  computed: {
    // isActive() {}
  },
  methods: {
    // toggleActive(index) {
    //   this.accordionState[index].active = !this.accordionState[index].active;
    //   let panel = this.$refs[`panel-${index}`][0];
    //
    //   if (panel.style.maxHeight) {
    //     panel.style.maxHeight = null;
    //   } else {
    //     panel.style.maxHeight = panel.scrollHeight + "px";
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  // padding: 100px 0;
  // margin: 0 200px;
  // height: 100vh;
}

// .bar {
//   @media (max-width: 1000px) {
//     width: 80%;
//     overflow-x: scroll;
//   }
// }

.bar {
  height: 400px;
  overflow: scroll;
}

.adaptorTitles {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  justify-items: center;
  @media (max-width: 800px) {
    letter-spacing: 0;
    font-size: 10px;
  }
}

.accordion {
  // background-color: #eee;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  justify-items: center;
  color: #444;
  // cursor: pointer;
  padding: 0px 0px;
  text-align: left;
  border-top: solid 1px #ccc;
  border-right: solid 1px #ccc;
  border-left: solid 1px #ccc;
  outline: none;
  transition: 0.4s;

  @media (max-width: 800px) {
    width: 600px;
  }

  &__item p {
    font-size: 12px;

    @media (max-width: 800px) {
      letter-spacing: 0;
      font-size: 10px;
    }
  }
}

.adaptorTable:nth-child(even) {
  background-color: #f2f2f2;
}

.adaptorTable:last-child {
  border-bottom: solid 1px #ccc;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion:hover {
  background-color: #ddd;
}

.panel {
  padding: 0px 0px;
  background-color: white;
  border-left: solid 1px #ccc;
  border-right: solid 1px #ccc;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.accordion-fade-slide {
  &-enter-active,
  &-leave-active {
    transition: all 0.3s;
  }
  &-enter {
    transform: translateY(-25px);
    opacity: 0;
  }
  &-leave-to {
    transform: translateY(25px);
    opacity: 0;
  }
}
</style>
