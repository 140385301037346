<template>
  <div class="hsConfigurator">
    <!-- <h3>HS-W</h3> -->
    <p>
      <!-- Fully customizable Wireless headstage. -->
    </p>

    <h3>Channels</h3>
    <div class="btn-group">
      <button
        v-for="(channelCount, index) in channelCount"
        :key="channelCount.count"
        @click="selectChannelCount(index)"
        :class="{ active: channelCount.active }"
      >
        <span> {{ channelCount.count }}</span>
      </button>
    </div>

    <h3>
      Weight: {{ animatedNumber }} g ( with battery:
      {{ Math.round((weight + batteryWeight) * 100) / 100 }} g)
    </h3>
    <h3>Footprint: 12.0 x 10.4 mm</h3>

    <h3>Add-on Modules</h3>
    <div class="button-grid" id="hsw">
      <button
        v-for="module in modules"
        @click="toggleActive(module.name)"
        @mouseover="showDescription(module.name)"
        @mouseleave="hideDescription('generic')"
        :class="{ active: isModuleActive(module.name) }"
      >
        <span> {{ module.name }}</span>
      </button>
    </div>

    <h3 :class="{ grayedOut: toggleSleep == 'yes' || toggleStandby == 'yes' }">
      Recording Duration
    </h3>

    <div class="rangeSlider">
      <input
        type="range"
        min="0.5"
        :max="maxTimeAllowed"
        step="0.25"
        v-model.number="recordingDuration"
        class="slider"
        name="slider"
        :disabled="toggleStandby == 'yes'"
      />

      <label for="slider">
        <div :class="{ grayedOut: toggleStandby == 'yes' }">
          {{ Math.round(maxTimeAllowed * 10) / 10 }} Hrs
        </div>
      </label>
    </div>

    <div class="timeSlider" :class="{ grayedOut: toggleStandby == 'yes' }">
      Time: {{ Math.round(recordingDuration * 10) / 10 }} Hrs
    </div>

    <h3>Sample Rate</h3>
    <input type="radio" name="sample-rate1" v-model="sampleRate" :value="1" />
    <label for="sample-rate1">
      1kSps
    </label>
    <input
      type="radio"
      name="sample-rate3"
      v-model="sampleRate"
      :value="20"
      checked
    />
    <label for="sample-rate3">
      20kSps
    </label>
    <br />

    <mq-layout mq="s+">
      <h3>Example Configurations</h3>
      <div class="btn-animals">
        <button type="button" name="button" @click="setState('bird')">
          <img src="../../icons/bird.svg" height="40px" alt="" />
        </button>
        <button type="button" name="button" @click="setState('mouse')">
          <img src="../../icons/mouse.svg" height="40px" alt="" />
        </button>
        <button type="button" name="button" @click="setState('rat')">
          <img src="../../icons/rat.svg" height="40px" alt="" />
        </button>
        <button type="button" name="button" @click="setState('monkey')">
          <img src="../../icons/monkey.svg" height="40px" alt="" />
        </button>
      </div>
    </mq-layout>
  </div>
</template>

<script>
let initialImg = require("../../images/HS-W/hs-w_1amps-1memory.png");

import { store, mutations } from "../../store";

let modules = [
  { name: "optoStim" },
  { name: "eStim" },
  { name: "imu" },
  { name: "adc" },
  { name: "mic" },
  { name: "gpio" }
];

let activeOptions = {
  optoStim: false,
  eStim: false,
  imu: false,
  adc: false,
  mic: false,
  gpio: false
};

// Weight values are in units of g
let weights = {
  optoStim: 0.035,
  eStim: 0.055,
  imu: 0.028,
  adc: 0.02,
  mic: 0.12,
  gpio: 0.015,
  magnet: 0.24,
  tracking: 0.0,
  memMod: 0.78,
  mmx: 0.25,
  hsAsmp: 0.32
};

let hsCpldB = 0.41;
let tBoard = 0.2;
let radioMod = 0.45;

let memWeight = 0.78;
let hsWeight = 0.32;

// Current values are in units of mA
let optionsCurrents = {
  optoStim: 0,
  eStim: 0,
  imu: 2,
  adc: 4,
  mic: 2,
  gpio: 0,
  mmx: 13,
  hardwired: 0,
  tracking: 3
};

let modCurrents = {
  1: { 25: 59, 20: 34, 12.5: 45, 1: 20 },
  2: { 25: 70, 20: 52, 12.5: 56, 1: 29 },
  3: { 25: 94, 20: 94, 12.5: 73, 1: 53 },
  4: { 25: 134, 20: 134, 12.5: 102, 1: 70 },
  5: { 25: 134, 20: 134, 12.5: 102, 1: 70 }
};
// @todo: need real numbers for 3, 4, 5 above still

let baseWeight =
  Math.round((hsCpldB + tBoard + radioMod + hsWeight + memWeight) * 100) / 100;

let channelCount = [
  { count: "64", active: true },
  { count: "128", active: false },
  { count: "192", active: false },
  { count: "256", active: false },
  { count: "320", active: false }
];

let animalState = [
  { name: "bird", active: false },
  { name: "mouse", active: false },
  { name: "rat", active: false },
  { name: "monkey", active: false }
];

let memState = {
  timeUnit: 1.0,
  sampleUnit: 1,
  channelUnit: 1
};

let allowedMemUnits = {
  1: [1, 2, 3, 4],
  2: [1, 2, 4],
  3: [3],
  4: [2, 4],
  5: [3]
};

export default {
  name: `HsWModules`,
  data() {
    return {
      modules: modules,
      activeOptions: activeOptions,
      memState: memState,
      weight: baseWeight,
      tweenedNumber: baseWeight,
      channelCount: channelCount,
      numberOfAmps: 1,
      numberOfMems: 1,
      numModulesSelected: 0,
      sampleRate: 20,
      memUnitsAllowed: [1, 2, 3, 4],
      recordingDuration: 3,
      memCount: 1,
      batteryWeight: 0,
      standbySelected: false,
      sleepSelected: false,
      toggleSleep: "no",
      toggleStandby: "no",
      sleepOrStandby: [
        { name: "sleep", active: false },
        { name: "standby", active: false }
      ],
      animalState: animalState
    };
  },
  computed: {
    maxTimeFixedMemory() {
      return (
        (this.numberOfMems / this.numberOfAmps) * (20 / this.sampleRate) * 3.5
      );
    },
    maxTimeAllowed() {
      return (
        (Math.max(...this.memUnitsAllowed) / this.numberOfAmps) *
        (20 / this.sampleRate) * 3.5
      );
    },
    memoryQuantum() {
      let scalar = Math.min(...this.memUnitsAllowed);
      return this.memUnitsAllowed.map(
        a => (a * this.maxTimeFixedMemory) / scalar
      );
    },
    animatedNumber() {
      return this.tweenedNumber.toFixed(2);
    }
  },
  beforeMount: function() {
    this.batteryWeight = this.computeBatteryWeight(
        this.recordingDuration,
        this.numberOfAmps
      );
  },
  watch: {
    activeOptions: {
      handler(val) {
        let numModules = 0;
        let mods = Object.entries(this.activeOptions);
        mods.forEach(modState => {
          if (
            modState[0] != "tracking LEDs" &&
            modState[0] != "mag-tether" &&
            modState[0] != "hardwired"
          ) {
            if (modState[1]) {
              numModules += 1;
            }
          }
        });
        this.numModulesSelected = numModules;
        // let modulesSelected = this.computeImgString;
        this.weight = this.computeHSWeight();
        this.batteryWeight = this.computeBatteryWeight(
          this.recordingDuration,
          this.numberOfAmps
        );
        let memString = `amps${this.numberOfAmps}-mems${this.memCount}-mmx${
          this.numModulesSelected
        }.png`;
        this.$emit("moduleSelected", {
          imgString: memString,
          activeOptions: this.activeOptions,
          weight: this.weight,
          batteryWeight: this.batteryWeight,
          maxRecordingDuration: this.maxTimeAllowed
        });
      },
      deep: true
    },
    weight(newValue) {
      TweenLite.to(this.$data, 0.3, { tweenedNumber: newValue }).delay(0.3);
    },
    numberOfAmps(newValue) {
      this.memUnitsAllowed = allowedMemUnits[newValue];
      this.numberOfMems = Math.min(...this.memUnitsAllowed);
      this.memCount = this.numberOfMems;
      this.recordingDuration = this.maxTimeFixedMemory;

      let memString = `amps${this.numberOfAmps}-mems${this.memCount}-mmx${
        this.numModulesSelected
      }.png`;

      this.weight = this.computeHSWeight();
      this.batteryWeight = this.computeBatteryWeight(
        this.recordingDuration,
        newValue
      );

      this.$emit("memStateUpdated", memString);
    },
    channelCount(newValue) {},
    sampleRate(newValue) {
      this.numberOfMems = Math.min(...this.memUnitsAllowed);
      this.recordingDuration = this.maxTimeFixedMemory;
      this.batteryWeight = this.computeBatteryWeight(
        this.recordingDuration,
        this.numberOfAmps
      );

      mutations.setSampleRate(newValue);
    },
    recordingDuration(newValue) {
      this.batteryWeight = this.computeBatteryWeight(
        newValue,
        this.numberOfAmps
      );
      if (newValue > this.maxTimeAllowed) {
        this.recordingDuration = this.maxTimeAllowed;
      } else {
        this.memoryQuantum.forEach((item, index) => {
          if (newValue > item) {
            this.memCount = this.memUnitsAllowed[index + 1];
          } else if (newValue <= this.memoryQuantum[0]) {
            this.memCount = Math.min(...this.memUnitsAllowed);
          }
        });
      }
      mutations.setRecordingDuration(newValue);
    },
    memCount(newValue) {
      let memString = `amps${this.numberOfAmps}-mems${this.memCount}-mmx${
        this.numModulesSelected
      }.png`;

      this.weight = this.computeHSWeight();
      this.batteryWeight = this.computeBatteryWeight(
        this.recordingDuration,
        this.numberOfAmps
      );

      this.$emit("memStateUpdated", memString);
    }
  },
  methods: {
    computeHSWeight() {
      let entries = Object.entries(this.activeOptions);
      let modWeight = 0;

      let numModsSelected = 0;

      for (let [key, value] of entries) {
        if (value) {
          modWeight += weights[key];
          numModsSelected += 1;
        }
      }
      let totalMMXWeight = Math.ceil(numModsSelected / 3) * weights["mmx"];
      let totalHsWeight = hsWeight * (this.numberOfAmps - 1);
      let totalMemWeight = memWeight * (this.memCount - 1);

      let totalWeight =
        totalHsWeight +
        totalMemWeight +
        modWeight +
        totalMMXWeight +
        baseWeight;

      mutations.setWeight(Math.round(totalWeight * 100) / 100);

      return Math.round(totalWeight * 100) / 100;
    },
    computeBatteryWeight(recordingDuration, channelCount) {
      let entries = Object.entries(this.activeOptions);
      let cumCurrent = 0;
      let numModsSelected = 0;

      for (let [key, value] of entries) {
        if (value) {
          cumCurrent += optionsCurrents[key];
          numModsSelected += 1;
        }
      }

      let totalMMXCurrent =
        Math.ceil(numModsSelected / 3) * optionsCurrents["mmx"];
      let modCurrent = modCurrents[channelCount][this.sampleRate];

      let totalCurrent = cumCurrent + totalMMXCurrent + modCurrent;

      let totalWeight = (recordingDuration * totalCurrent) / 50;

      mutations.setBatteryWeight(Math.round(totalWeight * 100) / 100);

      return Math.round(totalWeight * 100) / 100;
    },
    selectChannelCount(index) {
      this.channelCount.forEach(function(channelCount) {
        channelCount.active = false;
      });
      this.channelCount[index].active = !this.channelCount[index].active;

      this.numberOfAmps = index + 1;
      this.memState.channelUnit = index + 1;
    },
    isModuleActive(value) {
      return this.activeOptions[value];
    },
    toggleActive(id) {
      // this.$emit("modClick", id);
      this.activeOptions[id] = !this.activeOptions[id];
    },
    setState(animal) {
      this.animalState.forEach(function(animal) {
        animal.active = false;
      });
      this.modules.forEach(module => {
        this.activeOptions[module.name] = false;
      });

      if (animal == "bird") {
        this.selectChannelCount(0);
        this.animalState[0].active = true;

        this.$nextTick(() => {
          this.recordingDuration = 1;
        });
      } else if (animal == "rat") {
        this.selectChannelCount(3);
        this.animalState[1].active = true;

        this.toggleActive("eStim");
        this.toggleActive("imu");
        this.$nextTick(() => {
          this.recordingDuration = 2.5;
        });
      } else if (animal == "mouse") {
        this.selectChannelCount(2);
        this.animalState[2].active = true;

        this.toggleActive("imu");
        this.toggleActive("optoStim");
        this.toggleActive("mic");
        this.$nextTick(() => {
          this.recordingDuration = 2.5;
        });
      } else if (animal == "monkey") {
        this.selectChannelCount(4);
        this.animalState[3].active = true;
        this.toggleActive("imu");
        this.toggleActive("optoStim");
        this.toggleActive("eStim");
        this.toggleActive("mic");
        this.toggleActive("adc");
        this.toggleActive("gpio");
        this.$nextTick(() => {
          this.recordingDuration = 1.5;
        });
      }
    },
    check(value) {
      console.log(name);
      this.sleepOrStandby.forEach(item => {
        item.active = false;
      });
      if (value == "standby" && this.toggleSleep == "yes") {
        this.toggleSleep = "no";
      } else if (value == "sleep" && this.toggleStandby == "yes") {
        this.toggleStandby = "no";
      }
      this.$emit("modClick", value);
    },
    showDescription(id) {
      this.$emit("modClick", id);
    },
    hideDescription(id) {
      this.$emit("modClick", "generic");
    }
  }
};
</script>

<style lang="scss" scoped>
label,
input {
  margin-left: 5px;
}
.grayedOut {
  color: hsla(0, 0%, 60%, 0.5);
}
.stateBtns {
  position: absolute;
  top: 30px;
  left: 300px;
  z-index: 10;
  height: 30px;
}
.slider {
  width: 200px;
  @media (max-width: 450px) {
    width: 100px;
  }
}

.rangeSlider {
  display: flex;
  @media (max-width: 450px) {
    font-size: 10px;
  }
}

.timeSlider {
  @media (max-width: 450px) {
    font-size: 10px;
  }
}

label {
  @media (max-width: 450px) {
    font-size: 10px;
  }
}

.btn-animals {
  button {
    height: 60px;
    width: 60px;
    border-radius: 10px;
    padding-top: 5px;
    // background-color: hsl(0, 0%, 80%);
  }
}
</style>
