import Vue from "vue";

export const store = Vue.observable({
  productName: "",
  weight: "",
  batteryWeight: "",
  footPrint: "",
  recordingDuration: "3.5",
  sampleRate: "25",
  imgString: "",
  selectedModules: {}
});

export const mutations = {
  setProductName(name) {
    store.productName = name;
  },
  setWeight(weight) {
    store.weight = weight;
  },
  setBatteryWeight(batteryWeight) {
    store.batteryWeight = batteryWeight;
  },
  setFootPrint(footPrint) {
    store.footPrint = footPrint;
  },
  setRecordingDuration(recordingDuration) {
    store.recordingDuration = recordingDuration;
  },
  setSampleRate(sampleRate) {
    store.sampleRate = sampleRate;
  },
  setImgString(imgString) {
    store.imgString = imgString;
  },
  setSelectedModules(selectedModules) {
    store.selectedModules = selectedModules;
  }
};
