<template lang="html">
  <div class="techSpecs">
    <table id="customers">
      <tr v-for="spec in specs">
        <td>{{ spec.spec }}</td>
        <td>{{ spec.description }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
let specs = [
  {
    spec: "Camera Hub Capacity",
    description: "Models: 7 camera ports + 1 uplink, or 15 camera ports + 1 uplink"
  },
  {
    spec: "Resolution",
    description: "640×480 – 1600×1200 (0.3 – 1.9 MP)"
  },
  {
    spec: "Framerate",
    description: "15 – 90 fps"
  },
  {
    spec: "Video Format",
    description: "YUV 4:2:0 color video in MP4-wrapped H264, or AVI-wrapped MJPEG"
  },
  {
    spec: "Streaming latency",
    description: `2 frames (22 ms @ 90fps, 67ms @ 30fps) in MJPEG mode`
  },
  {
    spec: "Spectral Profile",
    description: "Full color with IR sensitivity up to 1000nm"
  },
  {
    spec: "Lens Compatibility",
    description: "C or CS-mount lenses"
  },
  {
    spec: "Synchronization",
    description: `Internal and external TTL frame sync sources, BNC to output, hubs can be daisy-chained`
  },
  {
    spec: "Sync Jitter",
    description: "1ms"
  },
  {
    spec: "Start/stop",
    description: `Software trigger, HTTP API trigger, peri-event triggering with MJPEG`
  },
  {
    spec: "Mounting",
    description: `GoPro-style tripod thread adapter included`
  },
  {
    spec: "Stock lens",
    description: "4.0mm f/1.4, FOV: 49°"
  },
  {
    spec: "Wide lens",
    description: "2.2mm f/1.2, FOV: 89° (Other lenses upon request)"
  },
  {
    spec: "Hub ↔ Camera Interface",
    description: "Video + power + sync over single Ethernet cable"
  },
  {
    spec: "Hub ↔ PC Interface",
    description: "GigE uplink"
  },
  {
    spec: "Software compatibility",
    description: "Windows (x64), Linux (x86-64), MacOS"
  },
  {
    spec: "Camera Dimensions",
    description: "3 × 1.9 × 1.2 inches (76 × 48 × 30 mm)"
  },
  {
    spec: "Camera Dimensions (with stock lens)",
    description: "3 × 1.9 × 2.0 inches (76 × 48 × 50 mm)"
  },
  {
    spec: "Hub Dimensions (7-port)",
    description: "6.6 × 4.1 × 1.0 inches (168 × 104 × 25 mm)"
  },
  {
    spec: "Hub Dimensions (15-port)",
    description: "11.3 × 4.6 × 1.0 inches (287 × 117 × 25 mm)"
  }
];

export default {
  name: `tabTechnicalSpecs`,
  data() {
    return {
      specs: specs
    };
  }
};
</script>

<style lang="scss" scoped>
.techSpecs {
  height: inherit;
  background-color: white;
  overflow-y: scroll;
}
#customers {
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
  font-weight: 300;
  font-size: 14px;

  @media (max-width: 600px) {
    font-size: 10px;
  }
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}
</style>
