import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";

import Index from "./views/Index.vue";

import Products from "./views/Products.vue";
import ECubeServer from "./views/ECubeServer.vue";
import Headstages from "./views/Headstages.vue";
import E3Vision from "./views/E3Vision.vue";
import NanoZ from "./views/NanoZ.vue";
import Accessories from "./views/Accessories.vue";

import About from "./views/About.vue";

import Support from "./views/Support.vue";

import Quote from "./views/Quote.vue";

import IpadIndex from "./views/IpadIndex.vue";
import IpadConfigurator from "./views/SFNConfigurator.vue";
import Survey from "./views/Survey.vue";

import IpadPrintForm from "./components/IpadPrintForm.vue";

import ConfiguratorQuoteForm from "./components/ConfiguratorQuoteForm.vue";

Vue.use(Router);
Vue.use(Meta);

export default new Router({
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      let position = { x: 0, y: 0 };
      // Keep scroll position when using browser buttons
      if (savedPosition) {
        position = savedPosition;
      }
      // Workaround for transitions scrolling to the top of the page
      // However, there are still some problems being fixed by the vue team
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(position);
        }, 300);
      });
    }
  },
  routes: [
    {
      path: `/`,
      name: `index`,
      component: Index
    },
    {
      path: `/index.html`,
      name: `index`,
      component: Index
    },
    {
      path: `/products`,
      name: `products`,
      component: Products
    },
    {
      path: `/about`,
      name: `about`,
      component: About
    },
    {
      path: `/support`,
      name: `support`,
      component: Support
    },
    {
      path: `/products/eCubeHeadstages`,
      name: `headstages`,
      component: Headstages
    },
    {
      path: `/products/nanoZ`,
      name: `nanoZ`,
      component: NanoZ
    },
    {
      path: `/products/e3Vision`,
      name: `e3Vision`,
      component: E3Vision
    },
    {
      path: `/products/eCubeServer`,
      name: `eCubeServer`,
      component: ECubeServer
    },
    {
      path: `/products/accessories`,
      name: `accessories`,
      component: Accessories
    },
    {
      path: `/quote/confirmation`,
      name: `quote`,
      component: Quote
    },
    {
      path: `/ConfiguratorQuoteForm`,
      name: `ConfiguratorQuoteForm`,
      component: ConfiguratorQuoteForm
    },
    {
      path: "/sfn2019",
      name: `SFNIndex`,
      component: IpadIndex
    },
    {
      path: "/configurator",
      name: `configurator`,
      component: IpadConfigurator
    },
    {
      path: "/survey",
      name: `survey`,
      component: Survey
    },
    {
      path: "/printForm",
      name: `printForm`,
      component: IpadPrintForm
    }
  ],
  mode: `history`
});
