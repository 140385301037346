<template>
  <div>
    <div class="sidebar">
      <div
        class="sidebar-backdrop"
        @click="closeSidebarPanel"
        v-if="isPanelOpen"
      ></div>
      <transition name="slide">
        <div v-if="isPanelOpen" class="sidebar-panel">
          <!-- <slot></slot> -->
          <ul class="sidebar-panel-nav">
            <li @click="closeSidebarPanel">
              <router-link to="/">Home</router-link>
            </li>
            <li @click="closeSidebarPanel">
              <router-link to="/products">
                Products
              </router-link>
            </li>
            <li @click="closeSidebarPanel">
              <router-link to="/about">
                About
              </router-link>
            </li>
            <li @click="closeSidebarPanel">
              <router-link to="/support">
                Support
              </router-link>
            </li>
          </ul>
        </div>
      </transition>
      <div v-if="isPanelOpen" class="closeMe" @click="closeSidebarPanel">X</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    isPanelOpen() {
      return this.isSideBarActive ? true : false;
    }
  },
  props: {
    isSideBarActive: Boolean
  },
  methods: {
    closeSidebarPanel() {
      // this.isSideBarActive = false;
      this.$emit("closeSideBar");
    }
  }
};
</script>
<style>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 150ms ease-in 0s;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
}

.sidebar-panel {
  overflow-y: auto;
  background-color: hsl(0, 0%, 99%);
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  padding: 3rem 0px 2rem 50px;
  width: 200px;
}
ul.sidebar-panel-nav {
  list-style-type: none;
}

ul.sidebar-panel-nav > li > a {
  color: hsl(0, 0%, 30%);
  text-decoration: none;
  font-size: 1.2rem;
  display: block;
  padding-bottom: 0.5em;
}

.closeMe {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  color: black;
  z-index: 10000;
}
</style>
