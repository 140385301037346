<template lang="html">
  <div class="wraps">
    <div class="blah">
      <img src="../images/flyer/Flyer_.png" width="1000px" alt="" />
    </div>
    <div class="hs32Image" v-if="this.productName == 'HS-32'">
      <img :src="imgString" width="320px" alt="" />
    </div>
    <div class="hs64Image" v-if="this.productName == 'HS-64'">
      <img :src="imgString" width="320px" alt="" />
    </div>
    <div class="hs640Image" v-if="this.productName == 'HS-640'">
      <img :src="imgString" width="200px" alt="" />
    </div>
    <div class="hsWImage" v-if="this.productName == 'HS-W'">
      <img :src="imgString" width="170px" alt="" />
    </div>

    <div class="hsText" v-if="this.productName == 'HS-32'">
      <div style="padding-left: 140px">
        <h2>{{ productName }}</h2>
      </div>
      <ul style="padding-top: 10px">
        <li class=""><b>Channels</b>: {{ getChannelCount }}</li>
        <li><b>Weight</b>: {{ weight }}g</li>
        <li><b>Footprint</b>: 8 x 5 mm</li>
        <li v-if="modObjectToMMXList.length !== 0">
          <span> <b>Factory Modules</b>: </span>
          <template
            v-for="(mod, index) in modObjectToMMXList.concat(
              modObjectToFactoryList
            )"
          >
            <span
              v-if="
                index !==
                  modObjectToMMXList.concat(modObjectToFactoryList).length - 1
              "
            >
              {{ mod }},</span
            >
            <span v-else> {{ mod }} </span>
          </template>
        </li>
        <li><b>Bandwidth</b>: 0.1Hz ~10kHz</li>
        <li>
          <b>Noise Floor</b>: 2.0 uV RMS (grounded inputs, full bandwidth)
        </li>
        <li><b>Sampling</b>: 14 bit ADC, 25 kSps</li>
        <li>
          <b>Data Cable</b>: 6 wire ultra-fine gigabit cable (1.0 ~ 1.5mm, O.D,
          optional Kevlar sheath)
        </li>
      </ul>
    </div>

    <div class="hsText" v-if="this.productName == 'HS-64'">
      <div style="padding-left: 140px">
        <h2>{{ productName }}</h2>
      </div>
      <ul style="padding-top: 10px">
        <li class=""><b>Channels</b>: {{ getChannelCount }}</li>
        <li><b>Weight</b>: {{ weight }}g</li>
        <li><b>Footprint</b>: 7.5 x 8.0 mm</li>
        <li v-if="modObjectToFactoryList.length !== 0">
          <span> <b>Factory Modules</b>: </span>
          <template v-for="(mod, index) in modObjectToFactoryList">
            <span v-if="index !== modObjectToFactoryList.length - 1">
              {{ mod }},</span
            >
            <span v-else> {{ mod }} </span>
          </template>
        </li>
        <li>
          <span v-if="modObjectToMMXList.length !== 0">
            <b>MMX Modules</b>:
          </span>
          <template v-for="(mod, index) in modObjectToMMXList">
            <span v-if="index !== modObjectToMMXList.length - 1">
              {{ mod }},</span
            >
            <span v-else> {{ mod }} </span>
          </template>
        </li>
        <li><b>Bandwidth</b>: 0.1Hz ~10kHz</li>
        <li>
          <b>Noise Floor</b>: 2.0 uV RMS (grounded inputs, full bandwidth)
        </li>
        <li><b>Sampling</b>: 14 bit ADC, 25 kSps</li>
        <li>
          <b>Data Cable</b>: 6 wire ultra-fine gigabit cable (1.0 ~ 1.5mm, O.D,
          optional Kevlar sheath)
        </li>
      </ul>
    </div>

    <div class="hsText" v-if="this.productName == 'HS-640'">
      <div style="padding-left: 140px">
        <h2>{{ productName }}</h2>
      </div>
      <ul style="padding-top: 10px">
        <li class=""><b>Channels</b>: {{ getChannelCount }}</li>
        <li><b>Weight</b>: {{ weight }}g</li>
        <li><b>Footprint</b>: 10.4 x 10.9 mm</li>
        <li v-if="modObjectToFactoryList.length !== 0">
          <span> <b>Factory Modules</b>: </span>
          <template v-for="(mod, index) in modObjectToFactoryList">
            <span v-if="index !== modObjectToFactoryList.length - 1">
              {{ mod }},</span
            >
            <span v-else> {{ mod }} </span>
          </template>
        </li>
        <li v-if="modObjectToMMXList.length !== 0">
          <span> <b>MMX Modules</b>: </span>
          <template v-for="(mod, index) in modObjectToMMXList">
            <span v-if="index !== modObjectToMMXList.length - 1">
              {{ mod }},</span
            >
            <span v-else> {{ mod }} </span>
          </template>
        </li>
        <li><b>Bandwidth</b>: 0.1Hz ~10kHz</li>
        <li>
          <b>Noise Floor</b>: 2.0 uV RMS (grounded inputs, full bandwidth)
        </li>
        <li><b>Sampling</b>: 14 bit ADC, 25 kSps</li>
        <li>
          <b>Data Cable</b>: 6 wire ultra-fine gigabit cable (1.0 ~ 1.5mm, O.D,
          optional Kevlar sheath)
        </li>
      </ul>
    </div>

    <div class="hsText" v-if="this.productName == 'HS-W'">
      <div style="padding-left: 140px">
        <h2>{{ productName }}</h2>
      </div>
      <ul style="padding-top: 10px">
        <li class=""><b>Channels</b>: {{ getChannelCount }}</li>
        <li>
          <b>Weight</b>: {{ weight }}g (with battery
          {{ weight + batteryWeight }}g)
        </li>
        <li><b>Footprint</b>: 12.0 x 10.4 mm</li>
        <li v-if="modObjectToFactoryList.length !== 0">
          <span> <b>Factory Modules</b>: </span>
          <template v-for="(mod, index) in modObjectToFactoryList">
            <span v-if="index !== modObjectToFactoryList.length - 1">
              {{ mod }},</span
            >
            <span v-else> {{ mod }} </span>
          </template>
        </li>
        <li v-if="modObjectToMMXList.length !== 0">
          <span> <b>MMX Modules</b>: </span>
          <template v-for="(mod, index) in modObjectToMMXList">
            <span v-if="index !== modObjectToMMXList.length - 1">
              {{ mod }},</span
            >
            <span v-else> {{ mod }} </span>
          </template>
        </li>
        <li><b>Recording Duration</b>: Up to {{ recordingDuration }} hrs</li>
        <li><b>Bandwidth</b>: 0.1Hz ~10kHz</li>
        <li>
          <b>Noise Floor</b>: 2.0 uV RMS (grounded inputs, full bandwidth)
        </li>
        <li><b>Sampling</b>: 14 bit ADC, 25 kSps</li>
        <li>
          <b>Data Link</b>: 10m radio link for remote control, online monitoring
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { store, mutations } from "../store";

export default {
  name: `flyer`,
  data() {
    return {
      productName: "",
      weight: "",
      footPrint: "",
      recordingDuration: "",
      imgString: "",
      selectedModules: {}
    };
  },
  mounted() {
    this.productName = store.productName;
    this.weight = store.weight;
    this.batteryWeight = store.batteryWeight;
    this.recordingDuration = store.recordingDuration;
    this.imgString = store.imgString;
    this.selectedModules = store.selectedModules;
  },
  computed: {
    modObjectToMMXList() {
      let modList = [];
      let entries = Object.entries(this.selectedModules);

      for (let [key, value] of entries) {
        if (!["tracking LEDs", "mag-tether", "hardwired"].includes(key)) {
          if (value) {
            modList.push(key);
          }
        }
      }
      return modList;
    },
    modObjectToFactoryList() {
      let modList = [];
      let entries = Object.entries(this.selectedModules);

      for (let [key, value] of entries) {
        if (["tracking LEDs", "mag-tether", "hardwired"].includes(key)) {
          if (value) {
            modList.push(key);
          }
        }
      }
      return modList;
    },
    getChannelCount() {
      if (this.productName == "HS-32") {
        return "32";
      } else if (this.productName == "HS-64") {
        return "64";
      } else if (this.productName == "HS-640") {
        return (
          parseInt(
            this.imgString
              .split("/")[2]
              .split("-")[0]
              .slice(4, 6)
          ) * 64
        );
      } else if (this.productName == "HS-W") {
        return parseInt(this.imgString.split("/")[2].split("-")[0][4]) * 64;
      }
    }
  },
  methods: {
    setData(payload) {
      console.log(payLoad);
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.wraps {
  // overflow-x: hidden;
  // overflow-y: hidden;
  background: white;
  height: 1620px;
  width: 1236px;
}

.hs32Image {
  position: absolute;
  top: 240px;
  left: 210px;
}

.hs64Image {
  position: absolute;
  top: 240px;
  left: 210px;
}

.hs640Image {
  position: absolute;
  top: 200px;
  left: 270px;
}
.hsWImage {
  position: absolute;
  top: 220px;
  left: 300px;
}

.hsText {
  position: absolute;
  top: 930px;
  left: 700px;
  font-size: 24px;
  max-width: 570px;
}

.blah {
  opacity: 0;
}
ul {
  list-style-image: none;
  line-height: 20px;
}

li {
  font-size: 20px;
  font-weight: 500;
  color: hsl(0, 0, 10);
}

.foo {
  // max-height: 100vh;
  // max-width: 100vw;
  // position: relative;
  // z-index: 10000000;
}
</style>
