<template lang="html">
  <div class="container">
    <div class="altImgTextLayout">
      <div class="altImgTextLayout__image">
        <img
          src="../images/eCube Server/front side angle.jpg"
          height="574px"
          alt=""
        />
      </div>
      <div class="altImgTextLayout__text heroText">
        <h1>eCube Server</h1>
        <h2>Your entire lab in a cube.</h2>
        <p>
          A fully integrated electrophysiology system that simultaneously hosts
          up to 10 independent experiments with thousands of channels.
        </p>
      </div>
    </div>

    <div class="altImgTextLayout">
      <div class="altImgTextLayout__image">
        <img src="../images/eCube Server/front.png" height="474px" alt="" />
      </div>
      <div class="altImgTextLayout__text">
        <h2>Highly integrated data acquisition.</h2>
        <ul>
          <li id="1">
            <p>
              Manage up to 10 separate experiments, with multiple researchers
              independently controlling start and stop times.
            </p>
          </li>

          <li id="2">
            <p>
              Designed to be paired with the smallest, lightest, and most
              modular headstages in the world.
            </p>
          </li>

          <li id="3">
            <p>
              ServerNode software enables you to save physiological data on a
              central server, while easily monitoring and controlling from your
              own laptop.
            </p>
          </li>
        </ul>
      </div>
    </div>

    <section class="tabbed-ish">
      <div class="">
        <div class="tabbed-info">
          <button
            id="product-tabs"
            v-for="tab in tabs"
            v-bind:key="tab"
            v-bind:class="['tab-button', { active: currentTab === tab }]"
            v-on:click="currentTab = tab"
          >
            {{ tab }}
          </button>
        </div>

        <transition name="component-fade" mode="out-in">
          <component v-bind:is="currentTabComponent" class="tab"></component>
        </transition>
      </div>
    </section>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import tabPackageContents from "../components/products/eCubeServer/tab-PackageContents.vue";
import tabTechnicalSpecs from "../components/products/eCubeServer/tab-TechnicalSpecs.vue";
import tabSoftware from "../components/products/eCubeServer/tab-Downloads.vue";

export default {
  name: `eCubeServer`,
  components: {
    tabPackageContents,
    tabTechnicalSpecs,
    tabSoftware
  },
  data() {
    return {
      tabs: ["Package Contents", "Technical Specs", "Software"],
      currentTab: "Package Contents"
    };
  },
  created() {
    // AOS.init({
    //   duration: 500,
    //   offset: 100,
    //   easing: "ease-in-out-cubic",
    //   once: true
    // });
  },
  computed: {
    currentTabComponent: function() {
      return "tab" + this.currentTab.split(" ").join("");
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 50px;
  display: grid;
  justify-items: center;
  overflow: hidden;

  @media (max-width: 800px) {
    padding-top: 100px;
  }
}

.altImgTextLayout {
  display: flex;
  margin: 0px 200px 100px 200px;

  &:nth-child(even) {
    flex-direction: row-reverse; // 3
  }

  @media (max-width: 1200px) {
    margin: 0px 100px 50px;
  }
  @media (max-width: 900px) {
    margin: 0px 50px 30px;
  }
  @media (max-width: 800px) {
    display: block;
    margin: 0 50px;
  }
  @media (max-width: 600px) {
    margin: 0 40px;
  }

  &__text {
    width: 100%;
    flex: 1;
    align-items: center;
    margin: 0 10px;
    // padding-top: 50px;
    @media (max-width: 1250px) {
      // padding-top: 100px;
    }
    @media (max-width: 1000px) {
      // padding-top: 50px;
    }
    @media (max-width: 800px) {
      padding-top: 10px;
      padding-bottom: 20px;
      margin: 0;
    }
  }

  &__image {
    flex: 1;
    width: 100%;
    margin-left: 10px;
  }

  &__image img {
    height: auto;
    width: 100%;

    @media (max-width: 800px) {
      height: auto;
      width: 70%;
    }
  }
}
.heroText {
  padding-top: 130px;
  @media (max-width: 1250px) {
    padding-top: 100px;
  }
  @media (max-width: 1000px) {
    padding-top: 50px;
  }
  @media (max-width: 800px) {
    padding-top: 0;
    // padding-top: 10px;
    // padding-bottom: 20px;
  }
}

.tabbed-ish {
  padding: 0px 150px 100px 150px;
  width: 80%;

  @media (max-width: 800px) {
    padding: 0 50px 50px 50px;
  }
  @media (max-width: 800px) {
    padding: 0 40px 50px 40px;
  }
}

.tabbed-info {
  border-bottom: 4px solid #f4ab01;
  padding-top: 30px;
}

button#product-tabs {
  width: 160px;
  box-shadow: none;
  border-radius: 0px;
  margin: 0;
  font-size: 18px;
  font-weight: 300;
  color: hsl(0, 0%, 30%);

  @media (max-width: 800px) {
    width: 120px;
    font-size: 14px;
  }
  @media (max-width: 600px) {
    width: 90px;
    font-size: 10px;
  }
}

.tab-button {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
  margin-bottom: -1px;
  background-color: inherit;
}
.tab-button:hover {
  // background: none;
  // color: white;
}
button#product-tabs.active {
  background: #f4ab01;
  color: white;
}

.tab {
  padding: 30px 50px;
  height: 300px;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);

  @media (max-width: 600px) {
    padding: 10px 10px;
  }
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
</style>
