<template lang="html">
  <div class="boundary">
    <div class=" center">
      <h1>Support</h1>
    </div>

    <br />

    <div class="center">
      <h2>Product Documentation</h2>
    </div>
    <div class="warrantyText">
      <p>
        Visit our product documentation and API guide here:
        <a href="https://docs.white-matter.com/docs/">White Matter Documentation</a>
      </p>
    </div>

    <br />

    <div class="center">
      <h2>Warranty</h2>
    </div>
    <div class="warrantyText">
      <p>
        White Matter provides life-time technical support. Our products are
        backed by a five (5) year
        <a href="../../whitematter_warranty.pdf">parts and labor warranty</a>
        from the date of purchase. Headstages and tether cable warranty coverage
        is limited to one (1) year. To request a repair, you must contact us for
        an RMA number before returning the product.
      </p>
    </div>

    <br />

    <div class="center">
      <h2>Customer Support</h2>
    </div>
    <div class="warrantyText">
      <p>
        Your research is important to us. If you are having troubles with one of
        our products, we will dedicate ourselves to finding a solution!
      </p>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
export default {
  name: `Support`
};
</script>

<style lang="scss" scoped>
$brand-yellow: #f4ab01;
.boundary {
  margin: 0 150px;

  overflow: hidden;

  @media (max-width: 1100px) {
    margin: 0 100px;
  }
  @media (max-width: 800px) {
    margin: 0 80px;
  }

  @media (max-width: 600px) {
    margin: 0 50px;
  }
  @media (max-width: 500px) {
    margin: 0 20px;
  }
}

.title {
  font-size: 50px;
  font-weight: 600;
}

.product-title {
  font-size: 30px;
  font-weight: 600;
  color: $brand-yellow;
}

.text {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 2px;
}

.warrantyText {
  text-align: center;
  // margin: 0 150px;
}

.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
