<template>
  <div>
    <transition-group name="product-filter">
      <div
        v-for="product in filteredProductList"
        :key="product.id"
        :class="['product-card-Ipad', { clicked: selectedProduct === product }]"
        @click="productClick(product)"
      >
        <img
          v-if="product.name !== 'HS-640'"
          class="product-thumbnail"
          :src="product.src__thumbnail"
          height="100px"
        />
        <img
          v-else
          class="product-thumbnail"
          :src="product.src__thumbnail"
          height="80px"
        />
        <div class="name">{{ product.name }}</div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: `ProductList`,
  props: {
    products: Array,
    activeFilter: Number | String | undefined,
    filters: Array,
    initialProduct: Object
  },
  data() {
    return {
      selectedProduct: this.initialProduct,
      foo: ""
    };
  },
  computed: {
    filteredProductList() {
      if (this.activeFilter == -1) {
        return this.products;
      } else {
        return this.products.filter(product =>
          product.tags.includes(this.activeFilter)
        );
      }
    }
  },
  methods: {
    productClick(product) {
      this.selectedProduct = product;
      this.$emit("product-clicked", this.selectedProduct);
    }
  }
};
</script>

<style lang="scss" scoped>
$brand-yellow: #f4ab01;

.product-card-Ipad {
  background: #fff;
  border-radius: 1px;
  width: 160px;
  display: grid;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
  // border: 1px solid #ccc;
  cursor: pointer;
  margin: 0px 0 20px 10px;
  border: 0;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
    transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
    border-left 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);

  @media (max-width: 1050px) {
    margin: 0px 0 20px 0px;
  }
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 3px 7px hsla(0, 0%, 0%, 0.2), 0 2px 6px hsla(0, 0%, 0%, 0.2);
}
.product-card.clicked {
  transform: translate(-8px, -5px);
  box-shadow: 0 3px 7px hsla(0, 0%, 0%, 0.2), 0 2px 6px hsla(0, 0%, 0%, 0.2);
  cursor: auto;
  border-left: 4px solid $brand-yellow;
}

.product-thumbnail {
  padding: 10px 0px;
}

.product-thumbnail img {
  @media (max-width: 600px) {
    height: auto;
    width: 100%;
  }
}

.name {
  width: 100%;
  background-color: hsl(0, 0%, 95%);
  color: hsl(0, 0%, 35%);
  text-align: center;
  font-size: 24px;
}

.product-filter-enter,
.product-filter-leave-to {
  opacity: 0;
}

.product-filter-leave-active {
  position: absolute;
  z-index: -1;
  transition: opacity 0.2 ease;
}

.product-filter-enter-active {
  transition-delay: 0.2s;
}
</style>
