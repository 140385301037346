<template lang="html">
  <footer class="footer">
    <div class="footer__large-section">
      <div class="blah">
        <div class="footer__large-section-image">
          <p style="text-align: left">
            White Matter LLC
            <br />
            999 3rd Ave #700
            <br />
            Seattle, WA 98104
          </p>
        </div>

        <div class="footer__large-section-contact">
          <button
            id="footer"
            type="button"
            name="button"
            @click="showSalesModal"
          >
            CONTACT SALES
          </button>
          <QuoteRequest
            v-show="isSalesModalVisible"
            @close="closeSalesModal"
            :contactEmail="'sales'"
          >
            <template v-slot:header>
              <h2>Contact Sales</h2>
            </template>
          </QuoteRequest>
          <button
            id="footer"
            type="button"
            name="button"
            @click="showSupportModal"
          >
            CONTACT SUPPORT
          </button>
          <QuoteRequest
            v-show="isSupportModalVisible"
            @close="closeSupportModal"
            :contactEmail="'info'"
          >
            <template v-slot:header>
              <h2>Contact Support</h2>
            </template>
          </QuoteRequest>
        </div>

        <div class="footer__large-section-image">
          <p style="text-align: left">
            info@white-matter.com
            <br />
            +1 510-409-0144
            <br />
            9am - 6pm PST
          </p>
        </div>
      </div>
    </div>

    <div class="" id="footer-links">
      <router-link to="/">Home</router-link> &middot;
      <router-link to="/products">Products</router-link> &middot;
      <router-link to="/about">About</router-link> &middot;
      <router-link to="/support">Support</router-link>
    </div>
    <div>White Matter LLC &copy; 2020</div>
  </footer>
</template>

<script>
import QuoteRequest from "./QuoteRequest.vue";
export default {
  name: `Footer`,
  data() {
    return {
      isSalesModalVisible: false,
      isSupportModalVisible: false,
      isDemoModalVisible: false
    };
  },
  components: {
    QuoteRequest
  },
  methods: {
    showSalesModal() {
      this.isSalesModalVisible = true;
    },
    closeSalesModal() {
      this.isSalesModalVisible = false;
    },
    showSupportModal() {
      this.isSupportModalVisible = true;
    },
    closeSupportModal() {
      this.isSupportModalVisible = false;
    },
    showDemoModal() {
      this.isDemoModalVisible = true;
    },
    closeDemoModal() {
      this.isDemoModalVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
$brand-yellow: #f4ab01;
.blah {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
}

.footer {
  padding-bottom: 1.5em;
  text-align: center;
  font-size: 12px;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  z-index: 10;
  position: relative;
  bottom: 0;
  background-color: white;

  &__notice {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F7EACD;
    width: 100%;
    margin: 0 auto;
    padding: 1em;

    p {
      width: 80%;
      font-size: 12px;
    }
  }

  &__large-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 120px;
    padding-top: 0.5em;
    // padding-bottom: 1.5em;
    text-align: center;
    font-size: 12px;
    z-index: 10;
    position: relative;
    background-color: white;

    &-image {
      margin: 0 10px;
    }

    &-image > p {
      font-size: 12px;
      @media (max-width: 450px) {
        font-size: 10px;
        letter-spacing: 0px;
      }
    }

    &-contact {
      display: flex;
      flex-direction: column;
      text-align: left;
      line-height: 20px;
      justify-content: center;
      margin: 0 5px;
    }
    &-trademarks {
    }
  }
}
button#footer {
  border: 1px solid $brand-yellow;
  width: 150px;
  height: 30px;
  font-size: 12px;
  color: white;
  background-color: $brand-yellow;

  @media (max-width: 800px) {
  }

  @media (max-width: 600px) {
    width: 120px;
    font-size: 12px;
  }
  @media (max-width: 450px) {
    width: 90px;
    font-size: 12px;
  }
}

button#footer:hover {
  border: 1px solid $brand-yellow;
  background-color: $brand-yellow;
  width: 150px;
  height: 30px;
  font-size: 12px;
  color: white;

  @media (max-width: 800px) {
  }
}

#footer-links > a {
  text-decoration: none;
  color: $brand-yellow;
  font-weight: 300;
}
</style>
