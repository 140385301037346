<template lang="html">
  <div>
    <div class="eibTitles">
      <div v-for="key in eibKeys" class="">
        <div v-if="key == 'photo'"></div>
        <div class="" v-else>
          {{ key }}
        </div>
      </div>
    </div>
    <div id="eibs">
      <template v-for="(adaptor, index) in eibs">
        <div class="adaptorTable">
          <div
            :id="`accordion-${index}`"
            class="accordion"
            :class="{ active: accordionState[index].active }"
          >
            <div v-for="key in eibKeys">
              <div v-if="key == 'photo'" class="accordion__item">
                <img :src="adaptor[key]" width="120px" alt="" />
              </div>
              <div v-else class="accordion__item">
                <p style="font-size: 12px">{{ adaptor[key] }}</p>
              </div>
            </div>
          </div>
          <!-- <div :ref="`panel-${index}`" class="panel">
          <p style="padding: 0 50px">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div> -->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
let eibKeys = [
  "photo",
  "product ID",
  "probe interface",
  "connector",
  "channels",
  "length",
  "width"
];
let eibs = [
  {
    photo: require("../images/accessories/eibs/EIB-TET32.png"),
    "product ID": "EIB-TET32",
    "probe interface": "via array",
    connector: "Molex Slimstack SSB6",
    channels: "32",
    length: "10.0",
    width: "9.2"
  },
  {
    photo: require("../images/accessories/eibs/EIB-TET64.png"),
    "product ID": "EIB-TET64",
    "probe interface": "via array",
    connector: "2 x Omnetics NPD ",
    channels: "64",
    length: "21.3",
    width: "21.3"
  },
  {
    photo: require("../images/accessories/eibs/EIB-WIRE64.png"),
    "product ID": "EIB-WIRE64",
    "probe interface": "via array",
    connector: "2 x Molex Slimstack SSB6",
    channels: "64",
    length: "10.0",
    width: "10.3"
  },
  {
    photo: require("../images/accessories/eibs/EIB-XBASE.png"),
    "product ID": "EIB-XBASE",
    "probe interface": "single via",
    connector: "Panasonic P4S",
    channels: "1",
    length: "10.0",
    width: "6.5"
  },
  {
    photo: require("../images/accessories/eibs/EIB-LGA64.png"),
    "product ID": "EIB-LGA64",
    "probe interface": "flip chip array",
    connector: "2 x Molex Slimstack SSB6",
    channels: "64",
    length: "10.5",
    width: "6.3"
  }
];

let accordionState = [
  { index: 0, active: false },
  { index: 1, active: false },
  { index: 2, active: false },
  { index: 3, active: false },
  { index: 4, active: false },
  { index: 5, active: false },
  { index: 6, active: false },
  { index: 7, active: false },
  { index: 8, active: false },
  { index: 9, active: false }
];
export default {
  name: `accordion`,
  data() {
    return {
      eibKeys: eibKeys,
      eibs: eibs,
      accordionState: accordionState
    };
  },
  computed: {
    // isActive() {}
  },
  methods: {
    // toggleActive(index) {
    //   this.accordionState[index].active = !this.accordionState[index].active;
    //   let panel = this.$refs[`panel-${index}`][0];
    //
    //   if (panel.style.maxHeight) {
    //     panel.style.maxHeight = null;
    //   } else {
    //     panel.style.maxHeight = panel.scrollHeight + "px";
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  // padding: 100px 0;
  // margin: 0 200px;
  // height: 100vh;
}

#eibs {
  height: 400px;
  overflow: scroll;
}

.eibTitles {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  justify-items: center;
  // grid-template-rows: 1fr;
}

.accordion {
  // background-color: #eee;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  justify-items: center;
  color: #444;
  // cursor: pointer;
  padding: 0px 0px;
  text-align: left;
  border-top: solid 1px #ccc;
  border-right: solid 1px #ccc;
  border-left: solid 1px #ccc;
  outline: none;
  transition: 0.4s;

  &__item {
    // border: solid 1px #ccc;
    // height: 100%;
    // width: 100%;
  }
}

.adaptorTable:nth-child(even) {
  background-color: #f2f2f2;
}

.adaptorTable:last-child {
  border-bottom: solid 1px #ccc;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion:hover {
  background-color: #ddd;
}

/* Style the accordion panel. Note: hidden by default */

// .accordion:after {
//   content: "\002B";
//   color: #777;
//   font-weight: bold;
//   float: right;
//   margin-left: 5px;
// }
//
// .active:after {
//   content: "\2212";
// }

.panel {
  padding: 0px 0px;
  background-color: white;
  border-left: solid 1px #ccc;
  border-right: solid 1px #ccc;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.accordion-fade-slide {
  &-enter-active,
  &-leave-active {
    transition: all 0.3s;
  }
  &-enter {
    transform: translateY(-25px);
    opacity: 0;
  }
  &-leave-to {
    transform: translateY(25px);
    opacity: 0;
  }
}
</style>
