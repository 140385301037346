<template>
  <div class="hsConfigurator">
    <!-- <h3>HS-32</h3> -->
    <p></p>

    <h3>Channels</h3>
    <div class="btn-group">
      <button
        v-for="(channelCount, index) in channelCount"
        :key="channelCount.count"
        @click="selectChannelCount(index)"
        :class="{ active: true }"
      >
        <span> {{ channelCount.count }}</span>
      </button>
    </div>

    <h3>Weight: {{ animatedNumber }} g</h3>
    <h3>Footprint: 8 x 5 mm</h3>

    <h3>Factory Options</h3>
    <div class="button-grid">
      <button
        v-for="options in factoryOptions"
        @click="toggleActive(options.name)"
        @mouseover="showDescription(options.name)"
        @mouseleave="hideDescription('generic')"
        :class="{ active: isModuleActive(options.name) }"
      >
        <span> {{ options.name }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { TweenLite } from "gsap";
import { store, mutations } from "../../store";
let factoryOptions = [
  { name: "single optoStim" },
  { name: "dual optoStim" },
  { name: "imu" },
  { name: "tracking LEDs" },
  { name: "mag-tether" },
  { name: "hardwired" }
];
let optionsMap = {
  "single optoStim": 0,
  "dual optoStim": 1,
  imu: 2,
  "tracking LEDs": 3,
  "mag-tether": 4,
  hardwired: 5
};
let channelCount = [{ count: "32", active: true }];

let weights = {
  imu: 0.04,
  "mag-tether": 0.2095,
  hardwired: -0.05,
  "tracking LEDs": 0.0,
  "single optoStim": 0.05,
  "dual optoStim": 0.1
};

let baseWeight = 0.2;

let activeOptions = {
  "single optoStim": false,
  "dual optoStim": false,
  "tracking LEDs": false,
  imu: false,
  "mag-tether": false,
  hardwired: false
};

let imgString = ["none", "none", "none", "none", "none", "none"];

export default {
  name: `Hs32Modules`,
  data() {
    return {
      factoryOptions: factoryOptions,
      activeOpto: "",
      activeTether: "",
      activeOptions: activeOptions,
      imgString: imgString,
      channelCount: channelCount,
      weight: baseWeight,
      tweenedNumber: baseWeight
    };
  },
  computed: {
    animatedNumber() {
      return this.tweenedNumber.toFixed(2);
    }
  },
  watch: {
    activeOptions: {
      handler(val) {
        let entries = Object.entries(this.activeOptions);
        let cumWeight = baseWeight;

        for (let [key, value] of entries) {
          let index = optionsMap[key];
          if (value) {
            cumWeight += weights[key];
            this.imgString[index] = key;
          } else {
            this.imgString[index] = "none";
          }
        }
        this.$emit("moduleSelected", [
          this.activeOptions,
          this.imgString.join("-")
        ]);
        this.weight = Math.round(cumWeight * 100) / 100;

        mutations.setWeight(this.weight);
      },
      deep: true
    },
    weight(newValue) {
      TweenLite.to(this.$data, 0.3, { tweenedNumber: newValue }).delay(0.3);
    }
  },
  methods: {
    selectChannelCount(index) {
      this.channelCount.forEach(function(channelCount) {
        channelCount.active = false;
      });
      this.channelCount[index].active = !this.channelCount[index].active;
      this.channels = index;
    },
    isModuleActive(value) {
      if (value == "mag-tether" || value == "hardwired") {
        return this.isTetherActive(value);
      } else if (value == "single optoStim" || value == "dual optoStim") {
        return this.isOptoActive(value);
      } else {
        return this.activeOptions[value];
      }
    },
    isOptoActive(value) {
      if (this.activeOpto === value) {
        this.activeOptions[value] = true;
        return true;
      } else {
        this.activeOptions[value] = false;
        return;
      }
    },
    isTetherActive(value) {
      if (this.activeTether === value) {
        this.activeOptions[value] = true;
        return true;
      } else {
        this.activeOptions[value] = false;
        return;
      }
    },
    toggleActive(id) {
      // this.$emit("modClick", id);
      if (id == "single optoStim" || id == "dual optoStim") {
        if (this.activeOpto == id) {
          this.activeOpto = "";
        } else {
          this.activeOpto = id;
        }
      }
      if (id == "mag-tether" || id == "hardwired") {
        if (this.activeTether == id) {
          this.activeTether = "";
        } else {
          this.activeTether = id;
        }
      }
      this.activeOptions[id] = !this.activeOptions[id];
    },
    showDescription(id) {
      this.$emit("modClick", id);
    },
    hideDescription(id) {
      this.$emit("modClick", "generic");
    }
  }
};
</script>

<style lang="scss" scoped></style>
