<template lang="html">
  <div>
    <section class="index-hero">
      <div class="title-card">
        <div class="title-card__title">
          Scalable Electrophysiology
        </div>
        <div class="title-card__subtitle">
          Next-gen tools for experimental neuroscience.
        </div>
        <div class="products-images">
          <div v-for="product in productPageData">
            <router-link :to="product.urlString">
              <img :src="product.imgSrc" :alt="product.name" />
            </router-link>
          </div>
        </div>
        <div class="title-card__learn-more-button">
          <router-link to="/products">
            <button id="heroButton" type="button" name="button">
              Products
            </button>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
let productPageData = [
  {
    name: "eCube Server",
    imgSrc: require("../images/eCube Server/front side angle.png"),
    urlString: "/products/eCubeServer"
  },
  {
    name: "eCube Headstages",
    imgSrc: require("../images/HS640/HS640 side angle with probes.png"),
    urlString: "/products/eCubeHeadstages"
  },
  {
    name: "e3 Vision",
    imgSrc: require("../images/e3Vision/e3Vision Front.png"),
    urlString: "/products/e3Vision"
  },
  {
    name: "nanoZ",
    imgSrc: require("../images/NanoZ/nanoZ top.png"),
    urlString: "/products/nanoZ"
  },
];

export default {
  name: `Index`,
  data() {
    return {
      productPageData: productPageData
    };
  }
};
</script>

<style lang="scss" scoped>
$brand-yellow: #f4ab01;

a {
  text-decoration: none;
  color: white;
}

a:visited {
  color: white;
}

.products-images {
  padding: 25px;
  display: flex;
  align-items: center;
}

.products-images div {
  width: 25%;
  text-align: center;
}

.products-images div img {
  max-height: 12em;

  @media (max-width: 1000px) {
    max-height: 10em;
  }
  @media (max-width: 900px) {
    max-height: 8em;
  }
  @media (max-width: 800px) {
    max-height: 7em;
  }
  @media (max-width: 650px) {
    max-height: 4.5em;
  }
  @media (max-width: 350px) {
    max-height: 3em;
  }
}

.title-card {
  padding: 40px;
  // position: relative;
  background-color: hsl(0, 0, 100);
  margin: 0 auto;
  height: 390px;
  width: 80%;
  z-index: 4;
  // left: 320px;
  box-shadow: 0 2px 6px hsla(0, 0%, 0%, 0.2), 0 2px 6px hsla(0, 0%, 0%, 0.2);
  @media (max-width: 1200px) {
    width: 80%;
  }
  @media (max-width: 900px) {
    width: 90%;
    padding: 10px;
  }
  @media (max-width: 350px) {
    width: 80%;
    padding: 0px;
  }

  &__title {
    color: hsl(0, 0, 30);
    font-weight: 400;
    font-size: 50px;
    letter-spacing: 1px;
    line-height: 60px;
    text-align: center;

    @media (max-width: 900px) {
      font-size: 40px;
      padding: 25px 20px 0px 20px;
    }
    @media (max-width: 650px) {
      font-size: 30px;
      line-height: 40px;
      padding: 35px 20px 0px 20px;
    }
    @media (max-width: 500px) {
      font-size: 30px;
      line-height: 40px;
      padding: 10px 20px 0px 10px;
    }
    @media (max-width: 350px) {
      font-size: 25px;
      line-height: 28px;
      padding: 35px 20px 0px 20px;
    }
  }

  &__subtitle {
    color: hsl(0, 0%, 55%);
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    padding: 0 20px 10px 30px;
    text-align: center;
    margin-top: 10px;

    @media (max-width: 650px) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  &__learn-more-button {
    text-align: center;
    button#heroButton {
      background-color: #eeeeee;
      //color: white;
      width: 90%;
      height: 40px;
      font-size: 20px;
      font-weight: 300;
      letter-spacing: 1px;
    }
  }
}
</style>
