<template>
  <div class="App" id="app">
    <link
      href="https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600|Roboto:300,400,600&display=swap"
      rel="stylesheet"
    />

    <div
      class="App__nav"
      v-if="
        this.$route.name !== 'SFNIndex' &&
          this.$route.name !== 'configurator' &&
          this.$route.name !== 'survey' &&
          this.$route.name !== 'printForm'
      "
    >
      <mq-layout mq="xs+">
        <MobileNavBar />
      </mq-layout>
      <mq-layout mq="lg+">
        <NavBar />
      </mq-layout>
    </div>

    <main class="App__main">
      <transitionPage>
        <router-view />
      </transitionPage>
    </main>

    <div
      class=""
      v-if="
        this.$route.name !== 'SFNIndex' &&
          this.$route.name !== 'configurator' &&
          this.$route.name !== 'survey' &&
          this.$route.name !== 'printForm'
      "
    >
      <footer class="App__footer">
        <Footer />
      </footer>
    </div>
  </div>
</template>

<script>
import NavBar from "./components/navigation/NavBar.vue";
import MobileNavBar from "./components/navigation/MobileNavBar.vue";
import Footer from "./components/Footer.vue";
import TransitionPage from "./transitions/TransitionPage.vue";
import QuoteRequest from "./components/QuoteRequest.vue";

export default {
  name: `App`,
  components: {
    NavBar,
    MobileNavBar,
    Footer,
    TransitionPage,
    QuoteRequest
  }
};
</script>

<style lang="scss">
@import "./scss/variables";

$brand-yellow: #f4ab01;
$brand-lightGray: #bdc3c7;
$brand-darkGray: #374140;
$brand-black: #2a2c2b;

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

button {
  background-color: white;
}

* {
  margin: 0;
  padding: 0;
}

main {
  max-width: 1250px;
  margin: 0 auto;
}

html {
  line-height: 1.6;
  color: #333;
  font-family: "Helvetica Neue", "Roboto", sans-serif;
}

hr {
  border: 0.5px solid hsl(0, 0%, 80%);
}

body {
  font-variant-ligatures: no-common-ligatures;
}

h1 {
  font-family: "Montserrat";
  color: map-get($colors, brand-yellow);
  font-size: 50px;
  font-weight: 400;

  @media (max-width: 1000px) {
    line-height: 50px;
    font-size: 40px;
  }
  @media (max-width: 800px) {
    font-size: 30px;
  }
}

h2 {
  font-size: 30px;
  line-height: 40px;
  @media (max-width: 1000px) {
    font-size: 25px;
  }
  @media (max-width: 350px) {
    font-size: 18px;
  }
}

h3 {
  @media (max-width: 1000px) {
    font-size: 16px;
  }
  @media (max-width: 800px) {
    font-size: 15px;
  }
}

.hsConfigurator > h3 {
  @media (max-width: 600px) {
    font-size: 10px;
  }
}

h4 {
  font-size: 18px;
}

div {
}

p {
  font-family: "Helvetica Neue", "Roboto", sans-serif;
  color: #444;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-size: 18px;

  @media (max-width: 1200px) {
    font-size: 16px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
  }
  @media (max-width: 450px) {
    font-size: 12px;
    font-weight: 400;
  }
}

ul {
  list-style-image: url("./icons/triangle.png");
}

li {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: hsl(0, 0%, 40%);

  @media (max-width: 600px) {
    font-size: 10px;
  }
}

.row {
  width: 100%;
  display: grid;
  position: relative;
  align-items: center;
}

.title {
  color: #f4ab01;
  font-size: 22px;
  font-weight: 300;
  // letter-spacing: 15px;
  padding-bottom: 15px;

  @media (max-width: 600px) {
    font-size: 16px;
  }

  @media (max-width: 400px) {
    font-size: 12px;
  }
}

.center {
  justify-items: center;
}

.App {
  &__nav {
  }

  &__main {
    padding-top: 100px;
    min-height: 500px;
  }
  &__footer {
  }
}

button {
  width: 90px;
  height: 40px;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 400;
  border: 0;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  cursor: pointer;
  margin: 5px;

  @media (max-width: 850px) {
    width: 65px;
    height: 30px;
    font-size: 10px;
  }
  @media (max-width: 550px) {
    width: 65px;
    height: 30px;
    font-size: 10px;
    margin: 3px;
  }
}

button:focus {
  outline: 0;
}

.active {
  background-color: hsl(0, 0%, 50%);
  color: hsl(0, 0%, 95%);
}
</style>
