<template lang="html">
  <div class="techSpecs">
    <table id="customers">
      <tr>
        <td>Channels</td>
        <td>64</td>
      </tr>
      <tr>
        <td>Measurement Range</td>
        <td>10kΩ – 100MΩ</td>
      </tr>
      <tr>
        <td>Z Test Accuracy</td>
        <td>± 1% (10kΩ – 15MΩ)</td>
      </tr>
      <tr>
        <td>Z Test Frequency</td>
        <td>1Hz – 5Hz</td>
      </tr>
      <tr>
        <td>Z Test Current</td>
        <td>&lt; 1.4 nA RMS (50pA max bias)</td>
      </tr>
      <tr>
        <td>Z Test Signals</td>
        <td>Sinusoid, m-sequence for impedance spectra</td>
      </tr>
      <tr>
        <td>Electroplate Modes</td>
        <td>
          Galvanostatic or Potentiostatic, DC and AC
        </td>
      </tr>
      <tr>
        <td>Electroplate Range</td>
        <td>±12uA, ±5V</td>
      </tr>
      <tr>
        <td>Electroplate Resolution</td>
        <td>5nA, 10mV</td>
      </tr>
      <tr>
        <td>PC Interface</td>
        <td>USB 2.0 mini-B</td>
      </tr>
      <tr>
        <td>Dimensions</td>
        <td>3.2 × 2.8 × 0.47 inches (81 × 70 × 12 mm), anodized aluminum</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: `tabTechnicalSpecs`
};
</script>

<style lang="scss" scoped>
.techSpecs {
  height: inherit;
  background-color: white;
  overflow-y: scroll;
}
#customers {
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
  font-weight: 300;
  font-size: 14px;

  @media (max-width: 600px) {
    font-size: 10px;
  }
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}
</style>
