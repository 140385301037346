<template>
  <div class="overlay">
    <div class="overlay__inner">
      <h1>Your response was received!</h1>

      <p>
        We'll be in touch within 48 hours!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: `Overlay`,
  data() {
    return {
      productName: "",
      numChannels: 0
    };
  },
  created() {
    // this.productName = this.$route.query.productName;
    // if (this.$route.query.numChannels) {
    //   this.numChannels = this.$route.query.numChannels;
    // }
  }
};
</script>

<style lang="scss" scoped>
.overlay {
  position: relative;
  max-height: 1200px;
  height: 400px;
  width: 100vw;
  padding-top: 100px;
  margin: auto;
  // overflow: scroll;
  background: hsl(0, 0%, 100%);
  z-index: 0;

  &__inner {
    // position: relative;
    max-width: 42em;
    margin-right: auto;
    margin-left: auto;
    padding: 5em;
    // border: 1px solid black;
  }

  &__close {
    position: absolute;
    top: 1em;
    right: 1em;
    text-decoration: none;
    font-size: 1.25em;
    // color: #333;
    transition: color 0.2s;

    &:hover,
    &:focus {
      color: #999;
    }
  }
}
</style>
