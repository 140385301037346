<template lang="html">
  <div class="productContainer">
    <section class="hero">
      <div class="hero__title">Products</div>
      <p class="">
        White Matter manufactures a comprehensive suite of scalable tools built
        for working neuroscientists. Our eCube series allows for unprecedented
        experimental design, giving you the freedom to run multiple experiments
        from one rig and across different species.
      </p>
    </section>
    <template v-for="product in productPageData">
      <section class="products">
        <div class="products__image">
          <img :src="product.imgSrc" alt="" />
        </div>
        <div class="products__text">
          <h2 class="products__text-title">{{ product.name }}</h2>
          <p class="products__text-description">{{ product.description }}</p>

          <router-link :to="product.urlString">
            <button
              id="learn-more"
              type="button"
              name="button"
              class="products__button"
            >
              Learn more
            </button>
          </router-link>
        </div>
      </section>

      <hr v-if="product.name !== 'Accessories'" />
    </template>

    <br /><br />
  </div>
</template>

<script>
let productPageData = [
  {
    name: "eCube Server",
    description: `Highly integrated system managing up to 10 separate experiments, with
      multiple researchers independently controlling start and stop times. Designed to be paired with the smallest, lightest, and most modular
      headstages in the world.`,
    imgSrc: require("../images/eCube Server/front side angle.png"),
    urlString: "/products/eCubeServer"
  },
  {
    name: "eCube Headstages",
    description: `The smallest, lightest, and most modular headstages in the world, with tethered and wireless configurations ranging from 32 - 640 channels per headstage.`,
    imgSrc: require("../images/headstages.png"),
    urlString: "/products/eCubeHeadstages"
  },
  {
    name: "e3 Vision",
    description: `High definition networked video cameras with millisecond-precise
    synchronization. Record simultaneously from any number of cameras, for
    multi-view 3D tracking of behavior.`,
    imgSrc: require("../images/e3Vision/e3Vision Front Angle.png"),
    urlString: "/products/e3Vision"
  },
  {
    name: "nanoZ",
    description: `Test the impedance and quality of any electrode, including high
    impedance single unit electrodes, tetrodes and silicon electrode
    arrays with up to 64 channels. It's fast and accurate: testing 64
    channels takes just 30 seconds!`,
    imgSrc: require("../images/NanoZ/nanoZ top.png"),
    urlString: "/products/nanoZ"
  },
  {
    name: "Accessories",
    description: `Tethers, headstage testers, electrode interface boards, BNC breakout
    boards, etc.`,
    imgSrc: require("../images/HS Tester/HS-tester-top.png"),
    urlString: "/products/accessories"
  }
];

export default {
  name: `ProductsV2`,
  data() {
    return {
      productPageData: productPageData
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/variables";

.productContainer {
  overflow: hidden;
}

.hero {
  // padding-top: 150px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  padding: 50px 200px 50px 200px;
  background-color: #f2f2f2;

  @media (max-width: 1200px) {
    padding: 120px 100px 50px 100px;
  }
  @media (max-width: 900px) {
    padding: 100px 40px 50px 40px;
  }

  &__title {
    font-size: 50px;
    font-family: "Montserrat", Arial, sans-serif;
    color: map-get($colors, brand-yellow);
  }

  &__description {
    color: map-get($colors, subtitle-text);
  }
}

.products {
  padding-top: 50px;
  display: flex;
  margin: 0 200px;

  @media (max-width: 1200px) {
    margin: 0 100px;
  }
  @media (max-width: 800px) {
    display: block;
  }
  @media (max-width: 600px) {
    margin: 0 40px;
  }

  &__image {
    flex: 1;
    width: 100%;
  }

  &__image img {
    height: auto;
    width: 100%;

    @media (max-width: 800px) {
      height: auto;
      width: 70%;
    }
  }

  &__text {
    height: auto;
    flex: 1;
    width: 100%;
    padding-top: 100px;
    margin-left: 20px;

    @media (max-width: 1200px) {
      padding-top: 75px;
      margin-left: 20px;
    }
    @media (max-width: 1000px) {
      padding-top: 25px;
      margin-left: 20px;
    }
    @media (max-width: 800px) {
      padding-top: 0;
      margin-left: 0;
    }
  }

  &__text-description {
  }
}

button#learn-more {
  background-color: map-get($colors, brand-yellow);
  color: white;
}

hr {
  margin: 20px auto 0 auto;
  width: 80%;
}
</style>
