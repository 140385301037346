<template lang="html">
  <div class="wraps">
    <div class="navPlain">
      <img src="../images/Logos/Main Logo.svg" width="600px" alt="" />
    </div>

    <div class="mainTitle">
      Welcome to SFN 2019!
    </div>

    <div class="foo gray">
      <div class="">
        <router-link to="/survey">
          <button id="surveyButton" type="button" name="button">
            Take Survey
          </button>
        </router-link>
      </div>
      <div class="">
        <img src="../icons/surveyArrow.svg" width="150px" alt="" />
      </div>
      <div class="bar">
        <div class="winSplosion">
          <img src="../icons/winSplosion.svg" width="500px" alt="" />
        </div>
        <div class="win">
          Win an HS Tester!
        </div>
      </div>
    </div>

    <div class="BYI gray">
      <router-link to="/configurator">
        <button id="HsButton" type="button" name="button">
          Build Your Own Headstage
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: `Index`,
  data() {
    return {
      lastScrollPosition: 0,
      scrollValue: 0,
      foo: 0
    };
  },
  computed: {
    theta() {
      return `transform: rotate(${this.lastScrollPosition / 100}rad)`;
    }
  },
  methods: {
    onScroll() {
      this.lastScrollPosition = window.pageYOffset;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  }
};
</script>

<style lang="scss" scoped>
$brand-yellow: #f4ab01;

.navPlain {
  position: absolute;
  display: grid;
  align-items: center;
  justify-items: center;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 150px;
  box-shadow: 0 2px 6px hsla(0, 0%, 0%, 0.2), 0 2px 6px hsla(0, 0%, 0%, 0.2);
}

.wraps {
  // overflow-x: hidden;
  // overflow-y: hidden;
  // background: white;
  // height: 2400px;
  // width: 1536px;
}

.foo {
  display: grid;
  grid-template-columns: 1fr 125px 1fr;
  align-items: center;
  justify-items: center;

  margin: 50px 50px;
  width: 93%;
  height: 720px;
}
.BYI {
  display: grid;
  align-items: center;
  justify-items: center;
  // padding-top: 100px;
  margin: 50px 50px;
  width: 93%;
  height: 720px;
}

button#surveyButton {
  width: 500px;
  height: 250px;
  font-size: 70px;

  background-color: $brand-yellow;
}

button#HsButton {
  width: 1000px;
  height: 250px;
  font-size: 70px;
  color: hsl(0, 0%, 20%);
  background-color: $brand-yellow;
}

.mainTitle {
  display: grid;
  align-items: center;
  justify-items: center;
  padding-top: 180px;
  font-size: 90px;
  font-weight: 800;
  // font-family: "Montserrat", Arial, sans-serif;
}

.block {
  height: 600px;
  width: 100%;
  // box-shadow: 0 2px 6px hsla(0, 0%, 0%, 0.2), 0 2px 6px hsla(0, 0%, 0%, 0.2);
  margin: 30px;
  display: flex;
  flex-direction: row;
}

.bar img {
  animation: pulsy 2s ease-in-out infinite;
}

.win {
  position: relative;
  left: 120px;
  top: -200px;
  z-index: 10000;
  font-size: 50px;
  width: 250px;
  text-align: center;
}

.winSplosion {
  position: relative;
  top: 50px;
}

button {
  color: hsl(0, 0%, 20%);
}

.gray {
  background-color: hsl(0, 0%, 90%);
}

.yellow {
  background-color: $brand-yellow;
}

.darkGray {
  background-color: hsl(0, 0%, 90%);
}

@keyframes pulsy {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
</style>
