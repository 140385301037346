<template lang="html">
  <div class="contents">
    <div>
      <ul>
        <li v-for="item in inTheBox">
          <p>{{ item }}</p>
        </li>
      </ul>
    </div>
    <div class="">
      <img src="../../../images/e3Vision/e3 unboxed.jpg" width="300px" alt="" />
    </div>
  </div>
</template>

<script>
let inTheBox = [
  "e3Vision camera",
  "e3Vision hub with proprietary sync + power + data",
  "High flexibility ethernet cables",
  "GoPro mounts",
  "Lifetime supported free recording software + HTTP API + Python SDK"
];

export default {
  name: `tabPackageContents`,
  data() {
    return {
      inTheBox: inTheBox
    };
  }
};
</script>

<style lang="scss" scoped>
.contents {
  height: 400px;
  // color: hsl(0, 0%, 60%);
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 0 20px;
  font-weight: 300;
  background-color: white;
  @media (max-width: 800px) {
    grid-template-columns: 2fr 1fr;
  }
  @media (max-width: 600px) {
    font-size: 10px;
  }
}

.contents ul {
  padding: 0 10px;
}

.contents img {
  @media (max-width: 800px) {
    height: auto;
    width: 100%;
  }
}
</style>
