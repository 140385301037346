var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"App",attrs:{"id":"app"}},[_c('link',{attrs:{"href":"https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600|Roboto:300,400,600&display=swap","rel":"stylesheet"}}),(
      this.$route.name !== 'SFNIndex' &&
        this.$route.name !== 'configurator' &&
        this.$route.name !== 'survey' &&
        this.$route.name !== 'printForm'
    )?_c('div',{staticClass:"App__nav"},[_c('mq-layout',{attrs:{"mq":"xs+"}},[_c('MobileNavBar')],1),_c('mq-layout',{attrs:{"mq":"lg+"}},[_c('NavBar')],1)],1):_vm._e(),_c('main',{staticClass:"App__main"},[_c('transitionPage',[_c('router-view')],1)],1),(
      this.$route.name !== 'SFNIndex' &&
        this.$route.name !== 'configurator' &&
        this.$route.name !== 'survey' &&
        this.$route.name !== 'printForm'
    )?_c('div',{},[_c('footer',{staticClass:"App__footer"},[_c('Footer')],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }