<template lang="html">
  <div class="container">
    <section class="products">
      <div class="products__image">
        <img
          src="../images/HS640/HS640 side angle with probes.png"
          height="550px"
        />
      </div>
      <div class="products__text">
        <h1 class="products__text-title">eCube Headstages</h1>

        <p class="products__text-description">
          The smallest, lightest, and most modular headstages in the world.
        </p>
      </div>
    </section>
    <br /><br />
    <section class="wrapper2">
      <div class="row  center">
        <h2>Compare Headstages</h2>
      </div>
      <div class="row center">
        <div class="hs">
          <template v-for="product in productCardData">
            <div class="hs-item">
              <div class="hs-item__card-image">
                <img :src="product.imgSrc" width="250px" alt="" />
              </div>
              <div class="">
                <div class="hs-item__card-title">
                  {{ product.name }}
                </div>
                <div class="hs-item__card-subtitle">
                  {{ product.description }}
                </div>
                <div class="hs-item__card-list">
                  <ul>
                    <li v-for="item in product.features">{{ item }}</li>
                  </ul>
                </div>
                <div
                  class="hs-item__card-link"
                  @click="function() {
                    setOverrideProduct(product.name);
                    scrollToConfigurator('configurator');
                  }"
                >
                  Learn more &#8250;
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>

    <section class="config">
      <div class="row center" style="padding-top: 50px; padding-bottom: 40px">
        <h2 id="configurator">Design your own headstage</h2>
      </div>
      <mq-layout :mq="['xs', 'sm', 'md']">
        <!-- Mobile -->
        <MobileConfigurator
          :overrideProduct="overrideProduct"
        />
        <br /><br />
      </mq-layout>
      <mq-layout mq="lg+">
        <!-- Desktop -->
        <Configurator
          :overrideProduct="overrideProduct"
        />
        <br /><br />
      </mq-layout>
    </section>
  </div>
</template>

<script>
let productCardData = [
  {
    imgSrc: require("../images/HSProductShots/newHS32.png"),
    name: "HS-32",
    description: "Lightest on the market",
    features: ["~ 0.1g", "32 channels (fixed)"]
  },
  {
    imgSrc: require("../images/HSProductShots/newHS64.png"),
    name: "HS-64",
    description: "Customizable fixed count",
    features: ["~ 0.25g", "64 channels (fixed)"]
  },
  {
    imgSrc: require("../images/HSProductShots/newHS640.png"),
    name: "HS-640",
    description: "Scalable & Modular",
    features: ["~ 0.33g (per 64 channels)", "Up to 640 channels"]
  },
  {
    imgSrc: require("../images/HSProductShots/newHSW.png"),
    name: "HS-W",
    description: "Next-gen Wireless",
    features: ["~ 2.7g", "Up to 320 channels"]
  }
];

import Configurator from "../components/configurator/configurator.vue";
import MobileConfigurator from "../components/configurator/MobileConfigurator.vue";

export default {
  name: `Headstages`,
  data() {
    return {
      productCardData: productCardData,
      overrideProduct: ""
    };
  },
  components: {
    Configurator,
    MobileConfigurator
  },
  methods: {
    scrollToConfigurator(name) {
      document
        .getElementById(name)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    },
    setOverrideProduct(name) {
      this.overrideProduct = name;
    }
  }
};
</script>

<style lang="scss" scoped>
$brand-yellow: #f4ab01;
.container {
  // padding-top: 80px;
  // padding-bottom: 80px;
}

.products {
  padding-top: 50px;
  display: flex;
  margin: 0 200px;

  @media (max-width: 1200px) {
    margin: 0 100px;
  }
  @media (max-width: 800px) {
    display: block;
  }
  @media (max-width: 600px) {
    margin: 0 40px;
  }

  &__image {
    flex: 1;
    width: 100%;
  }

  &__image img {
    height: auto;
    width: 100%;

    @media (max-width: 800px) {
      height: auto;
      width: 70%;
    }
  }

  &__text {
    height: auto;
    flex: 1;
    width: 100%;
    padding-top: 50px;
    margin-left: 20px;

    @media (max-width: 1200px) {
      padding-top: 30px;
      margin-left: 20px;
    }
    @media (max-width: 800px) {
      padding-top: 0;
      margin-left: 0;
    }
  }

  &__text-title {
    max-width: 300px;
    line-height: 55px;
  }

  &__text-description {
    @media (max-width: 1200px) {
      font-size: 16px;
    }
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
}

.wrapper2 {
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 100px;
  background-color: hsl(0, 0%, 97%);
}

.hs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.hs-item {
  display: grid;
  grid-template-rows: 180px 200px;
  justify-items: center;
  align-items: center;
  margin: 20px 10px;
  background-color: white;
  width: 280px;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.1);

  @media (max-width: 900px) {
    grid-template-rows: 150px 230px;
  }
  // @media (max-width: 600px) {
  //   grid-template-rows: 220px 230px;
  // }

  &__card-image {
    padding-top: 60px;
  }

  &__card-title {
    font-size: 30px;
    font-weight: 400;
    color: hsl(0, 0%, 20%);
    padding-top: 5px;
    line-height: 30px;
    font-family: "Montserrat", Arial, sans-serif;
  }

  &__card-subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: hsl(0, 0%, 20%);
    font-family: "Montserrat", Arial, sans-serif;
  }

  &__card-list {
    padding-top: 5px;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 1px;
    color: hsl(0, 0%, 40%);

    @media (max-width: 600px) {
      line-height: 12px;
    }
    // font-family: "Montserrat", Arial, sans-serif;
  }
  &__card-link {
    padding-top: 10px;
    font-size: 18px;
    font-weight: 400;
    color: $brand-yellow;
    cursor: pointer;
  }
}

.hs-item ul {
  list-style: none;
}
</style>
