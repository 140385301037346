<template>
  <form action="index.html" method="post" @submit.prevent="submitForm">
    <label><p>Name*</p></label>
    <input
      placeholder="First"
      type="text"
      v-model="quoteData.name.first"
      required
    />
    <input
      placeholder="Last"
      type="text"
      v-model="quoteData.name.last"
      required
    />

    <label><p>Email Address*</p></label>
    <input type="email" v-model="quoteData.email" required />
    <br />
    <label><p>Institution*</p></label>
    <input type="text" v-model="quoteData.institution" required />
    <br />
    <label><p>Message</p></label>
    <textarea type="text" v-model="quoteData.message" />
    <br />
    <button id="submit" type="submit" name="button">SUBMIT</button>

    <label v-if="loading">Loading...</label>
    <label v-if="success"></label>
    <label v-if="failure" style="color: red"
      >Oops something went wrong. Please use your email client.</label
    >
  </form>
</template>

<script>
let emptyQuoteData = "";

import axios from "axios";
export default {
  name: `QuoteForm`,
  props: {
    productName: String,
    channelCount: Number,
    selectedModules: Array,
    contactEmail: String
  },
  data() {
    return {
      quoteData: {
        name: {
          first: "",
          last: ""
        },
        email: "",
        institution: "",
        message: "",
        sendTo: this.contactEmail
      },
      loading: false,
      success: false,
      failure: false
    };
  },
  methods: {
    async submitForm() {
      this.loading = true;
      let url =
        "https://pxt3drtt8b.execute-api.us-west-2.amazonaws.com/testing/get-quote";

      try {
        let response = await axios.post(url, JSON.stringify(this.quoteData));
        console.log(response);
        if (
          response.status == 200 &&
          response.data == null
          // response.data.hasOwnProperty("errorMessage") ||
          // response.status !== 200
        ) {
          console.log("function succeeded");
          console.log(response.data);
          setTimeout(() => {
            this.loading = false;
            this.$parent.$emit("close"); //apparently bad practice since can break if not grandchild
            this.$router.push({ path: "/quote/confirmation" });
            this.quoteData.name.first = "";
            this.quoteData.name.last = "";
            this.quoteData.email = "";
            this.quoteData.institution = "";
            this.quoteData.message = "";
          }, 1000);
        } else {
          console.log("function failed! please try again later");
          console.log(response.data.errorMessage);
          setTimeout(() => {
            this.loading = false;
            this.failure = true;
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#first,
#last {
  font-size: 10px;
  padding-right: 110px;
}

button#submit {
  width: 100px;
  background-color: #f4ab01;
  color: white;
  cursor: pointer;
}

input {
  box-shadow: inset 0 2px 4px 0 hsla(0, 0%, 0%, 0.06);
  width: 100%;
  height: 25px;
  padding: 5px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  height: 45px;
  width: 100%;
  box-shadow: inset 0 2px 4px 0 hsla(0, 0%, 0%, 0.06);
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
</style>
