<template lang="html">
  <div class="container">
    <div class="altImgTextLayout">
      <div class="altImgTextLayout__image">
        <img src="../images/NanoZ/nanoZ top.png" alt="" />
      </div>
      <div class="altImgTextLayout__text heroText">
        <h1>nanoZ</h1>
        <h2>Automatic impedance testing and electroplating.</h2>
        <p>
          Verify the integrity and improve the recording properties of your
          neural probes. Testing 64 channels takes just 30 seconds.
        </p>
      </div>
    </div>

    <div class="altImgTextLayout">
      <div class="altImgTextLayout__image">
        <img src="../gifs/nanoZgui4.gif" alt="" />
      </div>
      <div class="altImgTextLayout__text">
        <h2>Flexible and easy to use.</h2>

        <ul>
          <li id="1">
            <p>
              The nanoZ tests the impedance and quality of any electrode,
              including high impedance single unit electrodes, tetrodes and
              silicon electrode arrays with up to 64 channels.
            </p>
          </li>

          <li id="2">
            <p>
              Simply plug the nanoZ into the computer, install the software
              suite, and you’re ready to go.
            </p>
          </li>

          <li id="3">
            <p>
              Make your own custom testing or electroplating platform with the
              included Matlab SDK for Windows or Mac
            </p>
          </li>
        </ul>
      </div>
    </div>

    <div class="altImgTextLayout">
      <div class="altImgTextLayout__image">
        <img src="../images/NanoZ/nanoZ+probesIII.jpg" alt="" />
      </div>
      <div class="altImgTextLayout__text">
        <h2>Compatible with any electrode array.</h2>

        <p>
          The nanoZ uses Samtec connectors that are pin-compatible with all 32-
          and 64-channel acute silicon probes from
          <a href="https://www.cambridgeneurotech.com/" target="_blank"
            >Cambridge NeuroTech</a
          >, <a href="https://neuronexus.com/" target="_blank">NeuroNexus</a>,
          and
          <a href="http://www.atlasneuro.com/" target="_blank"
            >Atlas Neuroengineering</a
          >. A DIP16 adaptor is included for use with 16 channel probes, and we
          have a variety of
          <router-link
            :to="{
              path: '/products/accessories',
              hash: '#ElectrodeInterfaceBoards'
            }"
            >electrode adaptors</router-link
          >
          available for probes with Omnetics and Molex connectors.
        </p>
      </div>
    </div>

    <section class="tabbed-ish">
      <div class="">
        <div class="tabbed-info">
          <button
            id="product-tabs"
            v-for="tab in tabs"
            v-bind:key="tab"
            v-bind:class="['tab-button', { active: currentTab === tab }]"
            v-on:click="currentTab = tab"
          >
            {{ tab }}
          </button>
        </div>

        <transition name="component-fade" mode="out-in">
          <component v-bind:is="currentTabComponent" class="tab"></component>
        </transition>
      </div>
    </section>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import tabPackageContents from "../components/products/nanoZ/tab-PackageContents.vue";
import tabTechnicalSpecs from "../components/products/nanoZ/tab-TechnicalSpecs.vue";
import tabDownloads from "../components/products/nanoZ/tab-Downloads.vue";

export default {
  name: `nanoZ`,
  components: {
    tabPackageContents,
    tabTechnicalSpecs,
    tabDownloads
  },
  data() {
    return {
      tabs: ["Package Contents", "Technical Specs", "Downloads"],
      currentTab: "Package Contents"
    };
  },
  created() {
    // AOS.init({
    //   duration: 500,
    //   offset: 100,
    //   easing: "ease-in-out-cubic",
    //   once: true
    // });
  },
  computed: {
    currentTabComponent: function() {
      return "tab" + this.currentTab.split(" ").join("");
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 50px;
  display: grid;
  justify-items: center;
  overflow: hidden;

  // @media (max-width: 800px) {
  //   padding-top: 100px;
  // }
}

.altImgTextLayout {
  display: flex;
  margin: 0px 200px 100px 200px;

  &:nth-child(even) {
    flex-direction: row-reverse; // 3
  }

  @media (max-width: 1200px) {
    margin: 0 100px 80px 100px;
  }
  @media (max-width: 900px) {
    // margin: 0 50px 50px 50px;
  }
  @media (max-width: 800px) {
    display: block;
    padding-bottom: 30px;
  }
  @media (max-width: 600px) {
    margin: 0 40px;
  }

  &__text {
    width: 100%;
    flex: 1;
    margin: 0 10px;
    @media (max-width: 800px) {
      margin: 0;
    }
  }

  &__image {
    flex: 1;
    width: 100%;
    margin: 0 10px;

    @media (max-width: 800px) {
      margin: 0;
    }
  }

  &__image img {
    height: auto;
    width: 100%;

    @media (max-width: 800px) {
      height: auto;
      width: 60%;
    }
    @media (max-width: 600px) {
      height: auto;
      width: 80%;
    }
    @media (max-width: 450px) {
      height: auto;
      width: 90%;
    }
    @media (max-width: 350px) {
      height: auto;
      width: 80%;
    }
  }
}

.heroText {
  padding-top: 130px;
  @media (max-width: 1250px) {
    padding-top: 100px;
  }
  @media (max-width: 1000px) {
    padding-top: 50px;
  }
  @media (max-width: 800px) {
    padding-top: 0;
    // padding-top: 10px;
    // padding-bottom: 20px;
  }
}

.tabbed-ish {
  padding: 0px 150px 100px 150px;
  width: 80%;

  @media (max-width: 800px) {
    padding: 0 50px 50px 50px;
  }
  @media (max-width: 800px) {
    padding: 0 40px 50px 40px;
  }
}

.tabbed-info {
  border-bottom: 4px solid #f4ab01;
  padding-top: 30px;
}

button#product-tabs {
  width: 160px;
  box-shadow: none;
  border-radius: 0px;
  margin: 0;
  font-size: 18px;
  font-weight: 300;
  color: hsl(0, 0%, 30%);

  @media (max-width: 800px) {
    width: 120px;
    font-size: 14px;
  }
  @media (max-width: 600px) {
    width: 90px;
    font-size: 10px;
  }
  @media (max-width: 400px) {
    width: 80px;
    font-size: 8px;
  }
}

.tab-button {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
  margin-bottom: -1px;
  background-color: inherit;
}
.tab-button:hover {
  // background: none;
  // color: white;
}
button#product-tabs.active {
  background: #f4ab01;
  color: white;
}

.tab {
  padding: 30px 50px;
  height: 300px;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);

  @media (max-width: 600px) {
    padding: 10px 10px;
  }
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
</style>
