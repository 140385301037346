<template>
  <div class="hsConfiguratorIpad">
    <!-- <h3 id="iPad">HS-64</h3> -->
    <!-- <p>Foo</p> -->

    <h3 id="iPad">Channels</h3>
    <div class="btn-group-Ipad">
      <button
        v-for="(channelCount, index) in channelCount"
        :key="channelCount.count"
        @click="selectChannelCount(index)"
        :class="{ active: true }"
      >
        <span> {{ channelCount.count }} </span>
      </button>
    </div>

    <h3 id="iPad">Weight: {{ weight }} g</h3>
    <h3 id="iPad">Footprint: 7.5 x 8.0 mm</h3>

    <h3 id="iPad">Factory Options</h3>
    <div class="button-grid-Ipad">
      <button
        v-for="option in factoryOptions"
        @click="toggleActive(option.name)"
        :class="{ active: isModuleActive(option.name) }"
        id="moduleButtonsIpad"
      >
        <span> {{ option.name }}</span>
      </button>
    </div>
    <h3 id="iPad">Add-on Modules</h3>
    <div class="button-grid-Ipad">
      <button
        v-for="module in modules"
        @click="toggleActive(module.name)"
        :class="{ active: isModuleActive(module.name) }"
        id="moduleButtonsIpad"
      >
        <span> {{ module.name }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { store, mutations } from "../../store";
let factoryOptions = [
  { name: "tracking LEDs" },
  { name: "mag-tether" },
  { name: "hardwired" }
];

let modules = [
  { name: "optoStim" },
  { name: "eStim" },
  { name: "imu" },
  { name: "adc" },
  { name: "mic" },
  { name: "gpio" }
];

let activeOptions = {
  optoStim: false,
  eStim: false,
  imu: false,
  adc: false,
  mic: false,
  gpio: false,
  "tracking LEDs": false,
  "mag-tether": false,
  hardwired: false
};

let channelCount = [{ count: "64", active: true }];

let baseWeight = Math.round(0.24 * 100) / 100;

let weights = {
  imu: 0.028,
  mmx: 0.25,
  "mag-tether": 0.2095,
  hardwired: -0.05,
  "tracking LEDs": 0.0,
  optoStim: 0.035,
  eStim: 0.055,
  adc: 0.02,
  mic: 0.12,
  gpio: 0.015
};

export default {
  name: `Hs64Modules`,
  data() {
    return {
      factoryOptions: factoryOptions,
      modules: modules,
      moduleQueue: [],
      activeTether: "",
      activeOptions: activeOptions,
      channelCount: channelCount,
      weight: baseWeight
    };
  },
  computed: {
    computeImgString() {
      let tracking = this.activeOptions["tracking LEDs"]
        ? "tracking LEDs"
        : "none";
      let magnet = this.activeOptions["mag-tether"] ? "mag-tether" : "none";
      let hardwired = this.activeOptions["hardwired"] ? "hardwired" : "none";
      let optoStim = this.activeOptions["optoStim"] ? "optoStim" : "none";
      let eStim = this.activeOptions["eStim"] ? "eStim" : "none";
      let imu = this.activeOptions["imu"] ? "imu" : "none";
      let adc = this.activeOptions["adc"] ? "adc" : "none";
      let mic = "none";
      let gpio = this.activeOptions["gpio"] ? "gpio" : "none";
      return `${tracking}-${magnet}-${hardwired}-${optoStim}-${eStim}-${imu}-${adc}-${mic}-${gpio}`;
    }
  },
  watch: {
    activeOptions: {
      handler(val) {
        let entries = Object.entries(this.activeOptions);
        let cumWeight = baseWeight;

        for (let [key, value] of entries) {
          if (value) {
            cumWeight += weights[key];
          }
        }

        this.weight = Math.round(cumWeight * 100) / 100;

        mutations.setWeight(this.weight);

        let modulesSelected = this.computeImgString;
        this.$emit("moduleSelected", [this.activeOptions, modulesSelected]);
      },
      deep: true
    }
  },
  methods: {
    selectChannelCount(index) {
      this.channelCount.forEach(function(channelCount) {
        channelCount.active = false;
      });
      this.channelCount[index].active = !this.channelCount[index].active;
      this.channels = index;
    },
    isModuleActive(value) {
      if (value == "mag-tether" || value == "hardwired") {
        return this.isTetherActive(value);
      } else {
        return this.activeOptions[value];
      }
    },
    isTetherActive(value) {
      if (this.activeTether === value) {
        this.activeOptions[value] = true;
        return true;
      } else {
        this.activeOptions[value] = false;
        return;
      }
    },
    toggleActive(id) {
      if (id == "mag-tether" || id == "hardwired" || id == "tracking LEDs") {
      } else if (this.moduleQueue.includes(id)) {
        this.moduleQueue.splice(this.moduleQueue.indexOf(id), 1);
      } else if (!this.moduleQueue.includes(id)) {
        this.moduleQueue.unshift(id);
      }

      if (this.moduleQueue.length > 2) {
        this.activeOptions[this.moduleQueue.pop()] = false;
      }

      if (id == "mag-tether" || id == "hardwired") {
        if (this.activeTether == id) {
          this.activeTether = "";
        } else {
          this.activeTether = id;
        }
      }
      this.activeOptions[id] = !this.activeOptions[id];
      this.$emit("modClick", id);
    }
  }
};
</script>

<style lang="scss" scoped></style>
