<template>
  <div class="configurator">
    <ProductList
      :products="products"
      :overrideProduct="overrideProduct"
      :initialProduct="this.currentProduct"
      :filters="this.filters"
      :activeFilter="this.activeFilter"
      @product-clicked="setProduct"
    />

    <transition name="component-fade" mode="out-in">
      <keep-alive>
        <component
          :is="this.currentComponent"
          :key="this.currentProduct.id"
          :current-image="this.currentProduct"
        ></component>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
const products = [
  {
    id: 0,
    name: "HS-32",
    description: "The lightest headstage on the market! ",
    baseWeight: 0.175,
    tags: ["< 1 g"],
    src__thumbnail: require("../../images/HS-32-thumbnail.png"),
    src__fullImage: require("../../images/HS-32.png")
  },
  {
    id: 1,
    name: "HS-64",
    description: "Compatible with Omnetics probes",
    baseWeight: 1.3,
    tags: ["Omnetics Interface"],
    src__thumbnail: require("../../images/HS-64M-thumbnail.png"),
    src__fullImage: require("../../images/HS-64M.png")
  },
  {
    id: 2,
    name: "HS-640",
    description: "Designed to scale across experiments",
    baseWeight: 0.3,
    tags: ["Modular"],
    src__thumbnail: require("../../images/hs-640-thumbnail.png"),
    src__fullImage: require("../../images/hs-640.png"),
    src__fullImageMod: require("../../images/hs-640-extra-module.png")
  },
  {
    id: 3,
    name: "HS-W",
    description:
      "Fully customizable Wireless headstage. Pick the modules you want depending on the experiment",
    baseWeight: 3,
    tags: ["Modular", "Wireless"],
    src__thumbnail: require("../../images/hs-w-thumbnail.png"),
    src__fullImage: require("../../images/hs-w.png"),
    src__fullImageMod: require("../../images/hs-w-extra-module.png")
  }
];

import AOS from "aos";
import "aos/dist/aos.css";

import ProductCard from "../ProductCard.vue";
import ProductList from "../ProductList.vue";

export default {
  name: `Configurator`,
  props: {
    overrideProduct: String
  },
  data() {
    return {
      filters: [
        { name: "< 1 g", active: false },
        { name: "Omnetics Interface", active: false },
        { name: "Modular", active: false },
        { name: "Wireless", active: false }
      ],
      activeFilter: -1,
      products: products,
      currentProduct: products[0],
      currentComponent: ProductCard
    };
  },
  components: {
    ProductCard,
    ProductList
  },
  methods: {
    setProduct(product) {
      this.currentProduct = product;
    }
  }
};
</script>

<style lang="scss" scoped>
.configurator {
  display: grid;
  grid-template-columns: 1fr 6fr;
  // grid-template-rows: 80px 6fr;
  margin: 0px 80px;
  background-color: hsl(0, 0%, 100%);
  height: 580px;
  padding: 30px;
  border: 0;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);

  @media (max-width: 1050px) {
    margin: 0px 40px;
  }
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
</style>
