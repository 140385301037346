<template lang="html">
  <div class="Downloads">
    <h3>Documentation</h3>
    <ul>
      <li>
        <a href="https://docs.white-matter.com/docs/e3vision/">e3Vision Web Documentation</a
        >
      </li>
      <li>
        <a href="/e3vision_Quick_Start_Guide.pdf">Quick Start Guide</a
        > (PDF)
      </li>
    </ul>
    <div class="two-col">
      <div>
        <h3>Software</h3>
        <ul>
          <li>
            <a
              href="https://storage.googleapis.com/white-matter/e3vision/v2.0.2/v2.0.0-win64.zip"
            >
              Windows 7/8/10 (64-bit)
            </a>
          </li>
          <li>
            <a
              href="https://storage.googleapis.com/white-matter/e3vision/v2.0.2/v2.0.0-linux-x86-64.zip"
            >
              Linux (x86-64)</a
            >
          </li>
        </ul>
      </div>
      <div>
        <h3>Firmware</h3>
        <ul>
          <li>
            <a
              href="https://storage.googleapis.com/white-matter/e3vision/v1.0.1/e3v-firmware-signed.fw"
            >
              Firmware Download (model A)</a
            >
          </li>
          <li>
            <a
              href="https://storage.googleapis.com/white-matter/e3vision/v2.0.2/e3v-firmware-v2.0.2.fw"
            >
              Firmware Download (model B)</a
            >
          </li>
          <li>
            <a href="https://docs.white-matter.com/docs/e3vision/quickstart/updating/"
              >Firmware Update How-To</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: `tabDownloads`
};
</script>

<style lang="scss" scoped>
.Downloads {
  height: 600px;
  background-color: white;
}

.two-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
