<template lang="html">
  <div class="Downloads">
    <h3>Documentation</h3>
    <ul>
      <li>
        <a href="https://docs.white-matter.com/docs/ecube/">eCube Web Documentation</a
        >
      </li>
    </ul>
    <div class="two-col">
      <div>
        <h3>Software</h3>
        <ul>
          <li>
            <a href="https://storage.googleapis.com/white-matter/ecube/v0.3.1/ServerNode-v0.3.1-win64.zip">ServerNode (Windows 64-bit)</a>
          </li>
          <li>
            <a href="https://storage.googleapis.com/white-matter/ecube/v0.3.0/servernode-v0.3.0-linux-x86-64.tar.gz">ServerNode (Linux 64-bit)</a>
          </li>
          <li>
            <a href="https://storage.googleapis.com/white-matter/ecube/v0.3.1/OpenEphys-v0.4.4-SN-v0.3.1-HSW-v0.4.0.zip">OpenEphys (Windows 64-bit)</a>
          </li>
        </ul>
      </div>
      <div>
        <h3>Utilities</h3>
        <ul>
          <li>
            <a href="https://storage.googleapis.com/white-matter/ecube/firmware/eCube%20Firmware%2020210726.zip">eCube Firmware update</a>
          </li>
          <li>
            <a href="https://aka.ms/vs/16/release/vc_redist.x64.exe">Microsoft Visual C++ Redistributable, if not installed</a>
          </li>
          <li>
            <a href="https://storage.googleapis.com/white-matter/ecube/v0.1.0/pyECubeSig-20200221.zip">pyECubeSig modules</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: `tabDownloads`
};
</script>

<style lang="css" scoped>
.Downloads {
  height: 450px;
  background-color: white;

}
</style>
