<template lang="html">
  <div
    class="navbar"
    :class="{
      'hidden-navbar': !showNavbar,
      'base-navbar': isTopOfPage,
      'no-bg': darkPage,
      'white-bg': (darkPage && !isTopOfPage) || !darkPage
    }"
  >
    <div class="header-logo" id="logo">
      <router-link to="/">
        <img src="../../images/Logos/logo no text.svg" height="130%" />
        <span class="textgo"> White Matter</span>
      </router-link>
    </div>

    <!-- <div> -->
    <ul class="nav-links">
      <li class="nav-item">
        <div class="dropdown">
          <div
            @mouseover="isOpen = true"
            @mouseleave="isOpen = false"
            class="dropbtn"
          >
            <router-link to="/products" :class="{}">
              <transition name="fade" mode="out-in">
                <span v-if="!isSearch" style="margin-right: 5px;">
                  <span
                    class="underlined"
                    :class="{
                      underline: this.$route.path.includes('products')
                    }"
                  >
                    Products
                  </span>
                  <div class="down-arrow" :class="{}"></div>
                  <!-- <img src="../../icons/down caret.svg" width="8%" alt="" /> -->
                </span>
              </transition>
            </router-link>
          </div>

          <div
            :class="{ show: isOpen }"
            class="dropdown-content"
            ref="dropdownMenu"
            @mouseover="isOpen = true"
            @mouseleave="isOpen = false"
          >
            <router-link to="/products/eCubeServer">
              <span
                :class="{
                  dropdownContentActive: this.$route.path.includes(
                    'eCubeServer'
                  )
                }"
              >
                eCube Server
              </span>
            </router-link>
            <router-link to="/products/eCubeHeadstages">
              <span
                :class="{
                  dropdownContentActive: this.$route.path.includes(
                    'eCubeHeadstages'
                  )
                }"
              >
                eCube Headstages
              </span>
            </router-link>

            <router-link to="/products/nanoZ">
              <span
                <span
                :class="{
                  dropdownContentActive: this.$route.path.includes('nanoZ')
                }"
              >
                nanoZ
              </span>
            </router-link>
            <router-link to="/products/e3Vision">
              <span
                <span
                :class="{
                  dropdownContentActive: this.$route.path.includes('e3Vision')
                }"
              >
                e3Vision
              </span>
            </router-link>
            <router-link to="/products/accessories">
              <span
                <span
                :class="{
                  dropdownContentActive: this.$route.path.includes(
                    'accessories'
                  )
                }"
              >
                Accessories
              </span>
            </router-link>
          </div>
        </div>
      </li>

      <li class="nav-item">
        <router-link to="/about" :class="{}">
          <transition name="fade" mode="out-in">
            <span
              v-if="!isSearch"
              class="underlined"
              :class="{ underline: this.$route.name == 'about' }"
            >
              About
            </span>
          </transition>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link to="/support" :class="{}">
          <transition name="fade" mode="out-in">
            <span
              v-if="!isSearch"
              class="underlined"
              :class="{ underline: this.$route.name == 'support' }"
            >
              Support
            </span>
          </transition>
        </router-link>
      </li>

      <li class="nav-item">
        <div
          v-if="!isSearch"
          id="search"
          @click="
            isSearch = !isSearch;
            setCursor;
          "
        >
          <img src="../../icons/search.svg" width="15px" alt="" />
        </div>

        <div id="search" v-if="isSearch" @click="isSearch = !isSearch">
          <img src="../../icons/close.svg" width="15px" alt="" />
        </div>
      </li>
    </ul>
    <transition name="reveal" mode="out-in">
      <div
        class="search-bar"
        :class="{ 'base-search-bar': isTopOfPage }"
        v-if="isSearch"
      >
        <form
          autocomplete="off"
          id="searchy"
          class=""
          @submit.prevent="handleSubmit"
        >
          <input
            v-model="query"
            id="foo"
            type="text"
            name=""
            placeholder="Search white-matter.com"
            autofocus
          />
          <div class="search-results" v-show="query">
            <ul id="search-results-list">
              <li v-for="result of queryResults">
                <router-link
                  :to="result.url"
                  v-on:click.native="
                    query = '';
                    isSearch = !isSearch;
                  "
                >
                  <div>
                    {{ result.title }}
                    <p>{{ queryStringMatch(result) }}</p>
                  </div>
                </router-link>
              </li>
              <li v-if="clickedSearchLink">
                foo
              </li>
            </ul>
          </div>
        </form>
      </div>
    </transition>
    <!-- </div> -->
  </div>
</template>

<script>
let index = [
  {
    uuid: 1,
    url: "/products/nanoZ",
    title: "nanoZ",
    content: `Instantly verify and modify your neural probes. Automatic
    electrical impedance testing and electroplating. Don’t just trust your
    probes. Verify, then modify or calibrate. The nanoZ tests the impedance and
    quality of any electrode, including high impedance single unit electrodes,
    tetrodes and silicon electrode arrays with up to 64 channels.`
  },
  {
    uuid: 2,
    url: "/products/e3Vision",
    title: "e3Vision",
    content: `Scalable video made easy. Ease of use , turnkey High definition
    networked video cameras with millisecond-precise synchronization.
    Record simultaneously from any number of cameras, for multi-view 3D
    tracking of behavior. Multi-user, multi-experiment, multi-view. One-click
    recordings give you full-color, multi-view, synchronized video - for use in
    3D behavioral tracking, long term analysis. Record videos from Windows,
    Linux, MacOS. Securely control and access remotely. Pair different host
    systems with different sets of cameras. Flexibility: Color/NIR for day/night
    Interchangeable CS-mount lenses Easily adapted with gopro mount Compact
    (esp in the lens axis) Python scriptable wrapper (example) Web interface
    from anywhere Interface / script triggering Standard video formats work
    with OpenCV/Deeplabcut 3D video with multiple simul view tracking
    Compatible with Windows, Linux, MacOS Multi-user and security: Securely
    pair e3vision software with cameras Supports multiple labs on the same
    network with no conflicts in camera usage Camera commands protected by
    modern encryption; non-logged in users cannot view or control cameras
	   Group management for control of camera subsets Lifetime supported
     recording software + HTTP API + Python SDK`
  },
  {
    uuid: 3,
    url: "/products/eCubeServer",
    title: "eCube Server",
    content: `eCube Server. Your entire lab in a cube. A fully integrated electrophysiology
    system that simultaneously hosts up to 10 independent experiments with
    thousands of channels.`
  },
  {
    uuid: 4,
    url: "/products/eCubeHeadstages",
    title: "eCube Headstages",
    content: `The smallest, lightest, and most modular headstages in the world. HS-32 lightest on the market, ~0.1 g, 32 channels (fixed) HS-64 HS-640 HS-W`
  },
  {
    uuid: 5,
    url: "/products/accessories",
    title: "accessories",
    content: `Headstage tester. Tethers. Electrode Interface boards.
    Breakout boxes.`
  },
  {
    uuid: 6,
    url: "/products/accessories#HeadstageTester",
    title: "Headstage Tester",
    content: "Headstage Tester precision arbitrary waveform generator"
  },
  {
    uuid: 7,
    url: "/products/accessories#Tethers",
    title: "Tethers",
    content: `Tethers. flexible, 1.1 ~ 1.6 mm O.D. headstage tether cables are available as regular cables or with our unique lightweight inline slip-ring commutator. Cable length made to order, up to 10 meters long, with or without magnets for easy plugging.`
  },
  {
    uuid: 8,
    url: "/products/accessories#ElectrodeInterfaceBoards",
    title: "Adaptors",
    content: "electrode interface boards adaptors"
  },
  {
    uuid: 9,
    url: "/products/accessories#ElectrodeInterfaceBoards",
    title: "Electrode Interface Boards",
    content: "electrode interface boards eibs"
  },
  {
    uuid: 10,
    url: "/products/accessories#BreakoutBoxes",
    title: "Breakout Boxes",
    content: `Breakout Boxes. 32 and 64 channel breakout boxes for easy interfacing with existing lab equipment.`
  }
];

import fz from "fuzzaldrin-plus";

let OFFSET = 200;
export default {
  name: `Index`,
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
      isOpen: false,
      isTopOfPage: true,
      isSearch: false,
      query: "",
      options: index,
      clickedSearchLink: false,
      routes: [
        { page: "index", active: true },
        { page: "products", active: false },
        { page: "about", active: false },
        { page: "support", active: false }
      ]
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  created() {
    document.addEventListener("click", this.documentClick);
  },
  destroyed() {
    // important to clean up!!
    document.removeEventListener("click", this.documentClick);
    window.removeEventListener("scroll", this.onScroll);
  },
  computed: {
    computeHeight() {
      if (this.isTopOfPage) {
        return "30px";
      } else {
        return "25px";
      }
    },
    darkPage() {
      if (this.$route.name !== " ") {
        return false;
      } else {
        return true;
      }
    },
    queryResults() {
      if (!this.query) return this.options;

      const preparedQuery = fz.prepareQuery(this.query);
      const scores = {};

      let scoreResult = this.options
        .map(option => {
          const scorableFields = [option.content].map(toScore =>
            fz.score(toScore, this.query, { preparedQuery })
          );

          scores[option.uuid] = Math.max(...scorableFields);

          return option;
        })
        .filter(option => scores[option.uuid] > 1)
        .sort((a, b) => scores[b.uuid] - scores[a.uuid]);

      console.log(scoreResult);

      return scoreResult.slice(0, 5);
    }
  },
  methods: {
    onScroll() {
      if (window.pageYOffset < 0) {
        return;
      }

      if (window.pageYOffset > 40) {
        this.isTopOfPage = false;
      }

      if (window.pageYOffset < 40) {
        this.isTopOfPage = true;
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
        return;
      }

      this.lastScrollPosition = window.pageYOffset;
    },
    documentClick(e) {
      let el = this.$refs.dropdownMenu;
      let target = e.target;

      if (this.isOpen) {
        this.isOpen = false;
      }

      if (el.contains(target) || target.contains(el)) {
        this.isOpen = false;
      }
    },
    setCursor() {
      document.getElementById("searchy").foo.focus();
    },
    handleSubmit() {
      console.log(results);
    },
    queryStringMatch(result) {
      let matchString = fz.match(result.content, this.query);
      console.log(matchString);

      if (matchString[matchString.length - 1] - matchString[0] > 30) {
        return result.content.slice(matchString[0], matchString[0] + 30);
      } else {
        return result.content.slice(matchString[0], matchString.length);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$brand-yellow: #f4ab01;
.down-arrow {
  display: inline;
  position: relative;
  top: 13px;
  left: 2px;
  border-color: white transparent;
  border-style: solid;
  border-width: 4px 4px 0px 4px;
  height: 0px;
  width: 0px;
}

.whiteTriangle {
  border-color: white transparent;
}

.grayTriangle {
  border-color: hsl(0, 0%, 30%) transparent;
}

.underline {
  border-bottom: 2px solid $brand-yellow;
}

.header-logo {
  margin-left: 100px;
  top: -3px;
  position: relative;

  /* padding: 10px 0 0 100px; */
}

.header-logo a {
  text-decoration: none;
}

.textgo {
  position: relative;
  top: -7px;
  font-weight: 300;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 1px;
}

#search {
  position: relative;
  top: 5px;
  cursor: pointer;
  width: 10px;
}

.search-bar {
  // display: none;
  position: absolute;
  top: 22px;
  right: 100px;
  height: 10px;
  transition: all 0.4s ease;
}

.search-bar.base-search-bar {
  top: 36px;
}

.search-bar input {
  height: 30px;
  width: 340px;
  // box-shadow: inset 0 2px 4px 0 hsla(0, 0%, 0%, 0.08);
  border: 2px solid $brand-yellow;
  padding-left: 20px;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 14px;
}

.search-results {
  height: 350px;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  background-color: hsl(0, 0%, 99%);
  padding-top: 10px;
  padding-left: 30px;
}

.search-results a {
  text-decoration: none;
  color: hsl(0, 0%, 30%);
}

ul {
  list-style-image: url("../../icons/triangle.png");
}

li {
  margin-bottom: 10px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 400;
  color: hsl(0, 0%, 40%);
}

input {
  outline: none;
}

.largeFont {
  // font-size: 30px;
}

.white {
  color: white;
}

.red {
  color: red;
}

// .gray {
//   color: hsl(0, 0%, 30%);
// }

.nav-links {
  display: flex;
  list-style: none;
  align-items: center;
  padding: 22px 50px 0 0;
  font-weight: 400;
}

.nav-links a {
  text-decoration: none;
  // color: hsl(0, 0%, 30%);
}

a {
  color: hsl(0, 0%, 30%);
}

a:hover,
a.router-link-active,
a.router-link-exact-active {
  color: hsl(0, 0%, 30%);
  cursor: pointer;
}

.nav-item {
  font-size: 15px;
  max-width: 150px;
  margin-right: 30px;
  letter-spacing: 2px;
}

.underlined {
  padding-bottom: 3px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 100vw;
  // background-color: hsla(0, 0%, 99%, 0.97);
  position: fixed;
  z-index: 10000000000;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  transition: all 0.4s ease;
}

.navbar.base-navbar #logo {
  height: 30px;
}

.navbar #logo {
  height: 25px;
  transition: height 0.15s;
}

.navbar.base-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 95px;
  width: 100vw;
  // background-color: hsla(0, 0%, 100%, 1);
  // border-bottom: 1px solid #ccc;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  position: fixed;
  z-index: 10000000000;
  box-shadow: none;
  /* transition: all 0.3s ease; */
}

.white-bg {
  background-color: white;
}

.white-transp-bg {
  background-color: hsla(0, 0%, 99%, 0.97);
}

.no-bg {
  background-color: none;
}

.navbar.hidden-navbar {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}

.spacing {
  margin-right: 10px;
}

.dropbtn {
  color: inherit;
  font-size: 15px;
  min-width: 110px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: none;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 80px;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: -70px;
  background-color: white;
  color: hsl(0, 0%, 60%);
  min-width: 220px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-size: 16px;
  line-height: 10px;
}

.dropdown-content a {
  color: hsl(0, 0%, 60%);
  padding: 18px 20px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: hsl(0, 0%, 98%);
}

.dropdownContentActive {
  color: $brand-yellow;
}

.show {
  display: block;
}

.reveal-enter-active,
.reveal-leave-active {
  transition: all 0.3s;
}
.reveal-enter, .reveal-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scaleX(0);
  transform-origin: length;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scale(0);
}

/*  */
</style>
