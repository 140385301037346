<template lang="html">
  <!-- <div class="wraps"> -->
  <section class="config">
    <div
      class="row center title"
      style="padding-top: 200px; padding-bottom: 40px"
    >
      <h2>Build Your Own Headstage</h2>
    </div>
    <section>
      <div class="navPlain">
        <router-link to="/sfn2019">
          <img src="../images/Logos/Main Logo.svg" width="600px" alt="" />
        </router-link>
      </div>
    </section>
    <section>
      <div class="">
        <IpadConfigurator />
      </div>
    </section>
  </section>
  <!-- </div> -->
</template>

<script>
import IpadConfigurator from "../components/configurator/IpadConfigurator.vue";
export default {
  name: `Index`,
  data() {
    return {
      lastScrollPosition: 0,
      scrollValue: 0,
      foo: 0
    };
  },
  computed: {
    theta() {
      return `transform: rotate(${this.lastScrollPosition / 100}rad)`;
    }
  },
  components: {
    IpadConfigurator
  },
  methods: {
    onScroll() {
      this.lastScrollPosition = window.pageYOffset;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  }
};
</script>

<style lang="scss">
$brand-yellow: #f4ab01;

.navPlain {
  position: absolute;
  display: grid;
  align-items: center;
  justify-items: center;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 150px;
  box-shadow: 0 2px 6px hsla(0, 0%, 0%, 0.2), 0 2px 6px hsla(0, 0%, 0%, 0.2);
}

.wraps {
  overflow-x: hidden;
  overflow-y: hidden;
  background: white;
  // height: 1620px;
  // width: 1236px;
}

.mainTitle {
  display: grid;
  align-items: center;
  justify-items: center;
  padding-top: 180px;
  font-size: 90px;
  font-weight: 800;
  // font-family: "Montserrat", Arial, sans-serif;
}

.block {
  height: 600px;
  // box-shadow: 0 2px 6px hsla(0, 0%, 0%, 0.2), 0 2px 6px hsla(0, 0%, 0%, 0.2);
  margin: 30px;
}

.number {
  position: relative;
  top: 50px;
  left: 50px;
  font-size: 120px;
  font-weight: 600;
  color: white;
}

.survey {
  position: relative;
  top: 200px;
  left: 100px;
  width: 400px;
  height: 180px;
  font-size: 60px;
}

.arrow {
  position: relative;
  top: 80px;
  left: 520px;
}

.win {
  position: relative;
  left: 700px;
  top: -150px;
}

.win img {
  animation: pulsy 2s ease-in-out infinite;
}

.winText {
  position: relative;
  left: 780px;
  top: -350px;
  font-size: 40px;
  width: 250px;
  text-align: center;
}

.headStage {
  position: relative;
  top: 200px;
  left: 200px;
  width: 800px;
  height: 200px;
  font-size: 60px;
}

button {
  color: hsl(0, 0%, 20%);
}

.gray {
  background-color: hsl(0, 0%, 90%);
}

.yellow {
  background-color: $brand-yellow;
}

.darkGray {
  background-color: hsl(0, 0%, 90%);
}

@keyframes pulsy {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
</style>
