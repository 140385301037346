<template lang="html">
  <div class="techSpecs">
    <table id="customers">
      <tr v-for="spec in specs">
        <td>{{ spec.spec }}</td>
        <td>{{ spec.description }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
let specs = [
  { spec: "Headstage capacity", description: "10 headstage inputs" },
  { spec: "Channel count", description: "640 channels per headstage input" },
  { spec: "Digital I/O", description: "64 bits configurable TTL" },
  {
    spec: "Analog Inputs",
    description: "32 channels, 16-bit ± 10V up to 200 kSps"
  },
  { spec: "Analog Outputs", description: "2 channels, ±5V up to 200 kHz" },
  { spec: "PC Interface", description: "Gigabit Ethernet"},
  { spec: "Dimensions", description: "6 × 6 × 6 inches (152 × 152 × 152 mm)" }
];

export default {
  name: `tabTechnicalSpecs`,
  data() {
    return {
      specs: specs
    };
  }
};
</script>

<style lang="css" scoped>
.techSpecs {
  height: inherit;
  background-color: white;
  overflow-y: scroll;
}
#customers {
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
  font-weight: 300;
  font-size: 14px;

  @media (max-width: 600px) {
    font-size: 10px;
  }
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}
</style>
