<template lang="html">
  <div class="Downloads">
    <h3>Documentation</h3>
    <ul>
      <li>
        <a href="/nanoZUserManual.pdf">nanoZ User Guide</a>
      </li>
    </ul>

    <h3>Software</h3>
    <ul>
      <li>
        <a href="https://storage.googleapis.com/white-matter/nanoz/nanoZ%2BSoftware%2BManual.zip" download>Software + Manual</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: `tabDownloads`,
  methods: {
    download(asset) {}
  }
};
</script>

<style lang="css" scoped>
.Downloads {
  height: 450px;
  background-color: white;

}
</style>
