<template lang="html">
  <div>
    <div class="pc-2"></div>
    <div class="pulsating-circle">
      <div class="plus">
        +
      </div>
    </div>
    <!-- <div class="circle" v-show="clicked"></div> -->
  </div>
</template>

<script>
export default {
  name: `Tooltip`,
  data() {
    return {
      clicked: false
    };
  }
};
</script>

<style lang="scss" scoped>
// .active {
//   border: 5px solid #f4ab01;
//   border-radius: 100px;
// }

.pc-2 {
  position: absolute;
  left: -41.65px;
  top: -41px;
  width: 80px;
  height: 80px;
  cursor: pointer;
  z-index: 4;
  font-size: 23px;
  background-color: #ccc;
  animation: pulse-ring 2.65s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  border-radius: 100px;

  @media (max-width: 450px) {
    left: -31.65px;
    top: -31px;
    width: 60px;
    height: 60px;
  }
}

.pulsating-circle {
  position: absolute;
  left: 0%;
  top: 0%;
  transform: translateX(-54%) translateY(-52%);
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 6;
  font-size: 20px;
  border: 5px solid transparent;
  border-radius: 100px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -99%;
    margin-top: -99%;
    border-radius: 45px;
    // background-color: #ccc;
    // animation: pulse-ring 2.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 3px;
    display: block;
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 85px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }
}

.plus {
  position: relative;
  z-index: 5;
  top: -62px;
  left: 9.5px;
  color: black;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0);
  }
  20% {
  }
  80% {
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
