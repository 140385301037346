<template lang="html">
  <div class="contents">
    <div>
      <ul>
        <li v-for="item in inTheBox">
          <p>{{ item }}</p>
        </li>
      </ul>
    </div>
    <div class="">
      <img
          src="../../../images/eCube Server/ecube_unboxed_small.png"
          width="400px"
          alt=""
      />
    </div>
  </div>
</template>

<script>
let inTheBox = [
  "eCube Server electrophysiology system",
  "Power supply",
  "Quickstart guide",
  "Lifetime supported ServerNode recording software"
];

export default {
  name: `tabPackageContents`,
  data() {
    return {
      inTheBox: inTheBox
    };
  }
};
</script>

<style lang="scss" scoped>
.contents {
  height: 300px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 0 20px;
  font-weight: 300;
  background-color: white;
  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    font-size: 10px;
  }
}

.contents ul {
  padding: 0 20px;
}

.contents img {
  @media (max-width: 800px) {
    height: auto;
    width: 100%;
  }
}
</style>
