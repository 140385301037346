<template lang="html">
  <div class="mobileNavbar">
    <div class="header-logo" :class="$mq" id="logo">
      <router-link to="/">
        <img src="../../images/Logos/logo no text.svg" height="130%" />
        <!-- <span class="textgo" :class="$mq"> White Matter</span> -->
      </router-link>
    </div>

    <div
      class="hamburger-menu"
      @click="isHamburgerClicked = !isHamburgerClicked"
    >
      <img src="../../icons/hamburger-menu.svg" width="18px" alt="" />
    </div>

    <SideBar :isSideBarActive="this.isHamburgerClicked" @closeSideBar="closeIt">
    </SideBar>

    <!-- <div> -->
  </div>
</template>

<script>
import SideBar from "./SideBar.vue";

let OFFSET = 200;
export default {
  name: `Index`,
  data() {
    return {
      isHamburgerClicked: false
    };
  },
  components: {
    SideBar
  },
  methods: {
    closeIt() {
      this.$nextTick(() => {
        this.isHamburgerClicked = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$brand-yellow: #f4ab01;

.header-logo {
  margin-left: 100px;
  top: -3px;
  position: relative;

  &.xs {
    margin-left: 30px;
  }

  &.sm {
    margin-left: 30px;
  }

  &.md {
    margin-left: 30px;
  }

  /* padding: 10px 0 0 100px; */
}

.hamburger-menu {
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 20px;
  width: 28px;
}

.header-logo a {
  text-decoration: none;
}

.textgo {
  position: relative;
  top: -7px;
  font-weight: 300;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 1px;

  &.sm {
    font-size: 0;
  }

  &.md {
    font-size: 24px;
  }
}

ul {
  list-style-image: url("../../icons/triangle.png");
}

li {
  margin-bottom: 10px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 400;
  color: hsl(0, 0%, 40%);
}

.mobileNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 100vw;
  background-color: hsla(0, 0%, 99%, 1);
  position: fixed;
  z-index: 10000000000;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  transition: all 0.4s ease;
}

.mobileNavbar.base-navbar #logo {
  height: 30px;
}

.mobileNavbar #logo {
  height: 25px;
  transition: height 0.15s;
}

.mobileNavbar.base-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 95px;
  width: 100%;
  // background-color: hsla(0, 0%, 100%, 1);
  // border-bottom: 1px solid #ccc;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  position: fixed;
  z-index: 10000000000;
  box-shadow: none;
  /* transition: all 0.3s ease; */
}

.reveal-enter-active,
.reveal-leave-active {
  transition: all 0.3s;
}
.reveal-enter, .reveal-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scaleX(0);
  transform-origin: length;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scale(0);
}

/*  */
</style>
