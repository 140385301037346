<template lang="html">
  <div class="container">
    <div class="altImgTextLayout">
      <div class="altImgTextLayout__image">
        <img
          id="heroImg"
          src="../images/e3Vision/e3Vision Front.png"
          height="474px"
          alt=""
        />
      </div>
      <div class="altImgTextLayout__text heroText">
        <h1>e3Vision</h1>
        <h2>Scalable video made easy.</h2>
        <p>
          High definition networked video cameras with millisecond-precise
          synchronization. Record simultaneously from any number of cameras, for
          multi-view 3D tracking of behavior.
        </p>
      </div>
    </div>

    <div class="altImgTextLayout">
      <div class="altImgTextLayout__image">
        <video height="350px" loop muted autoplay playsinline ref="unbox">
          <source src="../videos/e3visionV3.mp4" type="video/mp4" />
        </video>
      </div>

      <div class="altImgTextLayout__text">
        <h2>
          Multi-user, multi-experiment, multi-view.
        </h2>
        <ul>
          <li>
            <p>
              Control securely and access remotely.
            </p>
          </li>
          <li>
            <p>
              Process video stream in real-time and simultaneously record to disk.
            </p>
          </li>
          <li>
            <p>
              Pair different host systems with up to 60 cameras on one network.
            </p>
          </li>
          <li>
            <p>
              Lifetime license and software support included; no recurring annual costs.
            </p>
          </li>
        </ul>
      </div>
    </div>

    <section>
      <div class="section">
        <div class="feature-title">
          <h2>Made for Research</h2>
          <p>Infrared or visible light, behavioral monitoring or <a href="https://www.mousemotorlab.org/deeplabcut">deep-learning 3D tracking</a>, real-time or offline processing: e3Vision flexibly covers all these use cases. <a href="https://www.mousemotorlab.org/toolsweuse">Road-tested by the DeepLabCut authors</a> and <span class="tooltip" ontouchstart="">featured in tracking case studies.
          <span class="tooltiptext">e3Vision used in DeepLabCut Case Study #7: pupil tracking by Thomas Vaissière</span></span></p>
        </div>
        <div class="text-padtop">
          <p>With their miniature size, universal GoPro-style mounts, and single-cable connections, our cameras and hubs are made for fast and easy deployment in compact labs and behavioral enclosures.</p>
        </div>
        <div class="unboxVid">
          <video height="450px" loop muted autoplay playsinline>
            <source src="../videos/UnboxTrimmed1.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <br />
      <div class="row center">
        <table id="feature-table">
          <tr>
            <th></th>
            <th v-for="colName in compTableColumnNames">
              {{ colName }}
            </th>
          </tr>

          <template v-for="row in compTableEntries">
            <tr>
              <th>{{ row.rowTitle }}</th>
              <th v-for="colName in compTableColumnNames">
                <div class="td-col-2">
                  <div class="check-left">
                    <img
                      :src="row[colName]['icon']"
                      alt=""
                      data-aos="fade-right"
                    />
                  </div>
                  <div class="check-right">
                    <p style="text-align: left">
                      {{ row[colName].text }}
                    </p>
                  </div>
                </div>
              </th>
            </tr>
          </template>
        </table>

        <br />
      </div>
    </section>

    <section class="tabbed-ish">
      <div class="">
        <div class="tabbed-info">
          <button
            id="product-tabs"
            v-for="tab in tabs"
            v-bind:key="tab"
            v-bind:class="['tab-button', { active: currentTab === tab }]"
            v-on:click="currentTab = tab"
          >
            {{ tab }}
          </button>
        </div>

        <transition name="component-fade" mode="out-in">
          <component v-bind:is="currentTabComponent" class="tab"></component>
        </transition>
      </div>
    </section>
  </div>
</template>

<script>
let compTableColumnNames = [
  "e3Vision",
  "Security Cameras",
  "Machine Vision Cameras"
];

let compTableEntries = [
  {
    rowTitle: "On-camera compression",
    "e3Vision": {
      icon: require("../icons/check.svg"),
      text: "15 cameras per hub, up to 4 hubs per gigabit network"
    },
    "Security Cameras": {
      icon: require("../icons/check.svg"),
      text: "10-50 cameras on separate switches on network"
    },
    "Machine Vision Cameras": {
      icon: require("../icons/x.svg"),
      text: "1-2 cameras fills up a gigabit network"
    }
  },
  {
    rowTitle: "Frame synchronization",
    "e3Vision": {
      icon: require(`../icons/check.svg`),
      text: `One-click synchronized recordings for all cameras`
    },
    "Security Cameras": {
      icon: require(`../icons/x.svg`),
      text: `Clock differences cause seconds of video drift over hours, cannot be synchronized with neural data`
    },
    "Machine Vision Cameras": {
      icon: require(`../icons/check.svg`),
      text: `Can be synchronized using a separate sync cable`
    }
  },
  {
    rowTitle: "Ease of use",
    "e3Vision": {
      icon: require(`../icons/check.svg`),
      text: `Single cable handles data, power, and sync`
    },
    "Security Cameras": {
      icon: require(`../icons/check.svg`),
      text: `Single data / power cable, not synchronized`
    },
    "Machine Vision Cameras": {
      icon: require(`../icons/x.svg`),
      text: `Separate data / power, and sync cables`
    }
  },
  {
    rowTitle: "Automation",
    "e3Vision": {
      icon: require(`../icons/check.svg`),
      text: `Full automation with Python, MATLAB, or Javascript`
    },
    "Security Cameras": {
      icon: require(`../icons/x.svg`),
      text: `Proprietary GUI, not easily integrated with experiments`
    },
    "Machine Vision Cameras": {
      icon: require(`../icons/x.svg`),
      text: `Depends on manufacturer`
    }
  },
  {
    rowTitle: "Secure remote access",
    "e3Vision": {
      icon: require(`../icons/check.svg`),
      text: `Local only or self-hosted remote control options. Authenticated login and encrypted control of cameras.`
    },
    "Security Cameras": {
      icon: require(`../icons/x.svg`),
      text: `Cloud-based remote access and storage not suitable for human or animal research recordings`
    },
    "Machine Vision Cameras": {
      icon: require(`../icons/x.svg`),
      text: `Can usually only be viewed or controlled by the recording PC, authentication generally not supported`
    }
  }
];

import AOS from "aos";
import "aos/dist/aos.css";
import tabPackageContents from "../components/products/e3Vision/tab-PackageContents.vue";
import tabTechnicalSpecs from "../components/products/e3Vision/tab-TechnicalSpecs.vue";
import tabSoftware from "../components/products/e3Vision/tab-Downloads.vue";

export default {
  name: `e3Vision`,
  components: {
    tabPackageContents,
    tabTechnicalSpecs,
    tabSoftware
  },
  data() {
    return {
      compTableColumnNames: compTableColumnNames,
      compTableEntries: compTableEntries,
      tabs: ["Package Contents", "Technical Specs", "Software"],
      currentTab: "Package Contents"
    };
  },
  computed: {
    currentTabComponent: function() {
      return "tab" + this.currentTab.split(" ").join("");
    }
  },
  created() {
    AOS.init({
      duration: 500,
      offset: 100,
      easing: "ease-in-out-cubic",
      once: true
    });
  },
  mounted() {
    let { unbox } = this.$refs;
    // Declare Scene
    const scene = this.$scrollmagic
      .scene({
        // ID of element where animation starts
        triggerElement: unbox,

        // {0,0.5,1} - animations starts from {top,center,end} of window
        triggerHook: 0.5,

        // Duration of animation
        duration: 600
      })
      // .addIndicators({ name: "2 (duration: 350)" })
      .on("enter", function() {
        unbox.play();
      })
      .on("leave", function() {
        unbox.pause();
        unbox.currentTime = 0;
      });

    // Add Scene to controller
    this.$scrollmagic.addScene(scene);

    // Attaching scrollmagic controller to element
    // this.$scrollmagic.attachTo(this.$refs.scrollBox);
  },
  destroyed() {
    this.$scrollmagic.destroy();
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 50px;
  display: grid;
  justify-items: center;
  overflow: hidden;

  @media (max-width: 800px) {
    padding-top: 100px;
  }
}

.altImgTextLayout {
  display: flex;
  margin: 0 200px 50px;

  &:nth-child(even) {
    flex-direction: row-reverse; // 3
  }

  @media (max-width: 1200px) {
    margin: 0 100px;
  }
  @media (max-width: 900px) {
    margin: 0 50px;
  }
  @media (max-width: 800px) {
    display: block;
  }
  @media (max-width: 600px) {
    margin: 0 40px;
  }

  &__text {
    width: 100%;
    flex: 1;
    // padding-top: 100px;

    @media (max-width: 1250px) {
      // padding-top: 100px;
    }
    @media (max-width: 1000px) {
      // padding-top: 50px;
    }
    @media (max-width: 800px) {
      padding-top: 10px;
      padding-bottom: 20px;
    }
  }

  &__image {
    flex: 1;
  }

  &__image img {
    height: auto;
    width: 100%;

    @media (max-width: 1200px) {
      height: auto;
      width: 80%;
    }
    @media (max-width: 900px) {
      height: auto;
      width: 90%;
    }
    @media (max-width: 800px) {
      height: auto;
      width: 45%;
    }
    @media (max-width: 600px) {
      height: auto;
      width: 65%;
    }
    @media (max-width: 450px) {
      height: auto;
      width: 75%;
    }
  }

  &__image video {
    height: auto;
    width: 100%;
  }
}
#heroImg {
  padding-left: 50px;
  width: 80% !important;
  @media (max-width: 800px) {
    padding-left: 0;
    width: 45% !important;
  }
}
.heroText {
  padding-top: 130px;
  @media (max-width: 1250px) {
    padding-top: 100px;
  }
  @media (max-width: 1000px) {
    padding-top: 50px;
  }
  @media (max-width: 800px) {
    padding-top: 0;
  }
}

.unboxVid video {
  height: auto;
  width: 100%;
}

.section {
  margin: 0 150px;

  @media (max-width: 900px) {
    margin: 0 50px;
  }
}

.text-padtop {
  margin: 1em 0;
}

.brand-yellow {
  color: #f4ab01;
}

.tooltip {
  text-decoration: underline blue;
  text-decoration-style: dotted;
}

.tooltip .tooltiptext {
  max-width: 30em;
  background-color: white;
  text-align: center;
  border: 1px solid;
  padding: 5px;
  visibility: hidden;

  position: absolute;
  z-index: 1;
  left: 50%;
}

.tooltip:hover .tooltiptext, .tooltip:active .tooltiptext, .tooltip:focus .tooltiptext {
  visibility: visible;
}

.td-col-2 {
  display: flex;
  align-items: center;

  @media (max-width: 700px) {
    display: block;
    text-align: left;
  }
}

.td-col-2 img {
  width: 55px;
  @media (max-width: 700px) {
    width: 35px;
  }
  @media (max-width: 350px) {
    width: 10px;
  }
}

table#feature-table {
  width: 80%;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  overflow-x: scroll;
  // background-color: hsl(206, 24%, 98%);
  padding: 50px 20px;
  // margin-left: 50px
  @media (max-width: 900px) {
    margin: 0 50px;
    padding: 0;
    width: 90%;
  }
  @media (max-width: 700px) {
    margin: 0 30px;
    padding: 0;
    // width: 70%;
  }
  @media (max-width: 600px) {
    margin: 0 20px;
    padding: 0 0px;
    // width: 70%;
  }
}

th,
td {
  padding: 8px 5px;
  @media (max-width: 700px) {
    padding: 0 5px;
    font-size: 9px;
    line-height: 12px;
  }
  @media (max-width: 350px) {
    padding: 0 0;
  }
}
//
th p {
  font-size: 16px;
  letter-spacing: 0em;
  @media (max-width: 1000px) {
    font-size: 12px;
    line-height: 14px;
  }
  @media (max-width: 700px) {
    font-size: 8px;
    line-height: 8px;
  }
}

.tabbed-ish {
  padding: 0px 150px 100px 150px;
  width: 80%;

  @media (max-width: 800px) {
    padding: 0 50px 50px 50px;
  }
  @media (max-width: 800px) {
    padding: 0 40px 50px 40px;
  }
}

.tabbed-info {
  border-bottom: 4px solid #f4ab01;
  padding-top: 30px;
}

button#product-tabs {
  width: 160px;
  box-shadow: none;
  border-radius: 0px;
  margin: 0;
  font-size: 18px;
  font-weight: 300;
  color: hsl(0, 0%, 30%);

  @media (max-width: 800px) {
    width: 120px;
    font-size: 14px;
  }
  @media (max-width: 600px) {
    width: 90px;
    font-size: 10px;
  }
  @media (max-width: 400px) {
    width: 80px;
    font-size: 8px;
  }
}

.tab-button {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
  margin-bottom: -1px;
  background-color: inherit;
}
.tab-button:hover {
  // background: none;
  // color: white;
}
button#product-tabs.active {
  background: #f4ab01;
  color: white;
}

.tab {
  padding: 30px 50px;
  height: 300px;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);

  @media (max-width: 600px) {
    padding: 10px 20px;
  }
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
</style>
