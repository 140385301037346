<template>
  <div class="hsConfiguratorIpad">
    <!-- <h3 id="iPad">HS-640</h3> -->
    <!-- <p>Designed to scale across experiments.</p> -->

    <h3 id="iPad">Channels</h3>
    <div class="btn-group-Ipad">
      <button
        v-for="(channelCount, index) in channelCount"
        :key="channelCount.count"
        @click="selectChannelCount(index)"
        :class="{ active: channelCount.active }"
      >
        <span> {{ channelCount.count }}</span>
      </button>
    </div>

    <h3 id="iPad">Weight: {{ weight }}g</h3>
    <h3 id="iPad">Footprint: 10.4 x 10.9 mm</h3>

    <h3 id="iPad">Factory Options</h3>
    <div class="button-grid-Ipad">
      <button
        v-for="option in factoryOptions"
        @click="toggleActive(option.name)"
        :class="{ active: isModuleActive(option.name) }"
        id="moduleButtonsIpad"
      >
        <span> {{ option.name }}</span>
      </button>
    </div>

    <h3 id="iPad">Add-on Modules</h3>
    <div class="button-grid-Ipad">
      <button
        v-for="module in modules"
        @click="toggleActive(module.name)"
        :class="{ active: isModuleActive(module.name) }"
        id="moduleButtonsIpad"
      >
        <span> {{ module.name }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { TweenLite } from "gsap";
import { store, mutations } from "../../store";

let factoryOptions = [
  { name: "tracking LEDs" },
  { name: "mag-tether" },
  { name: "hardwired" }
];

let modules = [
  { name: "optoStim" },
  { name: "eStim" },
  { name: "imu" },
  { name: "adc" },
  { name: "mic" },
  { name: "gpio" }
];

let optionsMap = {
  optoStim: 0,
  eStim: 1,
  imu: 2,
  adc: 3,
  mic: 4,
  gpio: 5,
  "tracking LEDs": 6,
  "mag-tether": 7,
  hardwired: 8
};

let activeOptions = {
  optoStim: false,
  eStim: false,
  imu: false,
  adc: false,
  mic: false,
  gpio: false,
  "tracking LEDs": false,
  "mag-tether": false,
  hardwired: false
};

let baseWeight = Math.round(0.78 * 100) / 100;
let hsWeight = 0.32;

let weights = {
  imu: 0.028,
  mmx: 0.25,
  "mag-tether": 0.2095,
  hardwired: -0.05,
  "tracking LEDs": 0.0,
  optoStim: 0.035,
  eStim: 0.055,
  adc: 0.02,
  mic: 0.12,
  gpio: 0.015
};

let imgString = [
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none"
];

let channelCount = [
  { count: "64", active: true },
  { count: "128", active: false },
  { count: "192", active: false },
  { count: "256", active: false },
  { count: "320", active: false },
  { count: "384", active: false },
  { count: "448", active: false },
  { count: "512", active: false },
  { count: "576", active: false },
  { count: "640", active: false }
];

export default {
  name: `Hs64Modules`,
  data() {
    return {
      factoryOptions: factoryOptions,
      modules: modules,
      activeTether: "",
      activeOptions: activeOptions,
      imgString: imgString,
      channelCount: channelCount,
      weight: baseWeight,
      channels: 1,
      numModulesSelected: 0
    };
  },
  computed: {
    animatedNumber() {
      return this.tweenedNumber.toFixed(1);
    }
  },
  watch: {
    activeOptions: {
      handler(val) {
        this.weight = this.computeWeight();

        let numModules = 0;
        let mods = Object.entries(this.activeOptions);
        mods.forEach(modState => {
          if (
            modState[0] != "tracking LEDs" &&
            modState[0] != "mag-tether" &&
            modState[0] != "hardwired"
          ) {
            if (modState[1]) {
              numModules += 1;
            }
          }
        });
        this.numModulesSelected = numModules;
        let numChannels = this.channels;

        this.imgString = this.computeImgString(
          this.numModulesSelected,
          numChannels
        );
        // this.$emit("moduleSelected", [this.activeOptions, modulesSelected])
        this.$emit("moduleSelected", [this.activeOptions, this.imgString]);
      },
      deep: true
    },
    channels(newValue) {
      this.weight = this.computeWeight();
      this.imgString = this.computeImgString(this.numModulesSelected, newValue);

      this.$emit("moduleSelected", [this.activeOptions, this.imgString]);
    },
    weight(newValue) {
      TweenLite.to(this.$data, 1, { tweenedNumber: newValue }).delay(0.5);
    }
  },
  methods: {
    computeImgString(numModules, numChannels) {
      let tracking = this.activeOptions["tracking LEDs"]
        ? "tracking LEDs"
        : "none";
      let magnet = this.activeOptions["mag-tether"] ? "mag-tether" : "none";
      let hardwired = this.activeOptions["hardwired"] ? "hardwired" : "none";

      return `amps${numChannels}-MMX${numModules}-${tracking}-${magnet}-${hardwired}`;
    },
    computeWeight() {
      let entries = Object.entries(this.activeOptions);
      let modWeight = 0;
      let numModsSelected = 0;

      for (let [key, value] of entries) {
        if (value) {
          if (["mag-tether", "tracking LEDs", "hardwired"].includes(key)) {
            modWeight += weights[key];
          } else {
            modWeight += weights[key];
            numModsSelected += 1;
          }
        }
      }
      let totalMMXWeight = Math.ceil(numModsSelected / 3) * weights["mmx"];
      let totalHsWeight = baseWeight + hsWeight * (this.channels - 1);

      let totalWeight = totalHsWeight + modWeight + totalMMXWeight;

      mutations.setWeight(Math.round(totalWeight * 100) / 100);

      return Math.round(totalWeight * 100) / 100;
    },
    selectChannelCount(index) {
      this.channelCount.forEach(function(channelCount) {
        channelCount.active = false;
      });
      this.channelCount[index].active = !this.channelCount[index].active;
      this.channels = index + 1;
    },
    isModuleActive(value) {
      if (value == "mag-tether" || value == "hardwired") {
        return this.isTetherActive(value);
      } else {
        return this.activeOptions[value];
      }
    },
    isTetherActive(value) {
      if (this.activeTether === value) {
        this.activeOptions[value] = true;
        return true;
      } else {
        this.activeOptions[value] = false;
        return;
      }
    },
    toggleActive(id) {
      this.$emit("modClick", id);
      if (id == "mag-tether" || id == "hardwired") {
        if (this.activeTether == id) {
          this.activeTether = "";
        } else {
          this.activeTether = id;
        }
      }
      this.activeOptions[id] = !this.activeOptions[id];
    }
  }
};
</script>

<style lang="scss" scoped></style>
