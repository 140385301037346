<template lang="html">
  <div class="wrap">
    <section class="section1">
      <!-- <div class="bg-overlay"></div> -->
      <div class="bg-image">
        <video loop muted autoplay playsinline>
          <source src="../videos/PickAndPlace.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="mission-strip">
        <!-- <div class="mission-strip__item">
          <div style="width: 400px; padding-top: 40px">
            <div class="title white">
              Our Mission
            </div>
            <div class="text white">
              To provide the most advanced neuroscience tools on the market to
              support brain research while driving the development of
              next-generation neuroprosthetics.
            </div>
          </div>
        </div> 
        <div class="mission-strip__item">
          <img src="../icons/quote.svg" height="200px" alt="" />
        </div>
        <div class="mission-strip__item">
          <div class="quote white">
            <i>
              Designed by neuroscientists, <br />
              for neuroscientists.
            </i>
          </div>
        </div> -->
      </div>
    </section>
    <section class="section2">
      <div class="profile">
        <div class="section-title left" style="padding-top: 20px;">
          About Us
        </div>

        <p>
          Located in Seattle, Washington, White Matter LLC manufactures
          sophisticated data acquisition devices for neuroscience research.
          White Matter has grown from a two person hobby company founded in
          2010, to a team of dedicated engineers and neuroscientists with a
          passion for brain research.
        </p>
        <br />
        <p>
          Our eCube electrophysiology system provide researchers with an
          unparalleled ability to acquire, process and analyze thousands of
          channels of neural data with the smallest and lightest digital
          headstages on the market. We offer a complete ecosystem of scalable
          electrophysiology products that exceed the needs of the most ambitious
          neuroscientist. That’s why over 1,000 research laboratories around the
          world choose White Matter.
        </p>
      </div>

      <br /><br />

      <div class="values">
        <div class="section-title left">
          Our Values
        </div>
        <div class="col-2-about">
          <div class="values__item" data-aos="fade">
            <img src="../icons/values/innovation.svg" height="50px" alt="" />
            <h3>
              Innovation
            </h3>
            <p>
              Our products are without peer: if you can buy it elsewhere, we
              probably don’t make it.
            </p>
          </div>
          <div class="values__item" data-aos="fade" data-aos-delay="250">
            <img src="../icons/values/integrity.svg" height="50px" alt="" />
            <h3>
              Integrity
            </h3>
            <p>
              We meet or exceed our advertised specifications.
            </p>
          </div>

          <div class="values__item">
            <img src="../icons/values/understanding.svg" height="50px" alt="" />
            <h3>
              Understanding
            </h3>
            <p>
              A deep understanding of customer’s needs with unfailing support as
              those needs evolve.
            </p>
          </div>
          <div class="values__item" data-aos="fade">
            <img src="../icons/values/dependability.svg" height="50px" alt="" />
            <h3>
              Dependability
            </h3>
            <p>
              Robust hardware = data reliability, with extended product
              warranties that reflect the quality of our products.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  name: `About`,
  created() {
    AOS.init({
      // offset: 280,
      duration: 600,
      easing: "ease-in-out-cubic",
      // delay: 400,
      once: false
      // disable: false
    });
  }
};
</script>

<style lang="scss" scoped>
$brand-yellow: #f4ab01;

hr {
  // width: 80%;
}

.wrap {
  overflow: hidden;
}

.section1 {
  height: 350px;

  @media (max-width: 600px) {
    height: 250px;
    // padding-top: 25px;
  }
  @media (max-width: 350px) {
    height: 180px;
  }
}

.bg-image {
  position: relative;
  z-index: 1;
  height: inherit;
  width: 100%;
  // background-image: url("../gifs/smt2.gif");
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: hidden;
  // border-radius: 10px;
}

.bg-image video {
  width: inherit;
  // height: 400px;
  // background-size: 600px;
  // max-width: 100%;
  // height: inherit;
}

.bg-image:before {
  content: '';
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  // border-radius: 10px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bg-overlay {
  position: absolute;
  top: 50px;
  z-index: 2;
  opacity: 0.4;
  width: 100vw;
  height: inherit;
  background-image: url("../images/backgrounds/black-overlay.png");
  background-repeat: no-repeat;
  background-size: 100vw;
  overflow: hidden;

  // @media (max-width: 650px) {
  //   background-size: 100%;
  // }
}

.mission-strip {
  position: relative;
  padding-left: 100px;
  height: inherit;
  display: flex;
  flex-direction: row;
  z-index: 3;
  width: 100%;

  @media (max-width: 550px) {
    padding: 0 50px;
  }
  @media (max-width: 450px) {
    padding: 0 40px;
  }
  @media (max-width: 350px) {
    padding: 0 20px;
  }

  &__item {
    // margin-right: 30px;
    padding-top: 80px;

    @media (max-width: 450px) {
      padding-top: 80px;
      // height: 100px;
    }
  }
  &__item img {
    @media (max-width: 600px) {
      height: 160px;
    }
    @media (max-width: 450px) {
      height: 140px;
    }
    @media (max-width: 400px) {
      height: 120px;
    }
  }
}

.section2 {
}

.title {
  font-size: 50px;
  font-weight: 600;
}

.white {
  color: white;
}

.gray60 {
  color: hsl(0, 0%, 40%);
}

.text {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 28px;
}

.quote {
  font-size: 45px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 45px;
  opacity: 1;
  background-color: inherit;
  padding-top: 50px;
  padding-left: 20px;
  @media (max-width: 800px) {
    font-size: 40px;
  }
  @media (max-width: 700px) {
    font-size: 33px;
  }
  @media (max-width: 600px) {
    font-size: 25px;
    line-height: 35px;
    padding-left: 5px;
  }
  @media (max-width: 450px) {
    font-size: 22px;
    padding-top: 40px;
  }
  @media (max-width: 350px) {
    font-size: 18px;
    padding-top: 25px;
    line-height: 30px;
  }
}

.section-title {
  color: $brand-yellow;

  letter-spacing: 5px;
  font-size: 20px;
}

.profile {
  // height: 400px;
  padding: 0 100px;
  @media (max-width: 650px) {
    padding: 20px 100px;
  }
  @media (max-width: 550px) {
    padding: 0 50px;
  }
  @media (max-width: 450px) {
    padding: 0px 40px;
  }
  @media (max-width: 350px) {
    padding: 20px 40px 0;
  }
}

.values {
  padding: 0 100px 50px;

  @media (max-width: 550px) {
    padding: 0 50px 50px;
  }
  @media (max-width: 450px) {
    padding: 0 40px 50px;
  }

  &__item {
    margin-top: 10px;
    margin-right: 50px;
    @media (max-width: 550px) {
      margin-top: 10px;
      margin-right: 20px;
    }
  }

  &__item-title {
    font-size: 22px;
    color: hsl(0, 0%, 20%);
  }
  &__item-text {
    font-size: 18px;
    font-weight: 400;
    color: hsl(0, 0%, 40%);
  }
}

.row {
  width: 100%;
  // padding-left: 200px;
}

.col-2-about {
  display: grid;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
</style>
