<template>
  <div class="productCardMobile">
    <div class="hs-image-mobile" tag="div">
      <div v-if="currentImage.name == 'HS-32'" :key="currentImage.name">
        <div class="test">
          <img id="hs32" :src="this.hs32Mods" height="280px" />
        </div>

        <!-- <div class="tipper__connector">
          <div
            class="plus-butt"
            :class="{
              clicked: descriptionState == 'connector',
              active: descriptionState == 'connector'
            }"
            @click="setDescription('connector')"
          >
            <Tooltip />
          </div>
        </div>
        <div class="tipper__opto">
          <div
            class="plus-butt"
            :class="{
              clicked: descriptionState == 'single optoStim',
              active: descriptionState == 'single optoStim',
              hidden: !this.hs32Mods.includes('single optoStim')
            }"
            @click="setDescription('single optoStim')"
          >
            <Tooltip />
          </div>
        </div>
        <div class="tipper__imu">
          <div
            :class="{
              clicked: descriptionState == 'imu',
              active: descriptionState == 'imu',
              hidden: !this.hs32Mods.includes('imu')
            }"
            @click="setDescription('imu')"
          >
            <Tooltip />
          </div>
        </div> -->
      </div>

      <div v-else-if="currentImage.name == 'HS-64'" :key="'foo'">
        <div class="test">
          <img id="hs64" :src="this.hs64Mods" height="300px" />
        </div>
        <!-- <div class="tipper__connector">
          <div
            class="plus-butt"
            :class="{
              clicked: descriptionState == 'connector',
              active: descriptionState == 'connector'
            }"
            @click="setDescription('connector')"
          >
            <Tooltip />
          </div>
        </div> -->
      </div>
      <div v-if="currentImage.name == 'HS-640'">
        <div class="test">
          <img id="hs640" :src="this.hs640Mods" height="350px" />
        </div>
        <!-- <div class="tipper__connector">
          <div
            class="plus-butt"
            :class="{
              clicked: descriptionState == 'connector',
              active: descriptionState == 'connector'
            }"
            @click="setDescription('connector')"
          >
            <Tooltip />
          </div>
        </div> -->
      </div>
      <div v-else-if="currentImage.name == 'HS-W'" :key="'bar'">
        <div class="test">
          <img id="hsw" :src="this.hsWMods" height="350px" />
        </div>
        <!-- <div class="tipper__connector">
          <div
            class="plus-butt"
            :class="{
              clicked: descriptionState == 'connector',
              active: descriptionState == 'connector'
            }"
            @click="setDescription('connector')"
          >
            <Tooltip />
          </div>
        </div> -->
      </div>
    </div>

    <section class="modules">
      <mq-layout :mq="['xs', 'sm', 'md', 'lg']">
        <div v-if="this.productName == 'HS-32'">
          <Hs32Modules
            @moduleSelected="populateModules"
            @modClick="getModVal"
          />
        </div>

        <div v-else-if="this.productName == 'HS-64'">
          <Hs64Modules
            @moduleSelected="populateModules"
            @modClick="getModVal"
          />
        </div>

        <div v-else-if="this.productName == 'HS-640'">
          <Hs640Modules
            @moduleSelected="populateModules"
            @modClick="getModVal"
          />
        </div>

        <div v-else-if="this.productName == 'HS-W'">
          <HsWModules
            @moduleSelected="populateModules"
            @memStateUpdated="getMemoryUnits"
            @modClick="getModVal"
          />
        </div>
      </mq-layout>
    </section>

    <section class="feature-description">
      <div v-if="this.productName == 'HS-32'">
        <div v-if="descriptionState == 'generic'" class="foo">
          <h4>Our Smallest and Lightest Headstage</h4>
          <p>
            A 32 channel digital headstage under 200mg! Fully optioned with mag
            tether, 9 axis motion sensor, dual channel optogenetic stimulation,
            and tracking LEDs weighs just 0.5g.
          </p>
        </div>

        <div v-if="descriptionState == 'connector'" class="foo">
          <h4>Our Smallest and Lightest Headstage</h4>
          <p>
            A 32 channel digital headstage under 200mg! Fully optioned with mag
            tether, 9 axis motion sensor, dual channel optogenetic stimulation,
            and tracking LEDs weighs just 0.5g.
          </p>
        </div>

        <div
          v-else-if="
            descriptionState == 'single optoStim' ||
              descriptionState == 'dual optoStim'
          "
          class="foo"
        >
          <h4>Single / Dual Optostimulation</h4>
          <p>
            On-headstage stimulus pattern generation with independent LED
            drivers means no more bulky, expensive cables with optical
            commutators. Factory defined LED wavelengths and standard 1.25mm
            optical fiber ferrules.
          </p>
        </div>
        <div v-else-if="descriptionState == 'imu'" class="foo">
          <h4>IMU Motion Sensor</h4>
          <p>
            9-axis digital Inertial Motion Unit (IMU) integrates a 3-axis
            gyroscope, 3-axis accelerometer and 3-axis compass, with
            user-definable settings.
          </p>
        </div>
        <div v-else-if="descriptionState == 'hardwired'" class="foo">
          <h4>Hardwired Tether Option</h4>
          <p>
            The lightest headstage option has the tether cable soldered directly
            to the headstage. Provides data, power and control signals in a
            single ultra-flexible, 1.2mm O.D. cable, with optional inline
            commutator.
          </p>
        </div>
        <div v-else-if="descriptionState == 'mag-tether'" class="foo">
          <h4>Mag Tether Option</h4>
          <p>
            Self-aligning, self-plugging magnetic tether option makes connecting
            effortless, minimizing animal handling and discomfort. Provides
            data, power and control signals in a single ultra-flexible, 1.2mm
            O.D. cable, with optional inline commutator.
          </p>
        </div>
        <div v-else-if="descriptionState == 'tracking LEDs'" class="foo">
          <h4>Tracking LEDs Option</h4>
          <p>
            Diffuse green and red LEDs for video-based position tracking.
            Available in custom wavelengths including IR, upon request.
          </p>
        </div>
        <div class="button-grid pt-s center">
          <button
            class="font-white bg-brand-yellow"
            type="button"
            name="button"
            @click="this.sendModuleData"
          >
            Get Quote
          </button>
        </div>
      </div>

      <div v-if="this.productName == 'HS-64'">
        <div v-if="descriptionState == 'generic'" class="foo">
          <h4>Miniature Headstage with MMX</h4>
          <p>
            Barely larger than the HS-32, this 64 channel digital headstage
            supports one or two multimodal extension (MMX) modules of your
            choice without increasing the headstage footprint.
          </p>
        </div>

        <div v-if="descriptionState == 'connector'" class="foo">
          <h4>Miniature Headstage with MMX</h4>
          <p>
            Barely larger than the HS-32, this 64 channel digital headstage
            supports one or two multimodal extension (MMX) modules of your
            choice without increasing the headstage footprint.
          </p>
        </div>

        <div v-else-if="descriptionState == 'hardwired'" class="foo">
          <h4>Hardwired Tether Option</h4>
          <p>
            The lightest headstage option has the tether cable soldered directly
            to the headstage. Provides data, power and control signals in a
            single ultra-flexible, 1.2mm O.D. cable, with optional inline
            commutator.
          </p>
        </div>
        <div v-else-if="descriptionState == 'mag-tether'" class="foo">
          <h4>Mag Tether Option</h4>
          <p>
            Self-aligning, self-plugging magnetic tether option makes connecting
            effortless, minimizing animal handling and discomfort. Provides
            data, power and control signals in a single ultra-flexible, 1.2mm
            O.D. cable, with optional inline commutator.
          </p>
        </div>
        <div v-else-if="descriptionState == 'tracking LEDs'" class="foo">
          <h4>Tracking LEDs Option</h4>
          <p>
            Diffuse green and red LEDs for video-based position tracking.
            Available in custom wavelengths including IR, upon request.
          </p>
        </div>

        <div v-else-if="descriptionState == 'optoStim'" class="foo">
          <h4>4 Channel Optostim Module</h4>
          <p>
            On-headstage stimulus pattern generation with four independent LED
            drivers means no more bulky, expensive cables with optical
            commutators. User defined LED wavelengths and standard 1.25mm
            optical fiber ferrules.
          </p>
        </div>
        <div v-else-if="descriptionState == 'eStim'" class="foo">
          <h4>Electrical Stimulation Module</h4>
          <p>
            On-headstage stimulus pattern generation with four independent,
            bipolar, charge-balanced constant-current electrical stimulators.
          </p>
        </div>
        <div v-else-if="descriptionState == 'imu'" class="foo">
          <h4>IMU Motion Sensor</h4>
          <p>
            9-axis digital Inertial Motion Unit (IMU) integrates a 3-axis
            gyroscope, 3-axis accelerometer and 3-axis compass, with
            user-definable settings.
          </p>
        </div>
        <div v-else-if="descriptionState == 'adc'" class="foo">
          <h4>ADC Module</h4>
          <p>
            8 channel analog-digital converter module up to 200ksps/ch. For
            signals not requiring additional amplification such as our analog
            microphone.
          </p>
        </div>
        <div v-else-if="descriptionState == 'gpio'" class="foo">
          <h4>GPIO Module</h4>
          <p>
            General purpose input / output module for digital triggering or
            interfacing custom modules with standard digital interfaces.
          </p>
        </div>
        <div v-else-if="descriptionState == 'mic'" class="foo">
          <h4>Microphone Module</h4>
          <p>
            Miniature omni-direction microphones sensitive to ultrasonic and
            audible sounds.
          </p>
        </div>
        <div class="button-grid center pt-s">
          <button
            class="font-white bg-brand-yellow"
            type="button"
            name="button"
            @click="this.sendModuleData"
          >
            Get Quote
          </button>
        </div>
      </div>

      <div v-if="this.productName == 'HS-640'">
        <div v-if="descriptionState == 'generic'" class="foo">
          <h4>High Channel Count Headstage</h4>
          <p>
            Our flagship headstage supports up to 640 electrodes in a tiny 1x1cm
            footprint. Full digital recording and support for multiple MMX
            modules, all routed through one
            <router-link
              :to="{ path: '/products/accessories', hash: '#Tethers' }"
              >ultra-thin, flexible tether cable</router-link
            >.
          </p>
        </div>

        <div v-if="descriptionState == 'connector'" class="foo">
          <h4>High Channel Count Headstage</h4>
          <p>
            Our flagship headstage supports up to 640 electrodes in a tiny 1x1cm
            footprint. Full digital recording and support for multiple MMX
            modules, all routed through one
            <router-link
              :to="{ path: '/products/accessories', hash: '#Tethers' }"
              >ultra-thin, flexible tether cable</router-link
            >.
          </p>
        </div>

        <div v-else-if="descriptionState == 'hardwired'" class="foo">
          <h4>Hardwired Tether Option</h4>
          <p>
            The lightest headstage option has the tether cable soldered directly
            to the headstage. Provides data, power and control signals in a
            single ultra-flexible, 1.2mm O.D. cable, with optional inline
            commutator.
          </p>
        </div>
        <div v-else-if="descriptionState == 'mag-tether'" class="foo">
          <h4>Mag Tether Option</h4>
          <p>
            Self-aligning, self-plugging magnetic tether option makes connecting
            effortless, minimizing animal handling and discomfort. Provides
            data, power and control signals in a single ultra-flexible, 1.2mm
            O.D. cable, with optional inline commutator.
          </p>
        </div>
        <div v-else-if="descriptionState == 'tracking LEDs'" class="foo">
          <h4>Tracking LEDs Option</h4>
          <p>
            Diffuse green and red LEDs for video-based position tracking.
            Available in custom wavelengths including IR, upon request.
          </p>
        </div>

        <div v-else-if="descriptionState == 'optoStim'" class="foo">
          <h4>4 Channel Optostim Module</h4>
          <p>
            On-headstage stimulus pattern generation with four independent LED
            drivers means no more bulky, expensive cables with optical
            commutators. User defined LED wavelengths and standard 1.25mm
            optical fiber ferrules.
          </p>
        </div>
        <div v-else-if="descriptionState == 'eStim'" class="foo">
          <h4>Electrical Stimulation Module</h4>
          <p>
            On-headstage stimulus pattern generation with four independent,
            bipolar, charge-balanced constant-current electrical stimulators.
          </p>
        </div>
        <div v-else-if="descriptionState == 'imu'" class="foo">
          <h4>IMU Motion Sensor</h4>
          <p>
            9-axis digital Inertial Motion Unit (IMU) integrates a 3-axis
            gyroscope, 3-axis accelerometer and 3-axis compass, with
            user-definable settings.
          </p>
        </div>
        <div v-else-if="descriptionState == 'adc'" class="foo">
          <h4>ADC Module</h4>
          <p>
            8 channel analog-digital converter module up to 200ksps/ch. For
            signals not requiring additional amplification such as our analog
            microphone.
          </p>
        </div>
        <div v-else-if="descriptionState == 'gpio'" class="foo">
          <h4>GPIO Module</h4>
          <p>
            General purpose input / output module for digital triggering or
            interfacing custom modules with standard digital interfaces.
          </p>
        </div>
        <div v-else-if="descriptionState == 'mic'" class="foo">
          <h4>Microphone Module</h4>
          <p>
            Miniature omni-direction microphones sensitive to ultrasonic and
            audible sounds.
          </p>
        </div>
        <div class="button-grid center pt-s">
          <button
            class="font-white bg-brand-yellow"
            type="button"
            name="button"
            @click="this.sendModuleData"
          >
            Get Quote
          </button>
        </div>
      </div>

      <div v-if="this.productName == 'HS-W'">
        <div v-if="descriptionState == 'generic'" class="foo">
          <h4>Wireless Headstage</h4>
          <p>
            Tether-free digital data logging from 64 to 320 electrodes, with
            online monitoring and continuous record times up to 2 weeks.
            Supports the same MMX modules as our wired headstages.
          </p>
        </div>

        <div v-if="descriptionState == 'connector'" class="foo">
          <h4>Wireless Headstage</h4>
          <p>
            Tether-free digital data logging from 64 to 320 electrodes, with
            online monitoring and continuous record times up to 2 weeks.
            Supports the same MMX modules as our wired headstages.
          </p>
        </div>

        <div v-else-if="descriptionState == 'optoStim'" class="foo">
          <h4>4 Channel Optostim Module</h4>
          <p>
            On-headstage stimulus pattern generation with four independent LED
            drivers means no more bulky, expensive cables with optical
            commutators. User defined LED wavelengths and standard 1.25mm
            optical fiber ferrules.
          </p>
        </div>
        <div v-else-if="descriptionState == 'eStim'" class="foo">
          <h4>Electrical Stimulation Module</h4>
          <p>
            On-headstage stimulus pattern generation with four independent,
            bipolar, charge-balanced constant-current electrical stimulators.
          </p>
        </div>
        <div v-else-if="descriptionState == 'imu'" class="foo">
          <h4>IMU Motion Sensor</h4>
          <p>
            9-axis digital Inertial Motion Unit (IMU) integrates a 3-axis
            gyroscope, 3-axis accelerometer and 3-axis compass, with
            user-definable settings.
          </p>
        </div>
        <div v-else-if="descriptionState == 'adc'" class="foo">
          <h4>ADC Module</h4>
          <p>
            8 channel analog-digital converter module up to 200ksps/ch. For
            signals not requiring additional amplification such as our analog
            microphone.
          </p>
        </div>
        <div v-else-if="descriptionState == 'gpio'" class="foo">
          <h4>GPIO Module</h4>
          <p>
            General purpose input / output module for digital triggering or
            interfacing custom modules with standard digital interfaces.
          </p>
        </div>
        <div v-else-if="descriptionState == 'mic'" class="foo">
          <h4>Microphone Module</h4>
          <p>
            Miniature omni-direction microphones sensitive to ultrasonic and
            audible sounds.
          </p>
        </div>

        <div v-if="descriptionState == 'standby'" class="foo">
          <!-- <h4>Standby Mode</h4>
          <p>
            Id enim natura desiderat. Aperiendum est igitur, quid sit voluptas;
            Illa videamus, quae a te de amicitia dicta sunt. Videamus animi
            partes, quarum est conspectus illustrior; Sed quanta sit alias, nunc
            tantum possitne esse tanta.
          </p> -->
        </div>
        <div v-if="descriptionState == 'sleep'" class="foo">
          <!-- <h4>Sleep Mode</h4>
          <p>
            Nonne videmus quanta perturbatio rerum omnium consequatur, quanta
            confusio? Illud dico, ea, quae dicat, praeclare inter se cohaerere.
            Eademne, quae restincta siti?
          </p> -->
        </div>

        <button
          class="font-white bg-brand-yellow"
          type="button"
          name="button"
          @click="this.sendModuleData"
        >
          Get Quote
        </button>
      </div>
    </section>
  </div>
</template>

<script>
let hs32_image = require("../images/hs-32_combinatorics/none-none-none-none-none-none.png");
let hs64_image = require("../images/HS64/none-none-none-none-none-none-none-none-none.png");
let hs640_image = require("../images/HS640/amps1-MMX0-none-none-none.png");
let hsW_image = require("../images/HS-W/amps1-mems1-mmx0.png");

import _ from "lodash";

import Hs32Modules from "./modules/Hs32Modules.vue";
import Hs64Modules from "./modules/Hs64Modules.vue";
import Hs640Modules from "./modules/Hs640Modules.vue";
import HsWModules from "./modules/HsWModules.vue";

import Tooltip from "./Tooltip.vue";

export default {
  name: `ProductCard`,
  props: {
    currentImage: Object
  },
  data() {
    return {
      productName: this.currentImage.name,
      hs32Mods: hs32_image,
      hs64Mods: hs64_image,
      hs640Mods: hs640_image,
      hsWMods: hsW_image,
      descriptionState: "generic",
      modJustClicked: "",
      modState: {},
      activeMods: [],
      synchronize: false,
      show: false,
      isQuoteVisible: false
    };
  },
  computed: {
    modStateOff() {
      return !Object.values(this.modState).includes(true);
    }
  },
  components: {
    Hs32Modules,
    Hs64Modules,
    Hs640Modules,
    HsWModules,
    Tooltip
  },
  mounted() {
    this.synchronize = true;
  },
  methods: {
    showQuote() {
      this.isQuoteVisible = true;
    },
    closeQuote() {
      this.isQuoteVisible = false;
    },
    sendModuleData() {
      if (this.productName == "HS-32") {
        let imgString = this.hs32Mods;
        mutations.setImgString(imgString);
      } else if (this.productName == "HS-64") {
        let imgString = this.hs64Mods;
        mutations.setImgString(imgString);
      } else if (this.productName == "HS-640") {
        let imgString = this.hs640Mods;
        mutations.setImgString(imgString);
      } else if (this.productName == "HS-W") {
        let imgString = this.hsWMods;
        mutations.setImgString(imgString);
      }

      mutations.setProductName(this.productName);

      console.log(this.modState);
      mutations.setSelectedModules(this.modState);

      this.$router.push({ path: "/ConfiguratorQuoteForm" });
    },
    populateModules(modulesString) {
      if (this.productName == "HS-32") {
        this.modState = modulesString[0];
        this.hs32Mods = require(`../images/hs-32_combinatorics/${
          modulesString[1]
        }.png`);
      } else if (this.productName == "HS-64") {
        this.modState = modulesString[0];

        this.hs64Mods = require(`../images/HS64/${modulesString[1]}.png`);
      } else if (this.productName == "HS-640") {
        this.modState = modulesString[0];
        this.hs640Mods = require(`../images/HS640/${modulesString[1]}.png`);
      } else if (this.productName == "HS-W") {
        this.modState = modulesString.activeOptions;
        this.hsWMods = require(`../images/HS-W/${modulesString.imgString}`);
      }
    },
    getMemoryUnits(memString) {
      this.hsWMods = require(`../images/HS-W/${memString}`);
    },
    setDescription(name) {
      // if (this.modState[name]) {
      //   this.descriptionState = name;
      // } else
      if (this.activeMods.length == 0) {
        this.descriptionState = name;
      } else {
        this.descriptionState = name;
      }
    },
    setSleepStandby(val) {
      this.descriptionState = val;
    },
    getModVal(val) {
      this.descriptionState = val;
    }
  }
};
</script>

<style lang="scss">
$brand-yellow: #f4ab01;

// a {
//   text-decoration: none;
//   color: white;
// }

.stateBtns {
  position: absolute;
  top: 30px;
  left: 300px;
  z-index: 10;
}

.pt-s {
  padding-top: 0px;
}

.bg-brand-yellow {
  background-color: #f4ab01;
}

.font-white {
  color: white;
}

.foo p {
  @media (max-width: 600px) {
    font-size: 10px;
  }
}

h4 {
  @media (max-width: 450px) {
    font-size: 12px;
  }
}

.productCardMobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 350px 1fr;
  align-items: center;
  justify-items: left;
  grid-column-gap: 40px;
  height: 100%;
  width: 100%;
  padding-top: 10px;
  padding-left: 10px;

  @media (max-width: 1050px) {
    grid-template-rows: 320px 1fr;
    grid-column-gap: 10px;
  }
  @media (max-width: 900px) {
    grid-template-rows: 250px 100px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }

  @media (max-width: 600px) {
    grid-template-rows: 175px 100px;
    grid-column-gap: 10px;
    padding-left: 0px;
    // grid-template-columns: 300px;
  }
  @media (max-width: 400px) {
    grid-template-columns: 190px 130px;
    grid-template-rows: 180px 100px;
    grid-column-gap: 5px;
    padding-left: 5px;
    // grid-template-columns: 300px;
  }
  @media (max-width: 330px) {
    grid-template-columns: 155px 130px;
    grid-template-rows: 170px 100px;
    grid-column-gap: 0px;
    padding-left: 0px;
    // grid-template-columns: 300px;
  }
}

.hs-image-mobile {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  // height: 100%;
  position: relative;
}

.hs-image-mobile img#hs32 {
  @media (max-width: 1050px) {
    height: auto;
    width: 60%;
  }
  @media (max-width: 900px) {
    height: auto;
    width: 60%;
  }
  @media (max-width: 600px) {
    height: auto;
    width: 60%;
  }
  @media (max-width: 450px) {
    height: auto;
    width: 80%;
  }
}

.hs-image-mobile img#hs64 {
  @media (max-width: 1050px) {
    height: auto;
    width: 60%;
  }
  @media (max-width: 900px) {
    height: auto;
    width: 80%;
  }

  @media (max-width: 450px) {
    height: auto;
    width: 80%;
  }
}
.hs-image-mobile img#hs640 {
  @media (max-width: 900px) {
    height: auto;
    width: 30%;
  }
  @media (max-width: 800px) {
    height: auto;
    width: 40%;
  }
  @media (max-width: 600px) {
    height: auto;
  }
  @media (max-width: 450px) {
    height: auto;
    width: 40%;
  }
}

.hs-image-mobile img#hsw {
  @media (max-width: 900px) {
    height: auto;
    width: 30%;
  }

  @media (max-width: 450px) {
    height: auto;
    width: 40%;
  }
}

.modules {
  grid-column: 2;
  grid-row: 1 / span 3;
  align-self: start;
}

.feature-description {
  text-align: left;
  align-self: start;
}

.button-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; // magic number (needs to be changed in each HS Module)
}

.button-grid {
  @media (max-width: 850px) {
    max-width: 250px;
  }
  @media (max-width: 450px) {
    button {
      width: 44px;
      font-size: 8px;
      border-radius: 2px;
    }
  }
  @media (max-width: 330px) {
    button {
      width: 42px;
      height: 25px;
      font-size: 8px;
      border-radius: 2px;
    }
  }
}

.hidden {
  opacity: 0;
}

.btn-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;

  @media (max-width: 400px) {
    // max-width: 105px;
  }

  button {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin: 3px;
    @media (max-width: 850px) {
      height: 35px;
      width: 35px;
      border-radius: 3px;
    }

    @media (max-width: 400px) {
      height: 20px;
      width: 30px;
      border-radius: 2px;
    }
  }
  button:focus {
    outline: 0;
  }
}

.button .active {
  background-color: hsl(0, 0%, 50%);
}

.tipper {
  &__connector {
    position: absolute;
    top: 5%;
    left: 50%;
  }

  &__opto {
    position: absolute;
    top: 40%;
    right: 20%;
  }
  &__imu {
    position: absolute;
    top: 25%;
    left: 20%;
  }
}
</style>
